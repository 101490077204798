import React from "react"
import { compose } from "recompose"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import styled from "styled-components"
import * as Yup from "yup"
import CustomInput from "../../components/atoms/CustomInput/index"
import ButtonWithLoader from "../../components/atoms/ButtonWithLoader"
import { withFirebase } from "../../utils/Firebase"

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  return (
    <ForgotPasswordContainer>
      <ForgotPasswordForm t={t} />
    </ForgotPasswordContainer>
  )
}

const ForgotPasswordBase = (props) => {
  const { t, firebase, history } = props
  const { addToast } = useToasts()

  return (
    <Container>
      <HeaderText>{t("restore_password_title")}</HeaderText>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          firebase
            .reset({ email: values.email })
            .then(() => {
              addToast(t("email_reset_send"), { appearance: "success" })
              history.push("/")
            })
            .catch(() => {
              addToast(t("unexpected_error"), { appearance: "error" })
            })

          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
        })}
      >
        {(formProps) => (
          <Form>
            <CustomInput
              touched={formProps.touched.email}
              errors={formProps.errors.email}
              icon="email"
              value={formProps.values.email}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              labelName={t("email_address")}
              type="email"
              id="email"
              name="email"
            />

            <SubmitButton
              iconName="password"
              isLoading={formProps.isSubmitting}
              type="submit"
            >
              {t("recover_password")}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const ForgotPasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Container = styled.div`
  width: calc(60% - 550px);
  min-width: 440px;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`

ForgotPasswordBase.propTypes = {
  t: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

const ForgotPasswordForm = compose(withRouter, withFirebase)(ForgotPasswordBase)

export { ForgotPasswordForm }

export default ForgotPasswordPage
