import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ImageLoadable from "../../atoms/ImageLoadable"

const ImageWithText = ({ text, src, emptyMessage }) => {
  return (
    <Container>
      <ImageLoadable
        emptyMessage={emptyMessage}
        {...(src && src !== "local" ? { src } : {})}
      />
      {text && <span>{text}</span>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span {
    margin-top: 5px;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    text-align: center;
  }
`

ImageWithText.defaultProps = {
  text: null,
  src: null,
  emptyMessage: "Foto",
}

ImageWithText.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
  emptyMessage: PropTypes.string,
}

export default ImageWithText
