import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { useSpring, animated } from "react-spring"

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: ${loaderAnimation} 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Spinner = ({ isLoading }) => {
  const animationConfig = useSpring({ opacity: isLoading ? 1 : 0 })

  return (
    <animated.div style={animationConfig}>
      <Loader />
    </animated.div>
  )
}

Spinner.defaultProps = {
  isLoading: false,
}

Spinner.propTypes = {
  isLoading: PropTypes.bool,
}
export default Spinner
