/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"

import * as Yup from "yup"
import CustomInput from "../../atoms/CustomInput"
import Logo from "../../atoms/Logo"
import { withFirebase } from "../../../utils/Firebase"
import * as ROUTES from "../../../utils/Constants/Routes"
import { HeaderText, StyledForm, SubmitButton, RegularButton } from "./styles"

const INITIAL_STATE = {
  isLogginIn: false,
  error: null,
}

// Main
const SignInPage = () => {
  const { t } = useTranslation()
  return (
    <div>
      <HeaderText>
        {t("welcome")} <br /> <Logo /> +Admin
      </HeaderText>
      <SignInForm t={t} />
    </div>
  )
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  goToForgotPassword = () => {
    const { history } = this.props
    history.push("/forgot-password")
  }

  render() {
    const { error, isLogginIn } = this.state
    const { t, firebase, history } = this.props

    const loginSuccess = () => {
      /*
        Adding timeout avoid double login as a work around. 
        Instead this timeout, redirect should be on withAuthentication (firebase.onAuthUserListener)
       */
      this.setState({ ...INITIAL_STATE })
      history.push(ROUTES.HOME)
    }

    return (
      <Formik
        initialValues={{ email: undefined, password: undefined }}
        onSubmit={(values) => {
          this.setState({ isLogginIn: true })
          firebase
            .doSignInWithEmailAndPassword(values.email, values.password)
            .then(() => {
              setTimeout(loginSuccess, 1000)
            })
            .catch((err) => {
              this.setState({ error: err, isLogginIn: false })
            })
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
        })}
      >
        {(props) => (
          <StyledForm onSubmit={props.handleSubmit}>
            <CustomInput
              touched={props.touched.email}
              errors={props.errors.email}
              icon="email"
              value={props.values.email}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              labelName={t("email_address")}
              type="email"
              id="email"
              name="email"
            />
            <CustomInput
              touched={props.touched.password}
              errors={props.errors.password}
              icon="password"
              value={props.values.password}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              type="password"
              name="password"
              id="password"
              labelName={t("password")}
            />
            <SubmitButton iconName="login" isLoading={isLogginIn} type="submit">
              {t("log_in")}
            </SubmitButton>
            {error && <p>{t("password_error")}</p>}
            <RegularButton
              iconName="password"
              type="button"
              onClick={this.goToForgotPassword}
            >
              {t("recover_password")}
            </RegularButton>
            {process.env.REACT_APP_PROJECT_ID !== "doc-app-prod" && (
              <span
                style={{
                  position: "absolute",
                  bottom: 5,
                  left: 5,
                  fontSize: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // eslint-disable-next-line no-console
                  console.log(process.env)
                }}
              >{`env.${process.env.REACT_APP_PROJECT_ID}`}</span>
            )}
          </StyledForm>
        )}
      </Formik>
    )
  }
}

SignInFormBase.propTypes = {
  t: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)

export { SignInForm }

export default SignInPage
