/* eslint-disable no-nested-ternary */
import styled from "styled-components"

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 400px;
`

export const CustomInputLabel = styled.label`
  font-size: 1.375rem;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: center;
  color: ${(props) => props.theme.color.onSurface};
  margin-bottom: 10px;
`

export const CustomInput = styled.textarea`
  display: flex;
  width: 300px;
  border: none;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: #071435;
  mix-blend-mode: normal;
  opacity: 0.5;
  padding: 0 7px;
  white-space: normal;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  resize: none;
  min-height: 48px;
  max-height: 900px;
  height: auto;
`
