import React from "react"
import { Image, Shimmer } from "react-shimmer"
import PropTypes from "prop-types"
import styled from "styled-components"

const ImageLoadable = ({ width, height, src, hideControls, emptyMessage }) => {
  return (
    <Container width={width} height={height} hideControls={hideControls}>
      <Image
        src={src || "local"}
        errorFallback={() => {
          return (
            <ErrorFallback>
              <span>{emptyMessage}</span>
            </ErrorFallback>
          )
        }}
        fallback={<Shimmer width={width} height={height} />}
      />
    </Container>
  )
}

const ErrorFallback = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.black["300"]};
  background: #f2f2f2;
`

const Container = styled.div`
  border: 1px solid #cfcfcf;
  border-radius: ${(props) => (props.hideControls ? "0px" : "22px")};
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
  & > img {
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
  }
`

ImageLoadable.defaultProps = {
  width: 100,
  height: 100,
  src: undefined,
  hideControls: false,
  emptyMessage: "Foto",
}

ImageLoadable.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  hideControls: PropTypes.bool,
  emptyMessage: PropTypes.string,
}

export default ImageLoadable
