import React, { useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

import get from "lodash.get"
import styled from "styled-components"
import BackIcon from "../../components/atoms/BackIcon"
import ProductInfo from "../../components/organisms/ProductInfo"

const ProductDetail = () => {
  const { state } = useLocation()
  const history = useHistory()
  const [currentProduct] = useState(get(state, ["currentProd"]))
  const handleBack = () => {
    history.push(`/organization/${state.currentOrg.id}`, {
      activeTab: 2,
      currentOrg: state.currentOrg,
    })
  }
  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon isBack={false} onBack={handleBack} />{" "}
          {get(currentProduct, ["sku"], "Back")}
        </Back>
      </Nav>
      <Container>
        <ProductInfo product={currentProduct} />
      </Container>
    </div>
  )
}

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

export default ProductDetail
