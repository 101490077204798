import React, { useState } from "react"
import styled from "styled-components"
import get from "lodash.get"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { withFirebase } from "../../../utils/Firebase"
import BoxWithInput from "../../atoms/BoxWithInput"
import SubmitButton from "../../atoms/SubmitButton"
import Button from "../../atoms/ButtonWithLoader"
import ProductImage from "../../molecules/ProductImage"
import Modal from "../../atoms/CustomModal"
import Switch from "../../atoms/Switch"

const ProductInfo = ({ firebase, product }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { state } = useLocation()
  const [formValues, setFormValues] = useState({})
  const [confirmModal, setConfirmModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [pictures, setPictures] = useState({})
  const [switches, updateSwitches] = useState({
    canShareProductResourceInChat: get(
      product,
      "canShareProductResourceInChat",
      false
    ),
    canShowProductResultByCIE11: get(
      product,
      "canShowProductResultByCIE11",
      false
    ),
  })

  const onLoadedImage = async (fieldName, data) => {
    try {
      setIsFileLoading(true)
      const uploadDir = `organizations/${state.currentOrg.id}/products/${data.name}`
      const snapshot = await firebase.uploadToStorage(uploadDir, data.blob)
      const imageURL = await snapshot.ref.getDownloadURL()
      setPictures({ ...pictures, [fieldName]: imageURL })
      setIsFileLoading(false)
      return imageURL
    } catch (error) {
      setIsFileLoading(false)
      return ""
    }
  }

  const onSaveData = async () => {
    try {
      const imagesURL = {
        URLInsert: get(pictures, "URLInsert", product.URLInsert),
        mainPhotoURL: get(pictures, "mainPhotoURL", product.mainPhotoURL),
      }

      setIsLoading(true)
      firebase
        .updateOrgProduct({
          orgId: state.currentOrg.uid,
          documentId: get(product, ["id"]),
          productData: { ...formValues, ...imagesURL, ...switches },
        })
        .then(() => {
          setIsLoading(false)
          addToast(t("saved_successfully"), {
            appearance: "success",
          })
        })
        .catch(() => {
          setIsLoading(false)
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    } catch {
      setIsLoading(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const onDeleteProduct = async () => {
    // eslint-disable-next-line no-alert
    await firebase.deleteOrgProduct({
      orgId: state.currentOrg.uid,
      documentId: get(product, ["id"]),
    })
    window.history.back()
  }

  return (
    <Container>
      <ImagesContainer>
        <PhotoContainer>
          <ProductImage
            width={100}
            height={100}
            src={get(product, "mainPhotoURL")}
            onImageLoaded={onLoadedImage}
            fieldName="mainPhotoURL"
            isLoading={isFileLoading}
          />
          <span>{t("product_image_url")}</span>
        </PhotoContainer>
      </ImagesContainer>
      <InfoWrapper>
        <BoxWithInput
          labelName={t("country_label")}
          defaultValue={get(product, ["country"])}
          onChange={(event) =>
            setFormValues({ ...formValues, country: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, country: event.target.value })
          }
          type="text"
          id="country"
          name="country"
        />
        <BoxWithInput
          labelName={t("sku_label")}
          defaultValue={get(product, ["sku"])}
          onChange={(event) =>
            setFormValues({ ...formValues, sku: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, sku: event.target.value })
          }
          type="text"
          id="sku"
          name="sku"
        />
        <BoxWithInput
          labelName={t("lab_name")}
          defaultValue={get(product, ["labName"])}
          onChange={(event) =>
            setFormValues({ ...formValues, labName: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, labName: event.target.value })
          }
          type="text"
          id="lab_name"
          name="lab_name"
        />
        <BoxWithInput
          labelName={t("cie11_label")}
          defaultValue={get(product, ["cie11"])}
          onChange={(event) =>
            setFormValues({ ...formValues, cie11: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, cie11: event.target.value })
          }
          type="text"
          id="cie11"
          name="cie11"
        />
        <BoxWithInput
          labelName={t("pharmaceutical_presentation_label")}
          defaultValue={get(product, ["pharmaceuticalPresentation"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              pharmaceuticalPresentation: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              pharmaceuticalPresentation: event.target.value,
            })
          }
          type="text"
          id="pharmaceuticalPresentation"
          name="pharmaceuticalPresentation"
        />
        <BoxWithInput
          labelName={t("composition_label")}
          defaultValue={get(product, ["composition"])}
          onChange={(event) =>
            setFormValues({ ...formValues, composition: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, composition: event.target.value })
          }
          type="text"
          id="composition"
          name="composition"
        />
        <BoxWithInput
          labelName={t("therapeutic_label")}
          defaultValue={get(product, ["therapeuticIndications"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              therapeuticIndications: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              therapeuticIndications: event.target.value,
            })
          }
          type="text"
          id="therapeuticIndications"
          name="therapeuticIndications"
        />
        <BoxWithInput
          labelName={t("pharmacological_action_label")}
          defaultValue={get(product, ["pharmacologicalAction"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              pharmacologicalAction: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              pharmacologicalAction: event.target.value,
            })
          }
          type="text"
          id="pharmacologicalAction"
          name="pharmacologicalAction"
        />
        <BoxWithInput
          labelName={t("excipients_label")}
          defaultValue={get(product, ["excipients"])}
          onChange={(event) =>
            setFormValues({ ...formValues, excipients: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, excipients: event.target.value })
          }
          type="text"
          id="excipients"
          name="excipients"
        />
        <BoxWithInput
          labelName={t("dose_label")}
          defaultValue={get(product, ["dose"])}
          onChange={(event) =>
            setFormValues({ ...formValues, dose: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, dose: event.target.value })
          }
          type="text"
          id="dose"
          name="dose"
        />
        <BoxWithInput
          labelName={t("contraindications_label")}
          defaultValue={get(product, ["contraindications"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              contraindications: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              contraindications: event.target.value,
            })
          }
          type="text"
          id="contraindications"
          name="contraindications"
        />
        <BoxWithInput
          labelName={t("drug_interactions_label")}
          defaultValue={get(product, ["drugInteractions"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              drugInteractions: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              drugInteractions: event.target.value,
            })
          }
          type="text"
          id="drugInteractions"
          name="drugInteractions"
        />
        <BoxWithInput
          labelName={t("warnings_label")}
          defaultValue={get(product, ["warnings"])}
          onChange={(event) =>
            setFormValues({ ...formValues, warnings: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, warnings: event.target.value })
          }
          type="text"
          id="warnings"
          name="warnings"
        />
        <BoxWithInput
          labelName={t("precautions_label")}
          defaultValue={get(product, ["precautions"])}
          onChange={(event) =>
            setFormValues({ ...formValues, precautions: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, precautions: event.target.value })
          }
          type="text"
          id="precautions"
          name="precautions"
        />
        <BoxWithInput
          labelName={t("overdose_label")}
          defaultValue={get(product, ["overDoseIndications"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              overDoseIndications: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              overDoseIndications: event.target.value,
            })
          }
          type="text"
          id="overDoseIndications"
          name="overDoseIndications"
        />
        <BoxWithInput
          labelName={t("storage_label")}
          defaultValue={get(product, ["storageIndications"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              storageIndications: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              storageIndications: event.target.value,
            })
          }
          type="text"
          id="storageIndications"
          name="storageIndications"
        />
        <BoxWithInput
          labelName={t("expiration_label")}
          defaultValue={get(product, ["expirationIndications"])}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              expirationIndications: event.target.value,
            })
          }
          onBlur={(event) =>
            setFormValues({
              ...formValues,
              expirationIndications: event.target.value,
            })
          }
          type="text"
          id="expirationIndications"
          name="expirationIndications"
        />
        <BoxWithInput
          labelName={t("presentation_label")}
          defaultValue={get(product, ["presentations"])}
          onChange={(event) =>
            setFormValues({ ...formValues, presentations: event.target.value })
          }
          onBlur={(event) =>
            setFormValues({ ...formValues, presentations: event.target.value })
          }
          type="text"
          id="presentations"
          name="presentations"
        />

        <ImagesContainer>
          <PhotoContainer>
            <ProductImage
              srcType={["image/*", "application/pdf"]}
              emptyMessage={`${t("upload_file_button")} *`}
              text={`${t("upload_file_button")} *`}
              width={100}
              height={100}
              src={get(product, "URLInsert")}
              fieldName="URLInsert"
              onImageLoaded={onLoadedImage}
            />
            <span>{t("url_update_insert")}</span>
          </PhotoContainer>
        </ImagesContainer>

        <SwitchContainer>
          <span>{t("can_share_product_resource_in_chat")}</span>
          <Switch
            value={get(switches, "canShareProductResourceInChat")}
            checked={get(switches, "canShareProductResourceInChat")}
            id="canShareProductResourceInChat"
            onChange={(event) =>
              updateSwitches({
                ...switches,
                canShareProductResourceInChat: event.target.checked,
              })
            }
          />
        </SwitchContainer>

        <SwitchContainer>
          <span>{t("can_show_product_result_by_CIE11")}</span>
          <Switch
            value={get(switches, "canShowProductResultByCIE11")}
            checked={get(switches, "canShowProductResultByCIE11")}
            id="canShowProductResultByCIE11"
            onChange={(event) =>
              updateSwitches({
                ...switches,
                canShowProductResultByCIE11: event.target.checked,
              })
            }
          />
        </SwitchContainer>

        <SubmitButton
          iconName="save"
          isLoading={isLoading}
          onClick={onSaveData}
          labelName={t("save_button")}
        />
        <SubmitButton
          color="#d1011d"
          iconName="delete"
          onClick={() => setConfirmModal(true)}
          labelName={t("delete_button")}
        />
      </InfoWrapper>
      <Modal
        showClose={false}
        modalOpen={confirmModal}
        setModalOpen={() => setConfirmModal(false)}
      >
        <ConfirmModalContainer>
          <Title>{t("product_delete_confirm")}</Title>
          <ButtonsContainer>
            <Button color="lightgray" onClick={() => setConfirmModal(false)}>
              {t("cancel")}
            </Button>
            <Button color="red" onClick={onDeleteProduct}>
              {t("confirm")}
            </Button>
          </ButtonsContainer>
        </ConfirmModalContainer>
      </Modal>
    </Container>
  )
}

export const Title = styled.h4`
  margin: 0 0 6px 0;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ConfirmModalContainer = styled.div`
  width: 600px;
  height: auto;
  padding: 16px;
  border-radius: 10px;
  background: white;
  z-index: 1001;
`

export const Checks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px;
  margin: 0px 16px;
`

const Container = styled.div`
  position: relative;
  height: calc(100% - 56px);
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  & > div {
    margin: 0 15px;
    margin-top: 20px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span {
    margin-top: 5px;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    text-align: center;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  & > span {
    margin-right: 20px;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 1.25px;
    text-align: center;
  }
`

ProductInfo.propTypes = {
  product: PropTypes.shape({
    displayName: PropTypes.string,
    userType: PropTypes.string,
    email: PropTypes.string,
    URLInsert: PropTypes.string,
    mainPhotoURL: PropTypes.string,
  }).isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(ProductInfo)
