import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const TextCard = ({
  label,
  text,
  children,
  isSimple,
  completed,
  handleClick,
}) => {
  return (
    <Container onClick={handleClick}>
      <Label>{label}</Label>
      {!children && (
        <Text isSimple={isSimple} completed={completed}>
          {text}
        </Text>
      )}
      {children && children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  width: 400px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  transition: all 400ms ease-in-out;
  &:hover {
    border-color: ${(props) =>
      props.onClick ? props.theme.color.darkPurple : "f2f2f2"};
    transform: ${(props) => (props.onClick ? "scale(1.1)" : "none")};
  }
`

const Label = styled.span`
  font-size: 1.375rem;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: center;
  color: #071435;
  margin-bottom: 10px;
`

export const Text = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.isSimple ? "0px" : "0px 24px")};
  margin: ${(props) => (props.isSimple ? "0px" : "0px 16px")};
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: #071435;
  mix-blend-mode: normal;
  opacity: 0.5;
  padding: 0 7px;
  & > svg {
    color: ${(props) => (props.completed ? "green" : "red")};
    opacity: 1;
  }
`

TextCard.defaultProps = {
  children: null,
  isSimple: false,
  completed: false,
  text: null,
  handleClick: null,
}

TextCard.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  isSimple: PropTypes.bool,
  completed: PropTypes.bool,
  handleClick: PropTypes.func,
}

export default TextCard
