import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin: 20px;
  height: calc(100vh - 140px);
  overflow-x: hidden;
  overflow-y: auto;
`

export const Title = styled.p`
  padding: 0 20px;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 175px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`
