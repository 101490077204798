import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"

// Components
import CustomInput from "../../atoms/CustomInput"
import SubmitButton from "../../atoms/SubmitButton"

const DocCharges = ({ data, email, firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (data) {
      setFormValues({ ...data })
    }
  }, [data])

  const onSaveData = async () => {
    try {
      const date = Date.now()
      setIsLoading(true)
      firebase
        .saveProviderChargesByDoc({
          email,
          data: {
            firstYear: Number(formValues?.firstYear),
            secondYear: Number(formValues?.secondYear),
            restOfTime: Number(formValues?.restOfTime),
            modifiedAt: date,
          },
        })
        .then(() => {
          setFormValues({ ...formValues, modifiedAt: date })
          setIsLoading(false)
          addToast(t("saved_successfully"), {
            appearance: "success",
          })
        })
        .catch(() => {
          setIsLoading(false)
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    } catch {
      setIsLoading(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  return (
    <Container>
      <InfoWrapper>
        <InputContainer>
          <Subtitles>
            {`${t("last_update")}: ${
              moment(formValues.modifiedAt).format("HH:mm:ss - DD/MM/YYYY") ||
              moment(formValues.createdAt).format("HH:mm:ss - DD/MM/YYYY")
            }`}
          </Subtitles>
        </InputContainer>

        <InputContainer>
          <Subtitles>{`12 ${t("months")}`}</Subtitles>
          <CustomInput
            labelName={t("total_label")}
            value={formValues?.firstYear}
            onChange={(event) =>
              setFormValues({
                ...formValues,
                firstYear: event.target.value,
              })
            }
            type="number"
            id="firstYear"
            name="firstYear"
            icon="money"
          />
        </InputContainer>

        <InputContainer>
          <Subtitles>{`24 ${t("months")}`}</Subtitles>
          <CustomInput
            labelName={t("total_label")}
            value={formValues?.secondYear}
            onChange={(event) =>
              setFormValues({
                ...formValues,
                secondYear: event.target.value,
              })
            }
            type="number"
            id="secondYear"
            name="secondYear"
            icon="money"
          />
        </InputContainer>

        <InputContainer>
          <Subtitles>{`36 ${t("months")}`}</Subtitles>
          <CustomInput
            labelName={t("total_label")}
            value={formValues?.restOfTime}
            onChange={(event) =>
              setFormValues({
                ...formValues,
                restOfTime: event.target.value,
              })
            }
            type="number"
            id="restOfTime"
            name="restOfTime"
            icon="money"
          />
        </InputContainer>

        <SubmitButton
          iconName="save"
          isLoading={isLoading}
          onClick={onSaveData}
          labelName={t("save_button")}
        />
      </InfoWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: calc(100% - 56px);
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

export const Subtitles = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
`

DocCharges.propTypes = {
  data: PropTypes.shape({
    firstYear: PropTypes.number,
    secondYear: PropTypes.number,
    restOfTime: PropTypes.number,
    modifiedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  email: PropTypes.string.isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default DocCharges
