import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { Document, Page, View, Image, Text } from "@react-pdf/renderer"

import InvoiceLogo from "../../../static/images/logo.png"

const ReceiptPDF = ({ invoice, items }) => {
  return (
    <Document>
      <Page>
        <View
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Image
            src={InvoiceLogo}
            alt="invoice_logo"
            style={{ maxWidth: "100px", maxHeight: "53px" }}
          />
          <Text
            style={{
              textTransform: "capitalize",
              fontSize: "16px",
              color: "#000",
              marginTop: "30px",
              marginBottom: "34px",
              fontWeight: "bold",
            }}
          >
            {invoice?.type || "Receipt"}
          </Text>
        </View>

        <View style={{ paddingHorizontal: "50px" }}>
          <View style={{ height: "200px" }}>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              Receipt number RE{invoice?.invoiceNumber}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              Date {moment(invoice.createdAt).format("MMM DD, YYYY")}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              Payment method {invoice?.cardBrand || ""} - {invoice?.last4 || ""}
            </Text>

            <Text
              style={{ fontSize: "9px", lineHeight: "9px", marginTop: "15px" }}
            >
              Virtual Medical International (VMI) LLC
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              14900 SW 30 ST
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              UNIT 278918
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              MIRAMAR, FL 33027
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>USA</Text>

            <Text
              style={{ fontSize: "9px", lineHeight: "9px", marginTop: "15px" }}
            >
              Received from
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              {invoice?.customerName || ""}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              {invoice?.email || ""}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              {invoice?.address?.line1 || ""}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              {invoice?.address?.line2 || ""} {invoice?.address?.state || ""}
            </Text>
            <Text style={{ fontSize: "9px", lineHeight: "9px" }}>
              {invoice?.address?.country || ""}, {invoice?.address?.zip || ""}
            </Text>
          </View>

          <View
            style={{
              width: "100%",
              marginTop: "30px",
              border: "1px solid #000",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "10%",
                  height: "100%",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  QTY
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "35%",
                  height: "100%",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Description
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "25%",
                  height: "100%",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Qty professionals
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "15%",
                  height: "100%",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Unit price
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "15%",
                  height: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Amount
                </Text>
              </View>
            </View>
            {items?.map((item) => (
              <View
                key={item?.description}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30px",
                }}
                wrap={false}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "10%",
                    height: "100%",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.qty) || 0}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "35%",
                    height: "100%",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {item?.description || ""}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "25%",
                    height: "100%",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.qtyDoctors) || 0}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "15%",
                    height: "100%",
                    borderRight: "1px solid #000",
                    borderTop: "1px solid #000",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    ${item?.amount || 0}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "15%",
                    height: "100%",
                    borderTop: "1px solid #000",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    ${item?.totalAmount || 0}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              width: "200px",
              height: "200px",
              marginTop: "30px",
              border: "1px solid #000",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "Column",
              }}
            >
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100px",
                  borderRight: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "5px",
                  }}
                >
                  Sub total
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100px",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "5px",
                  }}
                >
                  Taxes
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100px",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "5px",
                  }}
                >
                  Total
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100px",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "5px",
                  }}
                >
                  Amount paid
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                  width: "100px",
                  borderRight: "1px solid #000",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "5px",
                  }}
                >
                  Balance
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "Column",
              }}
            >
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  ${invoice?.subTotal}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100px",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  ${invoice?.taxes}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100px",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  ${invoice?.total}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100px",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  ${invoice?.paidAmount}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100px",
                  borderTop: "1px solid #000",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  ${invoice?.balance}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{ marginTop: "38px", display: "flex", flexDirection: "row" }}
          >
            <Text style={{ fontSize: "10px" }}>
              Virtual Medical International (VMI) LLC Total paid: $
              {invoice?.paidAmount}
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "100px",
              }}
            >
              <Text style={{ fontSize: "10px" }}>questions:</Text>
              <Text
                style={{
                  fontSize: "10px",
                  textDecoration: "underline",
                  color: "#467886",
                  marginLeft: "5px",
                }}
              >
                contacto@doc.cr
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

ReceiptPDF.propTypes = {
  invoice: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      qtyDoctors: PropTypes.number,
      totalAmount: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default ReceiptPDF
