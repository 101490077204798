/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from "react"
import { compose } from "recompose"
import { Formik } from "formik"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import * as Yup from "yup"

import Nav from "../../components/atoms/Nav"
import Tab from "../../components/atoms/Tab"
import CustomInput from "../../components/atoms/CustomInput"
import { withFirebase } from "../../utils/Firebase"
import PaymentSummary from "./Sections/Summary"
import PaymentSummaryOthers from "./Sections/SummaryOthers"
import { AuthUserContext, withAuthorization } from "../../context/Session"
import { countries } from "../../utils/App"
import Charges from "./Sections/Charges"
import PaymentAdministration from "./Sections/Administration"
import Promotions from "./Sections/Promotions"
import {
  StyledForm,
  TabsContainer,
  Title,
  SubmitButton,
  FormContainer,
  SubTitle,
  RowContainer,
  SpreadContainer,
} from "./styles"
// import PaymentAdministration from "../../components/organisms/PaymentAdministration"
// import PaymentExchanges from "../../components/organisms/PaymentExchanges"
// import PaymentSummary from "../../components/organisms/PaymentSummary"

const Payments = ({ firebase }) => {
  const authUser = useContext(AuthUserContext)
  const [activeTab, setActiveTab] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [loadingExchanges, setLoadingExchanges] = useState(true)
  const [exchanges, setExchanges] = useState({})
  const [spreadFromDB, setSpread] = useState(0)
  const [minimunExchangesRates, setMinimunExchangesRates] = useState({})
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const formRef = useRef()

  useEffect(() => {
    setLoadingExchanges(true)
  }, [activeTab])

  useEffect(() => {
    const getExchanges = async () => {
      const localExchanges = await firebase.getFormOptions("exchanges")
      const pricingSnapshot = await firebase.getPricing()
      if (localExchanges) {
        const data = localExchanges.data()
        const sellExchageData = {}
        const buyExchangeData = {}
        const minimunFeesData = {}
        Object.keys(data.saleRates).forEach((k) => {
          const key = `sell-${k}`
          sellExchageData[key] = data.saleRates[k]
        })
        Object.keys(data.buyRates).forEach((k) => {
          const key = `buy-${k}`
          buyExchangeData[key] = data.buyRates[k]
        })
        Object.keys(data.minimunFees).forEach((k) => {
          const key = `minimunFee-${k}`
          minimunFeesData[key] = data.minimunFees[k]
        })
        if (pricingSnapshot.exists) {
          const getData = pricingSnapshot.data()
          setSpread(getData.compensation_spread_percentage || 0)
        }
        setMinimunExchangesRates(minimunFeesData)
        setExchanges({ ...sellExchageData, ...buyExchangeData })
      }

      setLoadingExchanges(false)
    }
    if (loadingExchanges && firebase && activeTab === 0) {
      getExchanges()
    }
  }, [loadingExchanges, activeTab, firebase, authUser])

  const currentCountry = countries.find((c) => c.value === authUser.country)
  const sellKey = `sell-${authUser.country}`
  const buyKey = `buy-${authUser.country}`

  // Diferencial cambiario
  const getPercentageSpread = (sell, buy, provisionSpread) => {
    const spread = ((sell - buy) / buy) * 100
    const percentage = spread * (provisionSpread / 100)
    const total = spread + percentage
    return total || 0
  }

  return (
    <>
      <Nav
        titleKey="users_payments"
        descriptionKey="users_payments_description"
      />
      <div>
        <TabsContainer>
          <Tab
            textKey="exchanges"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
          {authUser.roles.SUPER_ADMIN && (
            <Tab
              textKey="charges"
              onTabClick={() => setActiveTab(1)}
              active={activeTab === 1}
            />
          )}
          {/* <Tab
            textKey="visualization"
            onTabClick={() => setActiveTab(2)}
            active={activeTab === 2}
          /> */}
          <Tab
            textKey="payments_summary_title_doctors"
            onTabClick={() => setActiveTab(3)}
            active={activeTab === 3}
          />
          <Tab
            textKey="payments_summary_title_others"
            onTabClick={() => setActiveTab(4)}
            active={activeTab === 4}
          />
          <Tab
            textKey="payments_administration_title"
            onTabClick={() => setActiveTab(5)}
            active={activeTab === 5}
          />
          <Tab
            textKey="payments_promotions_title"
            onTabClick={() => setActiveTab(6)}
            active={activeTab === 6}
          />
        </TabsContainer>
        {activeTab === 0 && (
          <FormContainer>
            <Title>
              {t("exchange_title", {
                country: get(currentCountry, "label"),
              })}
              {!loadingExchanges &&
                (authUser.roles.SUPER_ADMIN ? (
                  <>
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        ...exchanges,
                        ...minimunExchangesRates,
                        compensation_spread_percentage: spreadFromDB,
                      }}
                      onSubmit={async (values) => {
                        try {
                          setIsSaving(true)
                          const keys = Object.keys(values)
                          const buyData = {}
                          const sellData = {}
                          const minimunFeesData = {}
                          keys.forEach((value) => {
                            if (value.includes("sell")) {
                              const removedKey = value.replace("sell-", "")
                              sellData[removedKey] = values[value]
                            } else if (value.includes("buy")) {
                              const removedKey = value.replace("buy-", "")
                              buyData[removedKey] = values[value]
                            } else if (value.includes("minimunFee")) {
                              const removedKey = value.replace(
                                "minimunFee-",
                                ""
                              )
                              minimunFeesData[removedKey] = values[value]
                            }
                          })
                          await firebase.saveExchanges(
                            minimunFeesData,
                            "minimunFees"
                          )
                          await firebase.saveExchanges(sellData, "saleRates")
                          await firebase.saveExchanges(buyData, "buyRates")
                          await firebase.updatePricing({
                            compensation_spread_percentage: Number(
                              values.compensation_spread_percentage
                            ),
                          })
                          setSpread(values.compensation_spread_percentage)
                          addToast(t("saved_successfully"), {
                            appearance: "success",
                            placement: "bottom-center",
                          })
                          setIsSaving(false)
                        } catch (e) {
                          addToast(t("unexpected_error"), {
                            appearance: "error",
                            placement: "bottom-center",
                          })
                          setIsSaving(false)
                        }
                      }}
                      validationSchema={() => {
                        const values = {}
                        countries.forEach((c) => {
                          values[`buy-${c.value}`] = Yup.number()
                            .typeError("Debe ser un valor numérico")
                            .positive("Debe ser mayor a 0")
                          values[`sell-${c.value}`] = Yup.number()
                            .typeError("Debe ser un valor numérico")
                            .positive("Debe ser mayor a 0")
                        })
                        return Yup.object(values)
                      }}
                    >
                      {(props) => (
                        <StyledForm>
                          <SpreadContainer>
                            <SubTitle>
                              {t("web_client.exchange_spread_title")}
                            </SubTitle>
                            <RowContainer>
                              <CustomInput
                                value={
                                  props.values.compensation_spread_percentage
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                id="compensation_spread_percentage"
                                name="compensation_spread_percentage"
                                type="number"
                                icon="percent"
                                fontSize="1.4rem"
                                iconRight
                              />
                            </RowContainer>
                          </SpreadContainer>
                          {countries.map((country) => (
                            <RowContainer key={country.value}>
                              <div>
                                <SubTitle>{t("sell")}</SubTitle>
                                <CustomInput
                                  touched={
                                    props.touched[`sell-${country.value}`]
                                  }
                                  errors={props.errors[`sell-${country.value}`]}
                                  icon="money"
                                  value={props.values[`sell-${country.value}`]}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  labelName={country.label.toUpperCase()}
                                  type="number"
                                  id={`sell-${country.value}`}
                                  name={`sell-${country.value}`}
                                />
                              </div>
                              <div>
                                <SubTitle>{t("buy")}</SubTitle>
                                <CustomInput
                                  touched={
                                    props.touched[`buy-${country.value}`]
                                  }
                                  errors={props.errors[`buy-${country.value}`]}
                                  icon="money"
                                  value={props.values[`buy-${country.value}`]}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  labelName={country.label.toUpperCase()}
                                  type="number"
                                  id={`buy-${country.value}`}
                                  name={`buy-${country.value}`}
                                />
                              </div>
                              <div>
                                <SubTitle>{t("difference")}</SubTitle>
                                <CustomInput
                                  value={(
                                    props.values[`sell-${country.value}`] -
                                    props.values[`buy-${country.value}`]
                                  ).toFixed(2)}
                                  labelName={country.label.toUpperCase()}
                                  type="text"
                                  id="id"
                                  name="name"
                                  readonly
                                />
                              </div>
                              <div>
                                <SubTitle>
                                  {t("percentage_difference")}
                                </SubTitle>
                                <CustomInput
                                  value={`${getPercentageSpread(
                                    props.values[`sell-${country.value}`],
                                    props.values[`buy-${country.value}`],
                                    props.values.compensation_spread_percentage
                                  ).toFixed(2)}%`}
                                  labelName={country.label.toUpperCase()}
                                  type="text"
                                  id="percentage_diff"
                                  name="percentage_diff"
                                  readonly
                                />
                              </div>
                              <div>
                                <SubTitle>
                                  {t("web_client.minimun_exchange_amount")}
                                </SubTitle>
                                <CustomInput
                                  value={
                                    props.values[`minimunFee-${country.value}`]
                                  }
                                  labelName={country.label.toUpperCase()}
                                  onChange={props.handleChange}
                                  type="number"
                                  icon="money"
                                  id={`minimunFee-${country.value}`}
                                  name={`minimunFee-${country.value}`}
                                />
                              </div>
                            </RowContainer>
                          ))}
                        </StyledForm>
                      )}
                    </Formik>
                  </>
                ) : (
                  <Formik
                    innerRef={formRef}
                    initialValues={{ ...exchanges, ...minimunExchangesRates }}
                    onSubmit={async (values) => {
                      try {
                        setIsSaving(true)
                        const keys = Object.keys(values)
                        const buyData = {}
                        const sellData = {}
                        keys.forEach((value) => {
                          if (value.includes("sell")) {
                            const removedKey = value.replace("sell-", "")
                            sellData[removedKey] = values[value]
                          } else if (value.includes("buy")) {
                            const removedKey = value.replace("buy-", "")
                            buyData[removedKey] = values[value]
                          }
                        })
                        await firebase.saveExchanges(sellData, "saleRates")
                        await firebase.saveExchanges(buyData, "buyRates")
                        addToast(t("saved_successfully"), {
                          appearance: "success",
                          placement: "bottom-center",
                        })
                        setIsSaving(false)
                      } catch (e) {
                        addToast(t("unexpected_error"), {
                          appearance: "error",
                          placement: "bottom-center",
                        })
                        setIsSaving(false)
                      }
                    }}
                    validationSchema={Yup.object({
                      [sellKey]: Yup.number()
                        .typeError("Debe ser un valor numérico")
                        .positive("Debe ser mayor a 0"),
                      [buyKey]: Yup.number()
                        .typeError("Debe ser un valor numérico")
                        .positive("Debe ser mayor a 0"),
                    })}
                  >
                    {(props) => (
                      <StyledForm onSubmit={props.handleSubmit}>
                        <RowContainer>
                          <div>
                            <SubTitle>{t("sell")}</SubTitle>
                            <CustomInput
                              touched={props.touched[sellKey]}
                              errors={props.errors[sellKey]}
                              icon="money"
                              value={props.values[sellKey]}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              labelName={
                                countries.find(
                                  (c) => c.value === authUser.country
                                ).label
                              }
                              type="number"
                              id={sellKey}
                              name={sellKey}
                            />
                          </div>
                          <div>
                            <SubTitle>{t("buy")}</SubTitle>
                            <CustomInput
                              touched={props.touched[buyKey]}
                              errors={props.errors[buyKey]}
                              icon="money"
                              value={props.values[buyKey]}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              labelName={
                                countries.find(
                                  (c) => c.value === authUser.country
                                ).label
                              }
                              type="number"
                              id={buyKey}
                              name={buyKey}
                            />
                          </div>
                          <div>
                            <SubTitle>{t("difference")}</SubTitle>
                            <CustomInput
                              value={(
                                props.values[sellKey] - props.values[buyKey]
                              ).toFixed(2)}
                              labelName={
                                countries.find(
                                  (c) => c.value === authUser.country
                                ).label
                              }
                              type="text"
                              id="id"
                              name="name"
                              readonly
                            />
                          </div>
                          <div>
                            <SubTitle>{t("percentage_difference")}</SubTitle>
                            <CustomInput
                              value={`${(
                                ((props.values[sellKey] -
                                  props.values[buyKey]) /
                                  props.values[buyKey]) *
                                100
                              ).toFixed(2)}%`}
                              labelName={
                                countries.find(
                                  (c) => c.value === authUser.country
                                ).label
                              }
                              type="text"
                              id="percentage_diff"
                              name="percentage_diff"
                              readonly
                            />
                          </div>
                          <div>
                            <SubTitle>
                              {t("web_client.minimun_exchange_amount")}
                            </SubTitle>
                            <CustomInput
                              value={
                                props.values[`minimunFee-${authUser.country}`]
                              }
                              labelName={
                                countries.find(
                                  (c) => c.value === authUser.country
                                ).label
                              }
                              type="number"
                              icon="money"
                              id="minimunFee"
                              name="minimunFee"
                              readonly
                            />
                          </div>
                        </RowContainer>
                        <SubmitButton
                          iconName="save"
                          isLoading={isSaving}
                          disabled={!props.isValid}
                        >
                          {t("save_button")}
                        </SubmitButton>
                      </StyledForm>
                    )}
                  </Formik>
                ))}
            </Title>
            {authUser.roles.SUPER_ADMIN && (
              <SubmitButton
                iconName="save"
                isLoading={isSaving}
                onClick={() => formRef.current.handleSubmit()}
              >
                {t("save_button")}
              </SubmitButton>
            )}
          </FormContainer>
        )}
        {activeTab === 1 && <Charges />}
        {/* {activeTab === 2 && (
          <>
            <div />
          </>
        )} */}
        {activeTab === 3 && <PaymentSummary />}
        {activeTab === 4 && <PaymentSummaryOthers />}
        {activeTab === 5 && <PaymentAdministration />}
        {activeTab === 6 && <Promotions />}
      </div>
    </>
  )
}

const condition = (authUser) => !!authUser
const ComposedPayments = compose(withAuthorization(condition))(Payments)

export default ComposedPayments
