import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BusinessCenter } from "@styled-icons/material/BusinessCenter"

const CardsContainer = ({
  children,
  isEmpty,
  isHorizontal,
  hasTitle,
  type,
  fullHeight,
  emptyMessage,
  loading,
  withCardPadding,
  isGrid,
  hasFooter,
}) => {
  const { t } = useTranslation()
  const localHeightValue = hasTitle ? 106 : 66
  if (loading)
    return (
      <Container
        isEmpty={isEmpty}
        isHorizontal={isHorizontal}
        heightValue={fullHeight ? 0 : localHeightValue}
        loading={loading.toString()}
        withCardPadding={withCardPadding}
      >
        <p>{t("loading")}</p>
      </Container>
    )

  if (isEmpty)
    return (
      <Container
        isEmpty={isEmpty}
        isHorizontal={isHorizontal}
        heightValue={fullHeight ? 0 : localHeightValue}
        withCardPadding={withCardPadding}
      >
        <BusinessCenter size={46} />
        <p>{emptyMessage || `Aun no tienes ${type} de este tipo en DOC...`}</p>
      </Container>
    )

  if (isGrid)
    return <GridContainer hasFooter={hasFooter}>{children}</GridContainer>

  return (
    <Container
      isHorizontal={isHorizontal}
      heightValue={fullHeight ? 0 : localHeightValue}
      withCardPadding={withCardPadding}
    >
      {children}
    </Container>
  )
}

const GridContainer = styled.div`
  height: ${(props) => (props.hasFooter ? "calc(100% - 80px)" : "auto")};
  width: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  grid-auto-rows: 300px;
  min-height: 100px;
  margin: 10px;
  overflow-y: scroll;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${(props) => props.theme.screen.xlg}) {
    grid-template-columns: repeat(4, 1fr);
  }
  & > div {
    box-sizing: border-box;
    height: 300px;
    min-width: 200px;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #f2f2f2;
    margin-bottom: 10px;
    overflow: hidden;
  }
  & > p {
    font-size: 1.375rem;
    text-align: center;
    min-width: 200px;
    width: 100%;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isEmpty || props.loading ? "center" : "flex-start"};
  align-items: ${(props) =>
    props.isEmpty || props.loading ? "center" : "flex-start"};
  flex-wrap: ${(props) => (props.isHorizontal ? "no-wrap" : "wrap")};
  height: ${(props) =>
    props.isHorizontal && !props.isEmpty
      ? "340px"
      : `calc(100% - ${props.heightValue}px)`};
  overflow-y: ${(props) => (props.isHorizontal ? "hidden" : "scroll")};
  overflow-x: ${(props) => (props.isHorizontal ? "scroll" : "hidden")};
  width: calc(100vw - 120px);
  flex-direction: ${(props) => (props.isEmpty ? "column" : "row")};
  margin-top: 6px;
  margin-left: 30px;
  & > div {
    box-sizing: border-box;
    min-height: 330px;
    min-width: 400px;
    border-radius: 4px;
    padding: ${(props) => (props.withCardPadding ? "10px" : "0px")};
    border: 1px solid #f2f2f2;
    margin-right: 22px;
    &:last-of-type {
      margin-right: 0;
    }
    overflow: hidden;
  }
  & > p {
    font-size: 1.375rem;
    text-align: center;
    width: 350px;
  }
`

CardsContainer.defaultProps = {
  isEmpty: true,
  isHorizontal: false,
  hasTitle: false,
  type: "usuarios",
  fullHeight: false,
  emptyMessage: null,
  loading: false,
  withCardPadding: true,
  isGrid: false,
  hasFooter: false,
}

CardsContainer.propTypes = {
  isEmpty: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  hasTitle: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  type: PropTypes.string,
  fullHeight: PropTypes.bool,
  emptyMessage: PropTypes.string,
  loading: PropTypes.bool,
  withCardPadding: PropTypes.bool,
  isGrid: PropTypes.bool,
  hasFooter: PropTypes.bool,
}

export default CardsContainer
