import React from "react"
import PropTypes from "prop-types"
import { Email } from "@styled-icons/material/Email"
import { Security } from "@styled-icons/material/Security"
import { Person } from "@styled-icons/material/Person"
import { LocationOn } from "@styled-icons/material/LocationOn"
import { Phone } from "@styled-icons/material/Phone"
import { LocalHospital } from "@styled-icons/material/LocalHospital"
import { Class } from "@styled-icons/material/Class"
import { MedicalServices } from "@styled-icons/material/MedicalServices"
import { Map } from "@styled-icons/material/Map"
import { School } from "@styled-icons/material/School"
import { Payment } from "@styled-icons/material/Payment"
import { AccountBalance } from "@styled-icons/material/AccountBalance"
import { Description } from "@styled-icons/material/Description"
import { PersonSearch } from "@styled-icons/material/PersonSearch"
import { Search } from "@styled-icons/material/Search"
import { AttachMoney } from "@styled-icons/material/AttachMoney"
import { Percent } from "@styled-icons/fa-solid/Percent"
import { DateRange } from "@styled-icons/material/DateRange"

import {
  InputWrapper,
  CustomInput,
  CustomInputLabel,
  Bar,
  Highlight,
  Error,
} from "./styles"

const renderIcon = (icon) => {
  const icons = {
    email: <Email size="24" />,
    password: <Security size="24" />,
    user: <Person size="24" />,
    location: <LocationOn size="24" />,
    phone: <Phone size="24" />,
    hospital: <LocalHospital size="24" />,
    class: <Class size="24" />,
    medical: <MedicalServices size="24" />,
    school: <School size="24" />,
    payment: <Payment size="24" />,
    bank: <AccountBalance size="24" />,
    subject: <Description size="24" />,
    search: <Search size="24" />,
    "search-person": <PersonSearch size="24" />,
    map: <Map size="24" />,
    money: <AttachMoney size="24" />,
    percent: <Percent size="24" />,
    date: <DateRange size="24" />,
  }
  return icons[icon]
}

const handleAvoidCopyAndPaste = (e) => {
  e.preventDefault()
  return false
}

const Input = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  type,
  required,
  labelName,
  id,
  noMargin,
  icon,
  disabled,
  multiple,
  small,
  readonly,
  avoidCopyAndPaste,
  errors,
  touched,
  showError,
  fontSize,
  iconRight,
}) => (
  <InputWrapper noMargin={noMargin} iconRight={iconRight}>
    {icon && renderIcon(icon)}
    <CustomInput
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      required={required}
      id={id}
      disabled={disabled}
      multiple={multiple}
      isSmall={small}
      readOnly={readonly}
      onCopy={(e) => avoidCopyAndPaste && handleAvoidCopyAndPaste(e)}
      onPaste={(e) => avoidCopyAndPaste && handleAvoidCopyAndPaste(e)}
      onFocus={onFocus}
      errors={(touched && errors) || showError}
      fontSize={fontSize}
    />
    <Highlight />
    <Bar />
    {labelName && (
      <CustomInputLabel readOnly={readonly}>{labelName}</CustomInputLabel>
    )}
    {((touched && errors) || showError) && <Error>{errors}</Error>}
  </InputWrapper>
)

Input.defaultProps = {
  disabled: false,
  multiple: false,
  small: false,
  readonly: false,
  touched: false,
  errors: "",
  value: "",
  required: false,
  avoidCopyAndPaste: false,
  noMargin: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  showError: false,
  icon: "",
  labelName: "",
  fontSize: "",
  iconRight: false,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  labelName: PropTypes.string,
  fontSize: PropTypes.string,
  iconRight: PropTypes.bool,
  id: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  small: PropTypes.bool,
  readonly: PropTypes.bool,
  avoidCopyAndPaste: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  touched: PropTypes.bool,
  showError: PropTypes.bool,
}

export default Input
