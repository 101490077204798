import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Nav from "../../atoms/Nav"
import CardsContainer from "../../atoms/CardsContainer"
import UserCard from "../../molecules/UserCard"
import VisitorDrawer from "../../molecules/VisitorDrawer"
import RolesDrawer from "../../molecules/RolesDrawer"

const OrgUsers = ({
  agents,
  orgData,
  onUserCreated,
  orgName,
  deleteUser,
  isRemovingUser,
  country,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [openDrawerRoles, setOpenDrawerRoles] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleAddOrganization = () => {
    if (openDrawerCreate) {
      // setLoadingOrgs(true)
      onUserCreated()
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }

  const handleAssignRoles = () => {
    if (openDrawerRoles) {
      setUserEmail("")
    }
    setOpenDrawerRoles(!openDrawerRoles)
  }

  return (
    <>
      <Nav
        titleKey="orgs_users"
        onSearchChange={handleSearchChange}
        onFilter={null}
        onAddClick={handleAddOrganization}
        searchValue={searchValue}
        addText="create_new"
      />
      <Container>
        <CardsContainer
          isEmpty={agents.length === 0}
          emptyMessage="Aun no tienes colaboradores dentro de la organización"
          fullHeight
        >
          {agents
            .filter((u) => {
              const data = u.data()
              if (searchValue) {
                return (
                  data.displayName &&
                  data.displayName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                )
              }
              return true
            })
            .map((usr) => {
              const data = usr.data()
              const userData = {
                ...data,
                id: usr.id,
                email: data.email,
                userEmail: data.email,
              }
              return (
                <UserCard
                  key={usr.id}
                  user={userData}
                  orgData={orgData}
                  deleteUser={deleteUser}
                  isRemovingUser={isRemovingUser}
                  setUserEmail={setUserEmail}
                  setOpenDrawerRoles={setOpenDrawerRoles}
                  isAgent
                  isFromOrgView
                />
              )
            })}
        </CardsContainer>
      </Container>
      <VisitorDrawer
        open={openDrawerCreate}
        closeDrawer={handleAddOrganization}
        orgId={orgData.uid}
        orgName={orgName}
        country={country}
      />
      <RolesDrawer
        open={openDrawerRoles}
        closeDrawer={handleAssignRoles}
        userEmail={userEmail}
      />
    </>
  )
}

export const Container = styled.div`
  height: calc(100% - 150px);
`

OrgUsers.defaultProps = {
  agents: [],
  deleteUser: false,
  isRemovingUser: () => {},
}

OrgUsers.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape()),
  orgData: PropTypes.shape().isRequired,
  orgName: PropTypes.string.isRequired,
  onUserCreated: PropTypes.func.isRequired,
  deleteUser: PropTypes.func,
  isRemovingUser: PropTypes.bool,
  country: PropTypes.string.isRequired,
}

export default OrgUsers
