import React from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"
import styled from "styled-components"

Modal.setAppElement("#root")

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
    zIndex: 1100,
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    maxWidth: "960px",
    margin: "0 auto",
    padding: 0,
    border: 0,
    background: "transparent",
    height: "100vh",
  },
}

const CustomModal = ({ children, modalOpen, setModalOpen, showClose }) => {
  const modalCloseTimeout = 50
  const closeModal = () => {
    setModalOpen(false)
  }
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
      closeTimeoutMS={modalCloseTimeout}
    >
      <Wrapper>
        {children}
        {showClose && <Close onClick={closeModal}>Cerrar</Close>}
      </Wrapper>
    </Modal>
  )
}

CustomModal.defaultProps = {
  showClose: true,
}

CustomModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element.isRequired,
  ]).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Close = styled.div`
  position: absolute;
  bottom: 4.5vh;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.58);
`
export default CustomModal
