/* eslint-disable no-nested-ternary */
import styled, { keyframes } from "styled-components"

const inputHighlighter = (background) => keyframes`
  from {
    background: ${background};
  }
  to {
    width: 0;
    background: transparent;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.noMargin ? 0 : "25px")};
`

export const CustomInputLabel = styled.label`
  color: ${(props) => props.theme.color.black["300"]};
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: -7px;
  transition: 0.2s ease all;
  background: ${(props) => props.theme.color.white.normal};
`

export const Bar = styled.span`
  position: relative;
  display: block;
  width: 220px;
  &:after,
  &:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: ${(props) => props.theme.color.purple};
    transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`
export const Highlight = styled.span`
  position: absolute;
  height: 80%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`

export const CustomInput = styled.input`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.color.background.black80};
  height: 40px;
  display: block;
  width: 220px;
  border: ${(props) =>
    props.errors
      ? `1px solid ${props.theme.color.error}`
      : "1px solid rgba(0, 0, 0, 0.23)"};
  background: ${(props) => props.theme.color.white.normal};
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 8px;
  &:focus {
    outline: none;
    input:focus ~ ${Bar}:before, input:focus ~ ${Bar}:after {
      width: 50%;
    }

    & ~ ${CustomInputLabel} {
      left: 15px;
      top: -8px;
      font-size: 0.75rem;
      line-height: 1.33;
      color: ${(props) => props.theme.color.black["300"]};
      background: ${(props) => props.theme.color.white.normal};
    }
    & ~ ${Bar} {
      &:before,
      &:after {
        width: 50%;
      }
    }
    & ~ ${Highlight} {
      animation: ${(props) => inputHighlighter(props.theme.color.secondary)}
        0.3s ease;
    }
  }
  &:valid {
    & ~ ${CustomInputLabel} {
      left: 15px;
      top: -8px;
      font-size: 0.75rem;
      line-height: 1.33;
      color: ${(props) => props.theme.color.black["300"]};
      background: ${(props) => props.theme.color.white.normal};
    }
  }
`

export const Error = styled.span`
  font-size: 0.75rem;
  color: ${(props) => props.theme.color.darkPurple};
`

export const IconRightContainer = styled.div`
  position: absolute;
  right: 90px;
  top: 15px;
`
