import React from "react"
import PropTypes from "prop-types"
import CardsContainer from "../../atoms/CardsContainer"
import SessionCard from "../../molecules/SessionCard"

const Sessions = ({ data }) => {
  return (
    <>
      <CardsContainer isEmpty={false} isGrid hasFooter>
        {data &&
          data.sessions.map((s) => {
            return <SessionCard data={s} />
          })}
      </CardsContainer>
    </>
  )
}

Sessions.propTypes = {
  data: PropTypes.shape({
    displayName: PropTypes.string,
    userType: PropTypes.string,
    email: PropTypes.string,
    sessions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
}

export default Sessions
