import React, { useState, useEffect, useContext, useCallback } from "react"
import { DateRangePicker } from "react-dates"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"
import { DocumentText } from "@styled-icons/heroicons-solid/DocumentText"
import ActionButton from "../../../../components/atoms/ActionButton"
import { withFirebase } from "../../../../utils/Firebase"
import * as ROUTES from "../../../../utils/Constants/Routes"
import { AuthUserContext } from "../../../../context/Session"
import {
  paymentsGenerateUrl,
  paymentsGenerateLocalUrl,
  payoutsCreateUrl,
} from "../../../../utils/App"
import { EmptyOrLoadingContainer } from "../../../../components/atoms/Table"
import IdCard from "./children/IdCard"
import {
  Header,
  PaymentContainer,
  Title,
  List,
  FilterContainer,
  CustomTable,
  CustomTableHead,
  CustomTableRow,
  CustomTableHeader,
  CustomTableBody,
  CustomTableCell,
} from "./styles"

const Summary = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)
  const [loadingTransactions, setLoadingTransactions] = useState(true)
  const [creating, setCreating] = useState(false)
  const [transactions, setTransactions] = useState([])
  const currentDay = moment().get("date") // 9 // moment().get("date")
  const [dates, setDates] = useState({
    startDate:
      currentDay <= 15
        ? moment().startOf("month")
        : moment().startOf("month").add(15, "days"),
    endDate:
      currentDay <= 15
        ? moment().startOf("month").add(14, "days")
        : moment().endOf("month"),
  })
  const [selectedUnixDates, setSelectedUnixDates] = useState({
    startDate: null,
    endDate: null,
  })
  const [focusedInput, setfocusedInput] = useState(null)

  const getSummary = useCallback(async () => {
    const token = await firebase.getIdToken()
    const summary = await fetch(
      authUser.roles.SUPER_ADMIN
        ? paymentsGenerateUrl
        : paymentsGenerateLocalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          ...(!authUser.roles.SUPER_ADMIN && {
            country: authUser?.country || "CR",
          }),
          withSummary: true,
          dates:
            selectedUnixDates.startDate && selectedUnixDates.endDate
              ? selectedUnixDates
              : null,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    if (summary.ok) {
      const data = await summary.json()
      setLoadingTransactions(false)
      setTransactions(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.roles.SUPER_ADMIN, selectedUnixDates])

  useEffect(() => {
    if (firebase && loadingTransactions) {
      getSummary()
    }
  }, [firebase, loadingTransactions, selectedUnixDates, getSummary])

  const onAddClick = async () => {
    try {
      setCreating(true)
      const report = await fetch(payoutsCreateUrl, {
        method: "POST",
        body: JSON.stringify({
          country: "CR",
          createdBy: authUser.email,
          ...transactions,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (report) {
        getSummary()
        addToast(t("saved_successfully"), {
          appearance: "success",
          placement: "bottom-center",
        })
      } else {
        addToast("No hay datos para guardar", {
          appearance: "info",
        })
      }
      setCreating(false)
    } catch (error) {
      setCreating(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  return (
    <PaymentContainer>
      <Header>
        <Title>{t("payments_summary_description_doctors")}</Title>
        <FilterContainer>
          <DateRangePicker
            small
            noBorder
            showDefaultInputIcon
            showClearDates
            isOutsideRange={() => false}
            startDateId="startDate"
            endDateId="endDate"
            startDate={dates.startDate}
            endDate={dates.endDate}
            onDatesChange={(selectedDates) => {
              setDates({
                startDate: selectedDates.startDate,
                endDate: selectedDates.endDate,
              })
            }}
            focusedInput={focusedInput}
            onFocusChange={(f) => {
              setfocusedInput(f)
            }}
            onClose={(dt) => {
              // console.log(dt)
              // console.log(dt.startDate.unix())
              // console.log(dt.endDate.unix())
              setSelectedUnixDates({
                startDate: dt.startDate.startOf("day").unix(),
                endDate: dt.endDate.endOf("day").unix(),
              })
              setLoadingTransactions(true)
            }}
          />
          <ActionButton
            disabled={
              get(transactions, "transactions", []).length <= 0 ||
              authUser.roles.SUPER_ADMIN ||
              creating
            }
            onActionClick={onAddClick}
            dark
          >
            <FilterContainer>
              <DocumentText size={18} />
              <span>{t("create_report")}</span>
            </FilterContainer>
          </ActionButton>
        </FilterContainer>
      </Header>
      <List>
        <CustomTable>
          {!loadingTransactions && (
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeader minWidth="170px">
                  {authUser.roles.SUPER_ADMIN
                    ? t("country_label")
                    : t("display_name_label")}
                </CustomTableHeader>
                <CustomTableHeader minWidth="150px">DNI</CustomTableHeader>
                <CustomTableHeader minWidth="170px">Cuenta</CustomTableHeader>
                <CustomTableHeader>Tipo</CustomTableHeader>
                <CustomTableHeader>{t("session_type")}</CustomTableHeader>
                <CustomTableHeader>{t("session_pricing")}</CustomTableHeader>
                <CustomTableHeader>{t("fees_label")}</CustomTableHeader>
                <CustomTableHeader>
                  {t("donation_expenses_label")}
                </CustomTableHeader>
                <CustomTableHeader>{t("total_label")}</CustomTableHeader>
                <CustomTableHeader>{t("status_label")}</CustomTableHeader>
              </CustomTableRow>
            </CustomTableHead>
          )}
          <CustomTableBody>
            {!loadingTransactions &&
            get(transactions, "summary", []).length > 0 ? (
              get(transactions, "summary", []).map((ts) => {
                return (
                  <CustomTableRow
                    key={`sum-${
                      authUser.roles.SUPER_ADMIN ? ts.country : ts.provider
                    }`}
                  >
                    <CustomTableCell>
                      <Link
                        to={{
                          state: {
                            transactions: get(transactions, "transactions", []),
                          },
                          pathname: ROUTES.PAYMENT_DETAIL.replace(
                            ":id",
                            authUser.roles.SUPER_ADMIN
                              ? ts.country
                              : ts.provider
                          ),
                        }}
                      >
                        {authUser.roles.SUPER_ADMIN
                          ? ts.country
                          : ts.providerName}
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell>
                      <IdCard
                        provider={ts.provider}
                        providerId={ts.providerIdNumber}
                      />
                    </CustomTableCell>
                    <CustomTableCell>
                      {ts.providerBankAccount || "N/A"}
                    </CustomTableCell>
                    <CustomTableCell>
                      {ts.providerBankAccountType || "N/A"}
                    </CustomTableCell>
                    <CustomTableCell>Médico</CustomTableCell>
                    <CustomTableCell>{`${parseFloat(ts.total).toFixed(
                      2
                    )}`}</CustomTableCell>
                    <CustomTableCell>{`${parseFloat(ts.totalFees).toFixed(
                      2
                    )}`}</CustomTableCell>
                    <CustomTableCell>{`${parseFloat(ts.totalDonations).toFixed(
                      2
                    )}`}</CustomTableCell>
                    <CustomTableCell>{`${parseFloat(ts.payoutTotal).toFixed(
                      2
                    )}`}</CustomTableCell>
                    <CustomTableCell>Pendiente</CustomTableCell>
                  </CustomTableRow>
                )
              })
            ) : (
              <EmptyOrLoadingContainer>
                {loadingTransactions ? t("loading") : "No results"}
              </EmptyOrLoadingContainer>
            )}
          </CustomTableBody>
        </CustomTable>
      </List>
    </PaymentContainer>
  )
}

Summary.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Summary)
