import React from "react"
import PropTypes from "prop-types"

import { InputWrapper, CustomInput, CustomInputLabel } from "./styles"

const BoxWithInput = ({
  defaultValue,
  onChange,
  onBlur,
  type,
  id,
  name,
  labelName,
}) => (
  <InputWrapper>
    <CustomInputLabel>{labelName}</CustomInputLabel>
    <CustomInput
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      id={id}
      multiple
    />
  </InputWrapper>
)

BoxWithInput.defaultProps = {
  defaultValue: "",
}

BoxWithInput.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default BoxWithInput
