/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import PropTypes from "prop-types"
import styled from "styled-components"
import { CameraAlt } from "@styled-icons/material/CameraAlt"
import isEmpty from "lodash.isempty"
import ImageLoadable from "../../atoms/ImageLoadable"
import Spinner from "../../atoms/Spinner"

const imageMIMEType = "image/*"
const pdfMIMEType = "application/pdf"

const getSourceType = (srcType) => {
  const types = {
    imageType: false,
    pdfType: false,
  }

  if (Array.isArray(srcType)) {
    if (srcType.includes(pdfMIMEType)) {
      types.pdfType = true
    }

    if (srcType.includes(imageMIMEType)) {
      types.imageType = true
    }
  }

  if (typeof srcType === "string") {
    if (srcType.indexOf(pdfMIMEType) >= 0) {
      types.pdfType = true
    }

    if (srcType.indexOf(imageMIMEType) >= 0) {
      types.imageType = true
    }
  }

  return types
}

const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
`

const FilePreview = styled.object`
  pointer-events: none;
  border-radius: "22px";
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
`

const ProductImage = ({
  src,
  disabled,
  onImageLoaded,
  hideControls,
  width,
  height,
  fieldName,
  emptyMessage,
  error,
  srcType,
  isLoading,
}) => {
  const [isPDF, setPdf] = useState(false)
  const [isImage, setImage] = useState(false)
  const [file, setFile] = useState({})

  const setFileType = (customType) => {
    const { pdfType, imageType } = getSourceType(srcType)
    if (customType) {
      const customImage = customType === "image/"
      const customPdf = customType === pdfMIMEType

      if (customImage) {
        setImage(true)
      } else {
        setImage(false)
      }
      if (customPdf) {
        setPdf(true)
      } else {
        setPdf(false)
      }
      return
    }

    if (pdfType) setPdf(true)
    if (imageType) setImage(true)
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const currentFile = acceptedFiles?.[0]

      if (!currentFile) {
        return
      }
      const data = {
        blobUrl: URL.createObjectURL(currentFile),
        name: `${currentFile.name}_${Date.now()}`,
      }
      const blob = await fetch(data.blobUrl).then((r) => r.blob())
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(currentFile),
          raw: currentFile,
        })
      )
      if (onImageLoaded) {
        onImageLoaded(fieldName, { ...data, blob })
      }
    },
    [onImageLoaded, file, fieldName]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: srcType,
    multiple: false,
    disabled: disabled || hideControls,
    onDrop,
  })

  const getSrc = () => {
    if (Object.keys(file).length > 0) {
      return file.preview
    }
    return src && src !== "local" ? src : null
  }

  const renderFilePicker = React.useCallback(() => {
    const source = getSrc()
    if (
      (isImage && !isPDF) ||
      (isImage &&
        isPDF &&
        !isEmpty(file) &&
        file.raw?.type.indexOf("image/") === 0) ||
      (isImage && isPDF && isEmpty(file) && !source) ||
      (isImage &&
        isPDF &&
        isEmpty(file) &&
        source &&
        source.indexOf(".pdf") === -1)
    ) {
      return (
        <ImageLoadable
          width={width}
          height={height}
          src={source}
          hideControls={hideControls}
          emptyMessage={!isLoading && emptyMessage}
        />
      )
    }

    return (
      <label htmlFor="#file-message" role="button">
        <FilePreview
          width={width}
          height={height}
          title="pdf preview"
          data={source}
        />
        <FileInput type="file" id="file-message" accept={srcType} />
      </label>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImage, isPDF, srcType])

  useEffect(
    () => () => {
      URL.revokeObjectURL(file.preview)
    },
    [file]
  )

  useEffect(() => {
    setFileType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      {...getRootProps({ refKey: "innerRef" })}
      hideControls={hideControls}
      error={error}
    >
      {!hideControls && <input {...getInputProps()} />}
      {renderFilePicker()}
      {!hideControls && (
        <CamContainer>
          <CameraAlt size={18} />
        </CamContainer>
      )}
      {isLoading && <Spinner isLoading />}
    </Container>
  )
}

const Container = styled.div`
  background: #f2f2f2;
  position: relative;
  display: inline-flex;
  border-radius: ${(props) => (props.hideControls ? "0px" : "22px")};
  border: ${(props) => (props.error ? "2px solid red" : "none")};
  margin-bottom: 8px;
  margin-right: 8px;
  padding: ${(props) => (props.hideControls ? "0px" : "4px")};
  box-sizing: border-box;
  cursor: ${(props) => (props.hideControls ? "default" : "pointer")};
  outline: none !important;
  & > div {
    display: flex;
    min-width: 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    & > img {
      display: block;
      width: ${(props) => (props.hideControls ? "100%" : "auto")};
      height: ${(props) => (props.hideControls ? "auto" : "100%")};
    }
  }
`

const CamContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
`

ProductImage.defaultProps = {
  text: "",
  src: null,
  disabled: false,
  onImageLoaded: () => null,
  hideControls: false,
  width: 194,
  height: 207,
  fieldName: "mainPhotoURL",
  emptyMessage: "Image",
  error: false,
  srcType: "image/*",
  isLoading: false,
}

ProductImage.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onImageLoaded: PropTypes.func,
  hideControls: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fieldName: PropTypes.string,
  emptyMessage: PropTypes.string,
  srcType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isLoading: PropTypes.bool,
}

export default ProductImage
