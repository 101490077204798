/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from "react"
import get from "lodash.get"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import * as Yup from "yup"
import { v4 as uuidv4 } from "uuid"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import ProductImage from "../ProductImage"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"
import {
  generatePassword,
  getReferenceCode,
  countries,
} from "../../../utils/App"
import organizationRoles from "../../../utils/Constants/Organization_Types"
import businessEmployees from "../../../utils/Constants/Business_Employees"
import { StyledForm, SubmitButton, Title, ImagesContainer } from "./styles"

const OrgDrawer = ({ firebase, open, closeDrawer }) => {
  const createAdminUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
  const createDrugstoreUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/drugstoreCreate`

  const { t, i18n } = useTranslation()
  const authUser = useContext(AuthUserContext)
  const formRef = useRef()

  const [isCreating, setIsCreating] = useState(false)
  const [created, setCreated] = useState({})
  const [country, setCountry] = useState(
    authUser.roles.SUPER_ADMIN
      ? undefined
      : countries.filter((c) => c.value === authUser.country)
  )
  const [orgType, setOrgType] = useState(undefined)
  const [employeeRange, setEmployeeRange] = useState(undefined)

  const lang = get(i18n, "language", "es").split("-")

  useEffect(() => {
    const createNewUser = async () => {
      await firebase.saveUserData({
        email: created.email,
        data: {
          displayName: created.name,
          email: created.email,
          active: true,
          status: "active",
          country: created.country,
          userType:
            created.type === "drugstore" ? "business_partner" : "client",
          roles: {
            BUSINESS_ADMIN: true,
            ...(created.type &&
              created.type === "drugstore" && {
                DRUGSTORE_ADMIN: true,
              }),
          },
          organizationId: created.orgId,
        },
      })
      setCreated({})
      setIsCreating(false)
      closeDrawer()
    }
    if (Object.keys(created).length > 0) {
      createNewUser()
    }
  }, [created, firebase, closeDrawer])

  const createOrganization = async (values) => {
    try {
      setIsCreating(true)
      let imageURL = null
      if (values?.logoURL?.name) {
        const uploadDir = `organizations/avatars/${values?.logoURL?.name}`

        const snapshot = await firebase.uploadToStorage(
          uploadDir,
          values.logoURL.blob
        )
        imageURL = await snapshot.ref.getDownloadURL()
      }

      const token = await firebase.getIdToken()
      await fetch(createAdminUrl, {
        method: "POST",
        body: JSON.stringify({ user: values }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then(async (data) => {
        if (data.status === 200) {
          const userData = await data.json()
          setTimeout(async () => {
            let newOrg = null
            if (values.type === "drugstore") {
              const getCodeToReference = getReferenceCode()
              const responseData = await fetch(createDrugstoreUrl, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  drugstore: {
                    coordinates: "",
                    state: "",
                    city: "",
                    district: "",
                    address: "",
                    country: values.country,
                    accountType: "",
                    bankName: "",
                    iban: "",
                    currencyType: "",
                    businessName: values.businessName,
                    phoneNumber: values.orgPhoneNumber,
                    email: values.email,
                    orgEmail: values.orgEmail,
                    bannerUrl: imageURL || "",
                    drugstoreType: "",
                    licenceNumber: values.id,
                    issuedBy: "",
                    delivery: "",
                    discount: "",
                    deliveryRadio: 0,
                    createdAt: Date.now(),
                  },
                  user: {
                    ...userData?.data,
                    displayName: values.name,
                    phoneNumber: values.phoneNumber,
                    phone: values.phoneNumber,
                    referenceCode: getCodeToReference,
                  },
                }),
              })
              newOrg = await responseData.json()
            } else {
              newOrg = await firebase.saveNewOrg({
                data: {
                  id: uuidv4(),
                  legalId: values.id,
                  name: values.name,
                  businessName: values.businessName,
                  phoneNumber: values.orgPhoneNumber,
                  email: values.email,
                  orgEmail: values.orgEmail,
                  active: true,
                  country: values.country,
                  referenceCode: getReferenceCode(),
                  type: values.type,
                  employeeRange: values.employeeRange,
                  logoURL: imageURL || "",
                  createdAt: Date.now(),
                  toActivate: true,
                },
              })

              await firebase.addOrgColaborator({
                orgId: newOrg,
                email: values.email,
                agentData: {
                  email: values.email,
                  displayName: values.name,
                  phoneNumber: values.phoneNumber,
                  phone: values.phoneNumber,
                  active: true,
                  createdAt: Date.now(),
                  role: "business_partner",
                },
              })
            }
            fetch(mailerUrl, {
              method: "POST",
              body: JSON.stringify({
                data: {
                  templateId: 2358080, // invite-admin
                  email: values.email,
                  variables: {
                    subject: "Te han invitado como administrador en DOC",
                    displayName: values.name,
                    orgName: values.businessName,
                    password: values.password,
                    websiteLink: process.env.REACT_APP_ADMIN_APP_SIGNIN,
                  },
                },
              }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
            setCreated({
              ...values,
              orgId: values.type === "drugstore" ? newOrg.id : newOrg,
            })
            formRef.current.resetForm()
            setOrgType(undefined)
            setEmployeeRange(undefined)
          }, 3000)
        }
      })
    } catch {
      setIsCreating(false)
    }
  }

  return (
    <Drawer openDrawer={open} closeDrawer={closeDrawer} title={t("create_org")}>
      <div>
        <Title>{t("create_org_title")}</Title>
        <Formik
          innerRef={formRef}
          initialValues={{
            email: "",
            password: generatePassword(),
            name: "",
            businessName: "",
            phoneNumber: "",
            orgPhoneNumber: "",
            orgEmail: undefined,
            logoURL: "",
            type: "",
            employeeRange: "",
            available: true,
            country: authUser.roles.SUPER_ADMIN ? undefined : authUser.country,
            id: "",
          }}
          onSubmit={async (values) => {
            createOrganization(values)
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            name: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.string().required(t("error_all_fields_required")),
            orgPhoneNumber: Yup.string().required(
              t("error_all_fields_required")
            ),
            id: Yup.string().required(t("error_all_fields_required")),
            country: Yup.string().required(t("error_all_fields_required")),
            businessName: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <ImagesContainer>
                <ProductImage
                  width={150}
                  height={150}
                  srcType="image/*"
                  text={`${t("photo_label")} *`}
                  src={props.values.logoURL}
                  onImageLoaded={props.setFieldValue}
                  fieldName="logoURL"
                  emptyMessage="Logo (opcional)"
                  error={props.errors.logoURL}
                />
              </ImagesContainer>
              <CustomInput
                touched={props.touched.name}
                errors={props.errors.name}
                icon="user"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={`${t("name_label")} Administrador`}
                type="text"
                id="name"
                name="name"
              />
              <CustomInput
                touched={props.touched.businessName}
                errors={props.errors.businessName}
                icon="user"
                value={props.values.businessName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("business_name_label")}
                type="text"
                id="businessName"
                name="businessName"
              />
              <CustomInput
                touched={props.touched.id}
                errors={props.errors.id}
                icon="user"
                value={props.values.id}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("id_businees_label")}
                type="text"
                id="id"
                name="id"
              />
              {authUser.roles.SUPER_ADMIN && (
                <CustomSelect
                  value={country}
                  formKey="country"
                  lngKey="country_label"
                  handleChange={(event, value) => {
                    props.setFieldValue("country", value)
                    setCountry(countries.filter((c) => c.value === value))
                  }}
                  items={countries}
                  showError={
                    !props.values.country && Boolean(props.errors.country)
                  }
                />
              )}
              {get(organizationRoles, lang[0], false) && (
                <CustomSelect
                  value={orgType}
                  formKey="type"
                  lngKey="orgs_type"
                  handleChange={(event, value) => {
                    props.setFieldValue("type", value)
                    setOrgType(
                      organizationRoles[lang].filter((c) => c.value === value)
                    )
                  }}
                  items={organizationRoles[lang]}
                  showError={!props.values.type && Boolean(props.errors.type)}
                />
              )}
              {props.values.type === "business" && (
                <CustomSelect
                  value={employeeRange}
                  formKey="employeeRange"
                  lngKey="employeeRange"
                  handleChange={(event, value) => {
                    props.setFieldValue("employeeRange", value)
                    setEmployeeRange(
                      businessEmployees[lang].filter((r) => r.value === value)
                    )
                  }}
                  items={businessEmployees[lang]}
                />
              )}
              <CustomInput
                touched={props.touched.orgEmail}
                errors={props.errors.orgEmail}
                icon="email"
                value={props.values.orgEmail}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("org_email_address")}
                type="email"
                id="orgEmail"
                name="orgEmail"
              />
              <CustomInput
                touched={props.touched.email}
                errors={props.errors.email}
                icon="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("admin_email_address")}
                type="email"
                id="email"
                name="email"
              />
              <CustomInput
                touched={props.touched.orgPhoneNumber}
                errors={props.errors.orgPhoneNumber}
                icon="phone"
                value={props.values.orgPhoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("org_phone_label")}
                type="text"
                id="orgPhoneNumber"
                name="orgPhoneNumber"
              />
              <CustomInput
                touched={props.touched.phoneNumber}
                errors={props.errors.phoneNumber}
                icon="phone"
                value={props.values.phoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("admin_phone_label")}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
              />
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
                disabled={!props.dirty || props.isSubmitting || !props.isValid}
              >
                {t("create")}
              </SubmitButton>
              {/* {error && <p>{error.message}</p>} */}
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

OrgDrawer.defaultProps = {
  open: false,
}

OrgDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
}
export default withFirebase(OrgDrawer)
