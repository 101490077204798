// eslint-disabled
import React from "react"
import styled from "styled-components"
// eslint-disable-next-line import/no-named-as-default
import SignInForm from "../../components/templates/SignIn"
import Logo from "../../components/atoms/Logo"
import Back from "./images/login-right.png"
// import ToyFaces from '../../components/atoms/Images/ToyFaces/login-right.png'
const SignIn = () => {
  return (
    <Container>
      <Main>
        <LeftContainer>
          <Header>
            <Logo />
            <p>Doctors on call</p>
          </Header>
          <SignInForm />
        </LeftContainer>
        <RightContainer>{/* <ToyFaces /> */}</RightContainer>
      </Main>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 86px;
  padding-left: 30px;
  padding-top: 24px;
  & > svg {
    height: 30px;
  }
`

const Main = styled.div`
  height: 100vh;
  display: inherit;
  width: 100%;
`

const LeftContainer = styled.div`
  display: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 550px);
  height: 100%;
  & > div:nth-child(2) {
    min-width: 440px;
    width: 50%;
  }
`

const RightContainer = styled.div`
  width: 550px;
  height: 100%;
  background-image: url(${Back});
`

export default SignIn
