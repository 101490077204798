import React from "react"
import PropTypes from "prop-types"

import {
  InputWrapper,
  CustomInput,
  CustomInputLabel,
  Bar,
  Highlight,
  Error,
  IconRightContainer,
} from "./styles"

const Input = ({
  name,
  value,
  onChange,
  onFocus,
  type,
  required,
  labelName,
  id,
  noMargin,
  disabled,
  multiple,
  small,
  readonly,
  errors,
  touched,
  showError,
  iconRight,
  onBlur,
}) => (
  <InputWrapper noMargin={noMargin}>
    <CustomInput
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      required={required}
      id={id}
      disabled={disabled}
      multiple={multiple}
      isSmall={small}
      readOnly={readonly}
      onFocus={onFocus}
      errors={(touched && errors) || showError}
      onBlur={onBlur}
    />
    <Highlight />
    <Bar />
    <CustomInputLabel readOnly={readonly}>{labelName}</CustomInputLabel>
    {((touched && errors) || showError) && <Error>{errors}</Error>}
    <IconRightContainer>{iconRight && iconRight}</IconRightContainer>
  </InputWrapper>
)

Input.defaultProps = {
  disabled: false,
  multiple: false,
  small: false,
  readonly: false,
  touched: false,
  errors: "",
  value: "",
  required: false,
  noMargin: false,
  onFocus: () => {},
  onChange: () => {},
  showError: false,
  iconRight: false,
  onBlur: () => {},
}
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  labelName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  small: PropTypes.bool,
  readonly: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  touched: PropTypes.bool,
  showError: PropTypes.bool,
  iconRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onBlur: PropTypes.func,
}

export default Input
