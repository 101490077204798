import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import { getInitials } from "../../../utils/App"

import {
  CardContainer,
  Badge,
  Info,
  Label,
  Title,
  ClientCard,
  Avatar,
  Initials,
} from "./styles"

const SessionCard = ({ data }) => {
  const { t } = useTranslation()
  return (
    <CardContainer>
      <Title>{t("session_card_title")}</Title>
      <Badge donated={data.refunded}>
        <span>{data.refunded ? t("donated") : t("finished")}</span>
      </Badge>
      <Info>
        <p>{dayjs(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</p>
        <p>$ {data.metadata.sessionTotal}</p>
        <Label>
          <span>Cliente</span>
        </Label>
        <ClientCard>
          <Avatar w={40} h={40}>
            <Initials>
              {getInitials(
                get(data, ["metadata", "clientName"], data.metadata.clientEmail)
              )}
            </Initials>
          </Avatar>
          <p>{data.metadata.clientEmail}</p>
        </ClientCard>
      </Info>
    </CardContainer>
  )
}
SessionCard.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default SessionCard
