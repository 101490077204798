import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import get from "lodash.get"
import BackIcon from "../../components/atoms/BackIcon"
import StatsCard from "../../components/atoms/StatsCard"
import TextCard from "../../components/atoms/TextCard"
import { withAuthorization } from "../../context/Session"
import { accountsCurrencyType, accountsType } from "../../utils/App"

const currencies = {
  BO: "BOB",
  CL: "CLP",
  COL: "COP",
  EC: "USD",
  CR: "CRC",
  SV: "USD",
  GT: "GTQ",
  HN: "HNL",
  MX: "MXN",
  NI: "NIO",
  PA: "USD",
  PE: "SOL",
  DO: "DOP",
}

const UsersForms = () => {
  const { t } = useTranslation()
  const {
    state: { user },
  } = useLocation()

  const getAccountType = () => {
    const value = get(user, ["business", "bankAccountType"])
    const type = accountsType.find((b) => {
      return b.value === value
    })
    return type ? t(type.label) : ""
  }

  const getAccountCurrencyType = () => {
    const value = get(user, ["business", "bankAccountCurrency"])
    const type = accountsCurrencyType.find((b) => {
      return b.value === value
    })
    return type ? t(type.label) : ""
  }

  const getCurrencyCountry = user?.countryBusiness || user?.country
  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          {get(user, ["displayName"], "Back")}
        </Back>
      </Nav>
      <Container>
        <InfoWrapper>
          <Title>{t("business_form_label")}</Title>
          <Title>{t("fees")}</Title>
          <TextCard
            label={t("virtual_quote")}
            text={`${currencies[getCurrencyCountry]} ${get(
              user,
              "pricing.virtual.consultationFee",
              0
            )}`}
          />
          <TextCard
            label={t("presencial_quote")}
            text={`${currencies[getCurrencyCountry]} ${get(
              user,
              "pricing.faceToFace.consultationFee",
              0
            )}`}
          />
          <TextCard
            label={t("home_consultation")}
            text={`${currencies[getCurrencyCountry]} ${get(
              user,
              "pricing.home.consultationFee",
              0
            )}`}
          />
          <TextCard
            label={t("questions")}
            text={`${currencies[getCurrencyCountry]} ${get(
              user,
              "pricing.questions.consultationFee"
            )}`}
          />
          <Title>{t("information")}</Title>
          {user?.business?.companyName && (
            <TextCard
              label={t("q1_business_companyName_label")}
              text={get(user, ["business", "companyName"])}
            />
          )}
          <TextCard
            label={t("q4_business_companyPhone_label")}
            text={
              get(user, ["business", "companyPhone"]) ||
              get(user, "phoneNumber.formattedNumber")
            }
          />
          <TextCard
            label={t("q8_business_bankName_label")}
            text={get(user, ["business", "bankName"])}
          />
          <TextCard
            label={t("q9_business_bankAccount_label")}
            text={get(user, ["business", "bankAccount"])}
          />
          <TextCard
            label={t("q9_business_bankAccountType_label")}
            text={getAccountType()}
          />
          <TextCard
            label={t("q9_business_bankAccountCurrency_label")}
            text={getAccountCurrencyType()}
          />
          <Title>{t("academic_form_label")}</Title>
          <TextCard
            label={t("q3_academic_bureau_label")}
            text={get(user, ["academic", "bureau"])}
          />
          <TextCard
            label={t("q4_academic_license_label")}
            text={get(user, ["academic", "license"])}
          />
          <TextCard
            label={t("q2_academic_profession_label")}
            text={
              user?.professionLangLabel
                ? get(user, ["academic", "professionLangLabel"])
                : get(user, ["academic", "profession"])
            }
          />
          <TextCard
            label={t("q5_academic_university_label")}
            text={get(user, ["academic", "university"])}
          />
          <TextCard
            label={t("q8_academic_biography_label")}
            text={get(user, ["academic", "biography"])}
          />
          <TextCard
            label={t("q9_academic_other_label")}
            text={get(user, ["academic", "other"])}
          />
        </InfoWrapper>
      </Container>
      <Footer>
        <StatsCard number={get(user, ["rating"], 0)} label="Rating" />
        {user.userType && user.userType !== "medical_visitor" && (
          <>
            <StatsCard number={get(user, "count", "0")} label="Sesiones" />
            <StatsCard
              number={get(user, "countDonated", "0")}
              label="Donadas"
            />
            <StatsCard
              number={get(user, "countNotDonated", "0")}
              label="Pagadas"
            />
          </>
        )}
      </Footer>
    </div>
  )
}

const Title = styled.h4``

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 185px);
  overflow: hidden;
`

const Footer = styled.section`
  height: 103px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 80px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(UsersForms)
