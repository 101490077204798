/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import get from "lodash.get"
import has from "lodash.has"
import unset from "lodash.unset"
import startCase from "lodash.startcase"
import replace from "lodash.replace"
import PropTypes, { string } from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { usePaymentInputs } from "react-payment-inputs"
import Cards from "react-credit-cards"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import moment from "moment"
import CustomModal from "../../atoms/CustomModal"
import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  ModalContainer,
  Description,
} from "../../atoms/ModalElements"
import CreditCard from "../../atoms/CreditCard"
import {
  tokenDeleteUrl,
  customerCreateUrl,
  tokenCreateUrl,
  paymentChargeUrl,
  getQrCodeUrl,
  mailerUrl,
} from "../../../utils/App"
import { withFirebase } from "../../../utils/Firebase"
import creditCard from "../../../static/images/credit-card.png"
import {
  Container,
  InfoWrapper,
  StyledForm,
  SubmitButton,
  PaymentsHeader,
  AddButton,
  DoubleInputWrapper,
  InputWrapper,
  CardInput,
  ErrorMessage,
  BillingSection,
  BillingContainer,
  BillingAddres,
  BillingTitle,
  BillingText,
} from "./styles"
import "react-credit-cards/es/styles-compiled.css"

const OrgPayments = ({ org, orgId, addresses, firebase }) => {
  const { addToast } = useToasts()
  const { getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs()
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDoctorCheckModalOpen, setIsDoctorCheckModalOpen] = useState(false)
  const [address, setAddress] = useState(
    addresses && addresses.length > 0 ? addresses[0].data() : null
  )
  const [visibleAddAddress, setVisibleAddAddress] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [tokens, setTokens] = useState({})
  const [payload, setPayload] = useState(null)
  const [stripeUserId, setStripeUserId] = useState(null)
  const [doctors, setDoctors] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    const createStripeUser = async () => {
      try {
        const token = await firebase.getIdToken()
        const customer = await fetch(customerCreateUrl, {
          method: "POST",
          body: JSON.stringify({
            client: {
              uid: org.uid,
              email: org.email,
              name: startCase(org.name),
              country: org.country,
            },
            isOrg: true,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        const parsedCustomer = await customer.json()
        if (parsedCustomer && parsedCustomer.stripeUserId == null) {
          setPayload(null)
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
          // Alert.alert("Error", I18n.t("something_went_wrong"))
        } else {
          setStripeUserId(parsedCustomer.stripeUserId)
        }
      } catch (error) {
        setPayload(null)
        // setSaving(false)
      }
    }
    if (!org.stripeUserId) {
      createStripeUser()
    } else {
      setStripeUserId(org.stripeUserId)
    }
  }, [firebase, orgId])

  useEffect(() => {
    let unsubscribe
    if (firebase && org.uid) {
      const docs = tokens
      unsubscribe = firebase.subscribeToTokens({
        orgId: org.uid,
        onSnapshot: (s) => {
          s.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (!has(docs, change.doc.id)) {
                docs[change.doc.id] = change.doc.data()
              }
            }
            if (change.type === "modified") {
              docs[change.doc.id] = change.doc.data()
            }
            if (change.type === "removed") {
              unset(docs, change.doc.id)
            }
          })
          setTokens({ ...docs })
          setLoading(false)
        },
      })
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [firebase, orgId])

  useEffect(() => {
    if (firebase && org.uid) {
      const getDoctors = async () => {
        const orgDoctors = await firebase.getOrgDoctorList(org.uid)
        setDoctors(orgDoctors)
      }
      if (doctors.length === 0) {
        getDoctors()
      }
    }
  }, [firebase, orgId])

  useEffect(() => {
    if (deleting && payload != null && stripeUserId) {
      const deleteCardToken = async () => {
        try {
          const token = await firebase.getIdToken()
          const card = await fetch(tokenDeleteUrl, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          const parsedCard = await card.json()
          setDeleting(false)
          if (parsedCard && parsedCard.status === "deleted") {
            addToast("Eliminada correctamente!", {
              appearance: "success",
              placement: "bottom-center",
            })
          } else {
            addToast(t("unexpected_error"), {
              appearance: "error",
            })
          }
        } catch (error) {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        }
      }

      deleteCardToken()
    }
  }, [deleting, payload])

  const generatePayload = async (cardToken = null) => {
    let { employeeRange } = org
    let basic = 0
    let premium = 0
    let adminFee = 0
    let premiumFee = 0
    let basicFee = 0
    let subTotal = 0

    let orgDoctors = doctors.filter((d) => d.active)
    if (cardToken) {
      const currentDate = moment(Date.now()).startOf("day").valueOf()

      orgDoctors = doctors.filter((d) => d.nextPaymentDate <= currentDate)

      const nextPaymentDate = org.nextPaymentDate
        ? moment(org.nextPaymentDate).startOf("day").valueOf()
        : 0
      if (nextPaymentDate <= currentDate) employeeRange = 0
    }

    const pricingSnapshot = await firebase.getPricing()

    if (pricingSnapshot.exists) {
      const getData = pricingSnapshot.data()

      adminFee = getData?.businessCharges?.adminFee
      premiumFee = getData?.businessCharges?.premium
      basicFee = getData?.businessCharges?.basic
    }

    orgDoctors.forEach((d) => {
      if (d.plan) {
        switch (d.plan) {
          case "basic":
            basic += 1
            break
          case "premium":
            premium += 1
            break
          default:
            break
        }
      }
    })

    subTotal =
      employeeRange * adminFee + basic * basicFee + premium * premiumFee

    const orgTokens = await firebase.getOrgTokens({
      orgId: org.uid,
    })

    const addressSnapshot = await firebase.getOrgAddress(org.uid)
    let orgAddress = null

    if (!addressSnapshot.empty) {
      addressSnapshot.forEach((d) => {
        const addressData = d.data()
        orgAddress = {
          city: addressData.address_city,
          country: addressData.address_country,
          line1: addressData.address_line1,
          line2: addressData.address_line2,
          state: addressData.address_state,
          zip: addressData.address_zip,
        }
      })
    }

    const clientPayload = {
      email: org.email,
      customerId: stripeUserId,
    }

    const chargePayload = {
      amount: subTotal,
      currency: "usd",
      description: `Business subscription: [${org?.businessName}]`,
      paymentType: "card",
    }

    // Set metadata
    const data = new Map()
    data.set("employeeRange", employeeRange)
    data.set("adminFee", adminFee)
    data.set("basicFee", basicFee)
    data.set("premiumFee", premiumFee)
    data.set("basic", basic)
    data.set("premium", premium)

    return {
      chargePayload: {
        client: clientPayload,
        charge: chargePayload,
        token: cardToken ?? orgTokens[0].token,
        metadata: Array.from(data),
        isDirectCharge: true,
        transactionType: "BusinessSubscription",
      },
      invoice: {
        customerId: org.uid,
        customerName: org.businessName,
        customerType: org.type,
        createdAt: Date.now(),
        email: org.email,
        paidAmount: chargePayload.amount,
        subTotal: chargePayload.amount,
        taxes: 0,
        total: chargePayload.amount,
        balance: 0,
        type: "receipt",
        address: orgAddress,
      },
      invoiceItems: [
        {
          amount: basicFee,
          description: "Basic package",
          qty: 1,
          qtyDoctors: basic,
          totalAmount: basic * basicFee,
        },
        {
          amount: premiumFee,
          description: "Premium package",
          qty: 1,
          qtyDoctors: premium,
          totalAmount: premium * premiumFee,
        },
        {
          amount: adminFee,
          description: `Administrative fee for ${employeeRange * 200} em`,
          qty: employeeRange,
          totalAmount: employeeRange * adminFee,
        },
      ],
    }
  }

  const businessCharge = async (cardToken = null) => {
    let result = false
    const { chargePayload, invoice, invoiceItems } = await generatePayload(
      cardToken
    )
    const token = await firebase.getIdToken()
    const res = await fetch(paymentChargeUrl, {
      method: "POST",
      body: JSON.stringify(chargePayload),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (res.ok) {
      const parsedRes = await res.json()

      const s = await firebase.getInvoiceConsecutive({
        country: org.country,
      })
      const now = new Date()
      const consecutive = org.country
        .toUpperCase()
        .concat(
          now.getFullYear().toString().slice(-2),
          (now.getMonth() + 1).toString().padStart(2, "0"),
          s.toString().padStart(4, "0")
        )

      const transaction = await firebase.getTransaction(parsedRes.transactionId)
      const { payment_method_details: paymentMethod, currency } =
        transaction.data()

      const invoiceId = await firebase.saveInvoice({
        invoiceData: {
          ...invoice,
          invoiceNumber: consecutive,
          transactionId: parsedRes.transactionId,
          currency,
          cardBrand: paymentMethod?.card?.brand,
          last4: paymentMethod?.card?.last4,
        },
      })

      await firebase.saveInvoiceItems({
        invoiceId,
        invoiceItems,
      })

      let orgDoctors = doctors.filter((d) => d.active)
      let orgToCharge = true
      if (cardToken) {
        const currentDate = moment(Date.now()).startOf("day").valueOf()

        orgDoctors = doctors.filter((d) => d.nextPaymentDate <= currentDate)
        const nextPaymentDate = org.nextPaymentDate
          ? moment(org.nextPaymentDate).startOf("day").valueOf()
          : 0
        if (nextPaymentDate <= currentDate) orgToCharge = false
      }

      if (orgToCharge) {
        await firebase.updateOrgData({
          orgId: org.uid,
          orgData: {
            toActivate: false,
            subscriptionPaid: true,
            subscriptionPaymentRetry: 0,
            nextPaymentDate: moment(now)
              .add(1, "months")
              .startOf("day")
              .valueOf(),
          },
        })
      }

      const promises = []

      for (const doc of orgDoctors) {
        promises.push(
          firebase.updateOrgDoctor({
            orgId: org.uid,
            id: doc.id,
            doctorData: {
              subscriptionPaid: true,
              subscriptionPaymentRetry: 0,
              active: true,
              nextPaymentDate: moment(now)
                .add(1, "months")
                .startOf("day")
                .valueOf(),
            },
          })
        )
        if (cardToken) {
          const orgData = {}
          const collectionName = "organizations"
          orgData[org.id] = {
            active: true,
          }
          // Update Provider
          promises.push(
            firebase.updateProvider({
              email: doc.email,
              providerData: { [collectionName]: orgData },
            })
          )
        }
      }

      await Promise.all(promises)

      const qrCode = await fetch(getQrCodeUrl, {
        method: "POST",
        body: JSON.stringify({
          url: `${process.env.REACT_APP_ORG_EMPLOYEE_REGISTER_URL}${org.uid}`,
          organizationId: org.uid,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      const qrCodeResponse = await qrCode.json()

      const profile = await firebase.user(org.email).get()
      const dataProfile = await profile.data()

      setTimeout(async () => {
        await fetch(mailerUrl, {
          method: "POST",
          body: JSON.stringify({
            data: {
              templateId: 6154227,
              email: org.email,
              variables: {
                subject: `Registro de colaboradores en DOC`,
                orgName: org.businessName,
                displayName: dataProfile.displayName,
                qrCodeUrl: qrCodeResponse.qrUrl,
                url: `${process.env.REACT_APP_ORG_EMPLOYEE_REGISTER_URL}${org.uid}`,
              },
            },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
      }, 500)
      result = true
    }

    return result
  }

  const createToken = async (values) => {
    if (!address) {
      setAddress({
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        address_city: values.address_city,
        address_state: values.address_state,
        address_zip: values.address_zip,
        address_country: values.address_country,
      })
      await firebase.addOrgBillingAddress({
        orgId: org.uid,
        billingAddress: {
          address_line1: values.address_line1,
          address_line2: values.address_line2,
          address_city: values.address_city,
          address_state: values.address_state,
          address_zip: values.address_zip,
          address_country: values.address_country,
        },
      })
    }

    const expiry = values.expiryDate.split("/")
    let userId = stripeUserId

    if (!userId) {
      const currentOrg = await firebase.org(org.uid).get()
      userId = currentOrg.data().stripeUserId
    }

    const createPayload = {
      client: {
        uid: org.uid,
        email: org.email,
        userId,
      },
      card: {
        number: replace(values.cardNumber, / /g, ""),
        cvc: values.cvc,
        name: values.name,
        exp_month: expiry[0].trim(),
        exp_year: `20${expiry[1].trim()}`,
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        address_city: values.address_city,
        address_state: values.address_state,
        address_zip: values.address_zip,
        address_country: values.address_country,
      },
      isOrg: true,
    }

    const token = await firebase.getIdToken()
    const card = await fetch(tokenCreateUrl, {
      method: "POST",
      body: JSON.stringify(createPayload),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    const parsedCard = await card.json()
    if (card.ok || (parsedCard && parsedCard.status === "created")) {
      if (org && org.type === "business" && org.toActivate) {
        await businessCharge()
      }
      setLoading(false)
      addToast(t("saved_successfully"), {
        appearance: "success",
        placement: "bottom-center",
      })
      setIsModalOpen(false)
    } else {
      setLoading(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  return (
    <Container>
      <PaymentsHeader>
        <div>
          <img alt="card icon" src={creditCard} />
        </div>
        <div>
          <Title m="8px">{t("create_credit_card")}</Title>
          <p style={{ marginLeft: 8, marginTop: 0 }}>
            {t("add_payment_methods")}
          </p>
        </div>
        <AddButton
          onClick={async () => {
            if (org && org.type === "business" && org.toActivate) {
              // Check if there is at least one doctor
              const orgDoctors = doctors.filter((d) => d.active)
              if (orgDoctors?.length === 0) {
                setIsDoctorCheckModalOpen(true)
              } else {
                setIsModalOpen(!isModalOpen)
              }
            } else {
              setIsModalOpen(!isModalOpen)
            }
          }}
        >
          <span>+</span>
        </AddButton>
      </PaymentsHeader>
      <InfoWrapper isDeleting={deleting}>
        {tokens &&
          Object.keys(tokens).map((tt) => (
            <CreditCard
              key={tt}
              card={tokens[tt]}
              onCardClick={() => {
                setDeleting(true)
                setPayload({
                  card: {
                    id: tokens[tt].token,
                    key: tt,
                  },
                  client: {
                    uid: org.uid,
                    email: org.email,
                    userId: stripeUserId,
                  },
                  isOrg: true,
                })
              }}
              onPaymentCardClick={async () => {
                setDeleting(true)
                const result = await businessCharge(tokens[tt].token)
                setDeleting(false)
                const appearance = result ? "success" : "error"
                const text = result
                  ? t("saved_successfully")
                  : t("unexpected_error")
                addToast(text, {
                  appearance,
                  placement: "bottom-center",
                })
              }}
              showPayOption={
                org &&
                org.type === "business" &&
                doctors.filter(
                  (d) => !d.active && d.subscriptionPaymentRetry === 6
                ).length > 0
              }
            />
          ))}
      </InfoWrapper>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions w="900px" h={visibleAddAddress ? "100%" : "500px"}>
              <section>
                <Title m="8px">{t("add_card")}</Title>
                <ModalContainer>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      cvc: undefined,
                      expiryDate: undefined,
                      focus: undefined,
                      name: undefined,
                      cardNumber: undefined,
                      address_line1: address ? address.address_line1 : "",
                      address_line2: address ? address.address_line2 : "",
                      address_city: address ? address.address_city : "",
                      address_state: address ? address.address_state : "",
                      address_zip: address ? address.address_zip : "",
                      address_country: address ? address.address_country : "",
                    }}
                    onSubmit={async (values) => {
                      setLoading(true)
                      try {
                        await createToken(values)
                      } catch (error) {
                        setLoading(false)
                        addToast(t("unexpected_error"), {
                          appearance: "error",
                        })
                      }
                    }}
                    validationSchema={Yup.object({
                      cvc: Yup.string()
                        .min(3)
                        .max(4)
                        .required(t("error_all_fields_required")),
                      expiryDate: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      focus: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      name: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      cardNumber: Yup.string()
                        .max(19)
                        .min(15)
                        .required(t("error_all_fields_required")),
                      address_country: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                      address_line1: Yup.string().required(
                        t("error_all_fields_required")
                      ),
                    })}
                  >
                    {(props) => (
                      <StyledForm onSubmit={props.handleSubmit}>
                        <section>
                          <div>
                            <Cards
                              cvc={props.values.cvc || ""}
                              expiry={
                                props.values.expiryDate
                                  ? props.values.expiryDate.replace("/", "")
                                  : ""
                              }
                              focused={props.values.focus || ""}
                              name={props.values.name || ""}
                              number={props.values.cardNumber || ""}
                            />
                          </div>
                          <div>
                            <Field name="name">
                              {({ field, form, meta }) => (
                                <InputWrapper>
                                  <CardInput
                                    type="text"
                                    placeholder="Nombre Titular"
                                    onFocus={() =>
                                      form.setFieldValue("focus", "name")
                                    }
                                    {...field}
                                  />
                                  {meta.touched && meta.error && (
                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                  )}
                                </InputWrapper>
                              )}
                            </Field>
                            <Field name="cardNumber">
                              {({ field, meta }) => (
                                <InputWrapper>
                                  <CardInput
                                    {...getCardNumberProps({
                                      placeholder: t("card_number_label"),
                                      onBlur: field.onBlur,
                                      onChange: field.onChange,
                                      onFocus: () =>
                                        props.setFieldValue("focus", "number"),
                                    })}
                                  />
                                  {meta.touched && meta.error && (
                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                  )}
                                </InputWrapper>
                              )}
                            </Field>
                            <DoubleInputWrapper>
                              <Field name="expiryDate">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      {...getExpiryDateProps({
                                        placeholder: t("expiration_card_label"),
                                        onBlur: field.onBlur,
                                        onChange: field.onChange,
                                        onFocus: () =>
                                          props.setFieldValue(
                                            "focus",
                                            "expiry"
                                          ),
                                      })}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <Field name="cvc">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      {...getCVCProps({
                                        placeholder: "CVC",
                                        onBlur: field.onBlur,
                                        onChange: field.onChange,
                                        onFocus: () =>
                                          props.setFieldValue("focus", "cvc"),
                                      })}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                            </DoubleInputWrapper>
                          </div>
                        </section>
                        {address && (
                          <BillingAddres>
                            <BillingTitle>
                              {props.values.address_line1}
                            </BillingTitle>
                            <BillingText>
                              {`${props.values.address_city}, ${get(props, [
                                "values",
                                "address_state",
                              ])}`}
                            </BillingText>
                            <BillingText>
                              {`${props.values.address_country}, ${get(props, [
                                "values",
                                "address_zip",
                              ])}`}
                            </BillingText>
                          </BillingAddres>
                        )}
                        {!address && (
                          <>
                            <BillingSection>
                              <p>{t("add_billing_address")}</p>
                              <AddButton
                                width="32px"
                                height="32px"
                                onClick={() =>
                                  setVisibleAddAddress(!visibleAddAddress)
                                }
                              >
                                <span>+</span>
                              </AddButton>
                            </BillingSection>
                            <BillingContainer isVisible={visibleAddAddress}>
                              <Field name="address_line1">
                                {({ field, form, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      type="text"
                                      placeholder="Street address #1"
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <Field name="address_line2">
                                {({ field, meta }) => (
                                  <InputWrapper>
                                    <CardInput
                                      type="text"
                                      placeholder="Street address #2"
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <ErrorMessage>{meta.error}</ErrorMessage>
                                    )}
                                  </InputWrapper>
                                )}
                              </Field>
                              <DoubleInputWrapper>
                                <Field name="address_city">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="City"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                                <Field name="address_state">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="State / Province / Region"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                              </DoubleInputWrapper>
                              <DoubleInputWrapper>
                                <Field name="address_country">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="Country"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                                <Field name="address_zip">
                                  {({ field, meta }) => (
                                    <InputWrapper>
                                      <CardInput
                                        type="text"
                                        placeholder="Zip Code"
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <ErrorMessage>
                                          {meta.error}
                                        </ErrorMessage>
                                      )}
                                    </InputWrapper>
                                  )}
                                </Field>
                              </DoubleInputWrapper>
                            </BillingContainer>
                          </>
                        )}
                        <ButtonsContainer
                          isForm
                          mt="10px"
                          isDisabled={
                            !props.dirty || props.isSubmitting || !props.isValid
                          }
                        >
                          <button
                            type="button"
                            onClick={() => {
                              if (!address) {
                                setAddress(null)
                              }
                              setVisibleAddAddress(false)
                              setIsModalOpen(false)
                            }}
                          >
                            {t("cancel")}
                          </button>
                          <SubmitButton
                            small
                            isLoading={loading}
                            type="submit"
                            disabled={
                              !props.dirty ||
                              props.isSubmitting ||
                              !props.isValid
                            }
                          >
                            {t("create")}
                          </SubmitButton>
                        </ButtonsContainer>
                      </StyledForm>
                    )}
                  </Formik>
                </ModalContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {isDoctorCheckModalOpen && (
        <CustomModal
          modalOpen={isDoctorCheckModalOpen}
          setModalOpen={setIsDoctorCheckModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <Title>{t("doctor_verify_title")}</Title>
                <Description>{t("doctor_verify")}</Description>
                <ButtonsContainer>
                  <button
                    type="button"
                    onClick={() => setIsDoctorCheckModalOpen(false)}
                  >
                    {t("accept")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </Container>
  )
}

OrgPayments.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    uid: PropTypes.string,
    stripeUserId: PropTypes.string,
  }).isRequired,
  orgId: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(OrgPayments)
