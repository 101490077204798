import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Search } from "@styled-icons/material/Search"

const SearchInput = ({ value, onChange, placeholder }) => (
  <InputWrapper>
    <Search size="20" />
    <input
      name="search"
      value={value}
      onChange={onChange}
      id="search"
      placeholder={placeholder}
    />
  </InputWrapper>
)

const InputWrapper = styled.div`
  width: 229px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  margin: 0 8px;
  align-items: center;
  & > input {
    border: none;
    outline: none;
    &::placeholder {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`

SearchInput.defaultProps = {
  value: "",
  placeholder: "Search",
}
SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default SearchInput
