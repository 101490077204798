import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    resources: {
      es: {
        // eslint-disable-next-line global-require
        translations: require("./locales/es/translation.json"),
      },
      en: {
        // eslint-disable-next-line global-require
        translations: require("./locales/en/translation.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    lng: "es",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
    },
  })

export default i18n

// https://www.robinwieruch.de/react-internationalization
