import { customAlphabet } from "nanoid"
import Payment from "payment"

const nanoid = customAlphabet("1234567890ABCDEF", 8)

/* eslint-disable import/prefer-default-export */
export const generatePassword = () => {
  const length = 8
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  let retVal = ""
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export const getReferenceCode = () => {
  const uuid = nanoid()
  return uuid
}

export const createAdminUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
export const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
export const customerCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeCustomersCreate`
export const tokenCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeTokensCreate`
export const tokenDeleteUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeTokensDelete`
export const paymentsGenerateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsReportsGenerate`
export const paymentsGenerateLocalUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsReportsGenerateLocal`
export const payoutsCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/payoutsCreate`
export const payoutsGetUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/payoutsGet`
export const payoutsUpdateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/payoutsUpdate`
export const payoutsOrgsGenerateLocal = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/admin-payouts-https-getPortal`
export const updateAllProvidersCharges = `${process.env.REACT_APP_FUNCTIONS_URL}/providers-https-updateAllProviders`
export const paymentChargeUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeChargesPaymentCharge`
export const getQrCodeUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/organizations-https-getQrCode`

// http://127.0.0.1:5001/docv3-develop/us-central1/admin-payouts-https-getPortal

export const countries = [
  // { value: "BO", label: "Bolivia", valid: true },
  // { value: "CL", label: "Chile", valid: true },
  { value: "CR", label: "Costa Rica", valid: true },
  // { value: "EC", label: "Ecuador", valid: true },
  { value: "SV", label: "El Salvador", valid: true },
  { value: "GT", label: "Guatemala", valid: true },
  { value: "HN", label: "Honduras", valid: true },
  { value: "MX", label: "México", valid: true },
  { value: "NI", label: "Nicaragua", valid: true },
  // { value: "CO", label: "Colombia", valid: true },
  { value: "PA", label: "Panamá", valid: true },
  { value: "PE", label: "Perú", valid: true },
  { value: "DO", label: "República Dominicana", valid: true },
  { value: "VE", label: "Venezuela", valid: true },
]

export const getCountryGenericValue = (posibleCountryValue) => {
  if (typeof posibleCountryValue === "string") {
    const parsedValue = posibleCountryValue.toLowerCase().replace(" ", "")
    const parsedCountries = countries.map((country) => ({
      ...country,
      value: country?.value?.toLowerCase().replace(" ", ""),
      label: country?.label?.toLowerCase().replace(" ", ""),
    }))

    const selectedCountryIndex = parsedCountries.findIndex((c) => {
      if (c.label === parsedValue) return c
      if (c.value === parsedValue) return c
      return false
    })

    return selectedCountryIndex === -1
      ? countries[2]
      : countries[selectedCountryIndex]
  }

  const isCountryCode = countries.find((c) => c.value === posibleCountryValue)

  return isCountryCode
}

export const getInitials = (name) => {
  const formattedName = name ? name.replace(/\W+/gm, " ") : ""
  const [firstWord, lastWord] = formattedName.split(" ")
  if (firstWord && lastWord) {
    return `${firstWord.charAt(0).toUpperCase()}${lastWord
      .charAt(0)
      .toUpperCase()}`
  }
  return firstWord.substring(0, 2).toUpperCase()
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "")
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`
      break
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
    maxLength = issuer === "amex" ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export const accountsType = [
  {
    value: "checking_account",
    label: "web_client.checking_account",
  },
  {
    value: "savings_account",
    label: "web_client.savings_account",
  },
]

export const accountsCurrencyType = [
  {
    value: "local",
    label: "local_currency_account",
    i18n: true,
  },
  {
    value: "dollar",
    label: "dollar_currency_account",
    i18n: true,
  },
]
