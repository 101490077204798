/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import get from "lodash.get"
import { withFirebase } from "../../../../../../utils/Firebase"

const IdCard = ({ firebase, provider, providerId }) => {
  const [id, setId] = useState("obteniendo id...")

  useEffect(() => {
    const getId = async () => {
      const profile = await firebase.user(provider).get()
      const data = await profile.data()
      setId(get(data, "idCard", "N/A"))
    }
    if (firebase && provider) {
      getId()
    }
  }, [firebase, provider, providerId])

  return <span>{id}</span>
}

export default withFirebase(IdCard)
