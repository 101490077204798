/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory, useLocation } from "react-router-dom"
import { Formik, Field } from "formik"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import * as Yup from "yup"
import { Add } from "@styled-icons/material/Add"
import BackIcon from "../../components/atoms/BackIcon"
import ActionButton from "../../components/atoms/ActionButton"
import ProductImage from "../../components/molecules/ProductImage"
import CustomInput from "../../components/atoms/CustomInput"
import TextCard from "../../components/atoms/TextCard"
import { countries } from "../../utils/App"
import { withFirebase } from "../../utils/Firebase"
import Theme from "../../theme"

const NewProduct = ({ firebase }) => {
  const history = useHistory()
  const formRef = useRef()
  const { state } = useLocation()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false)

  const handleBack = () => {
    history.push(`/organization/${state.currentOrg.uid}`, {
      activeTab: 2,
      currentOrg: state.currentOrg,
    })
  }

  // eslint-disable-next-line no-unused-vars
  const onAddClick = async () => {
    try {
      if (formRef.current.isValid) {
        const uploadDir = `organizations/${state.currentOrg.uid}/products/${formRef.current.values.mainPhotoURL.name}`
        const snapshot = await firebase.uploadToStorage(
          uploadDir,
          formRef.current.values.mainPhotoURL.blob,
          {
            orgId: state.currentOrg.uid,
            storagePath: uploadDir,
          }
        )
        const imageURL = await snapshot.ref.getDownloadURL()
        await firebase.addOrgProduct({
          orgId: state.currentOrg.uid,
          productData: { ...formRef.current.values, mainPhotoURL: imageURL },
        })
        addToast(t("saved_successfully"), {
          appearance: "success",
          placement: "bottom-center",
        })
        setShowError(false)
        formRef.current.resetForm()
      } else {
        formRef.current.validateForm()
        setShowError(true)
        addToast(t("error_all_fields_required"), {
          appearance: "error",
          placement: "bottom-center",
        })
      }
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
    }
  }

  const customStyles = {
    control: (provided, stateSt) => ({
      ...provided,
      width: stateSt.selectProps.width,
      borderRadius: stateSt.selectProps.borderRadius,
      borderColor: stateSt.selectProps.showError
        ? Theme.color.error
        : Theme.color.iceBlue,
    }),
    singleValue: (provided, stateSt) => {
      const opacity = stateSt.isDisabled ? 0.5 : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },
  }

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon isBack={false} onBack={handleBack} /> {t("orgs_products")}
        </Back>
        <ActionButton onActionClick={onAddClick} dark>
          <FilterContainer>
            <Add size={18} />
            <span>{t("save_product")}</span>
          </FilterContainer>
        </ActionButton>
      </Nav>
      <Container>
        <Formik
          validateOnMount
          innerRef={formRef}
          initialValues={{
            sku: "",
            mainPhotoURL: null,
            country: "CR",
            labName: "",
            cie11: "",
            pharmaceuticalPresentation: "",
            composition: "",
            therapeuticIndications: "",
            pharmacologicalAction: "",
            excipients: "",
            dose: "",
            contraindications: "",
            drugInteractions: "",
            warnings: "",
            precautions: "",
            overDoseIndications: "",
            storageIndications: "",
            expirationIndications: "",
            presentations: "",
            otc: false,
            prescriptionRestricted: false,
            severalRestricted: false,
            reactions: "",
            restrictions: "",
          }}
          validationSchema={Yup.object({
            sku: Yup.string().required(t("error_field_required")),
            country: Yup.string().required(t("error_field_required")),
            labName: Yup.string().required(t("error_field_required")),
          })}
        >
          {(props) => (
            <>
              <ImagesContainer>
                <ProductImage
                  text={`${t("photo_label")} *`}
                  src={props.values.mainPhotoURL}
                  onImageLoaded={props.setFieldValue}
                />
              </ImagesContainer>
              <InfoWrapper>
                <StyledForm>
                  <TextCard label={`${t("country_label")} *`}>
                    <Select
                      styles={customStyles}
                      defaultValue={countries.find(
                        (c) => c.value === props.values.country
                      )}
                      label={t("country_label")}
                      options={countries}
                      onChange={(option) =>
                        props.setFieldValue("country", option.value)
                      }
                      placeholder={t("country_label")}
                      width="100%"
                      borderRadius="25px"
                      showError={showError && !props.values.country}
                    />
                  </TextCard>
                  <TextCard label={`${t("sku_label")} *`}>
                    <CustomInput
                      touched={props.touched.sku}
                      errors={props.errors.sku}
                      value={props.values.sku}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="sku"
                      name="sku"
                      showError={showError && !props.values.sku}
                    />
                  </TextCard>
                  <TextCard label={`${t("lab_name")} *`}>
                    <CustomInput
                      touched={props.touched.labName}
                      errors={props.errors.labName}
                      value={props.values.labName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="labName"
                      name="labName"
                      showError={showError && !props.values.labName}
                    />
                  </TextCard>
                  <TextCard label={t("several_restricted_label")}>
                    <div>
                      <Field type="checkbox" name="severalRestricted" />{" "}
                      Requiere restriccción
                    </div>
                  </TextCard>
                  <TextCard label={t("prescription_restricted_label")}>
                    <div>
                      <Field type="checkbox" name="prescriptionRestricted" />{" "}
                      Requiere restriccción
                    </div>
                  </TextCard>
                  <TextCard label={t("cie11_label")}>
                    <CustomInput
                      touched={props.touched.cie11}
                      errors={props.errors.cie11}
                      value={props.values.cie11}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="cie11"
                      name="cie11"
                    />
                  </TextCard>
                  <TextCard label={t("pharmaceutical_presentation_label")}>
                    <CustomInput
                      touched={props.touched.pharmaceuticalPresentation}
                      errors={props.errors.pharmaceuticalPresentation}
                      value={props.values.pharmaceuticalPresentation}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="pharmaceuticalPresentation"
                      name="pharmaceuticalPresentation"
                    />
                  </TextCard>
                  <TextCard label={t("composition_label")}>
                    <TextArea
                      touched={props.touched.composition}
                      errors={props.errors.composition}
                      value={props.values.composition}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="composition"
                      name="composition"
                    />
                  </TextCard>
                  <TextCard label={t("therapeutic_label")}>
                    <TextArea
                      touched={props.touched.therapeuticIndications}
                      errors={props.errors.therapeuticIndications}
                      value={props.values.therapeuticIndications}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="therapeuticIndications"
                      name="therapeuticIndications"
                    />
                  </TextCard>
                  <TextCard label={t("pharmacological_action_label")}>
                    <TextArea
                      touched={props.touched.pharmacologicalAction}
                      errors={props.errors.pharmacologicalAction}
                      value={props.values.pharmacologicalAction}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="pharmacologicalAction"
                      name="pharmacologicalAction"
                    />
                  </TextCard>
                  <TextCard label={t("excipients_label")}>
                    <TextArea
                      touched={props.touched.excipients}
                      errors={props.errors.excipients}
                      value={props.values.excipients}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="excipients"
                      name="excipients"
                    />
                  </TextCard>
                  <TextCard label={t("dose_label")}>
                    <TextArea
                      touched={props.touched.dose}
                      errors={props.errors.dose}
                      value={props.values.dose}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="dose"
                      name="dose"
                    />
                  </TextCard>
                  <TextCard label={t("contraindications_label")}>
                    <TextArea
                      touched={props.touched.contraindications}
                      errors={props.errors.contraindications}
                      value={props.values.contraindications}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="contraindications"
                      name="contraindications"
                    />
                  </TextCard>
                  <TextCard label={t("drug_interactions_label")}>
                    <TextArea
                      touched={props.touched.drugInteractions}
                      errors={props.errors.drugInteractions}
                      value={props.values.drugInteractions}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="drugInteractions"
                      name="drugInteractions"
                    />
                  </TextCard>
                  <TextCard label={t("warnings_label")}>
                    <TextArea
                      touched={props.touched.warnings}
                      errors={props.errors.warnings}
                      value={props.values.warnings}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="warnings"
                      name="warnings"
                    />
                  </TextCard>
                  <TextCard label={t("precautions_label")}>
                    <TextArea
                      touched={props.touched.precautions}
                      errors={props.errors.precautions}
                      value={props.values.precautions}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="precautions"
                      name="precautions"
                    />
                  </TextCard>
                  <TextCard label={t("overdose_label")}>
                    <TextArea
                      touched={props.touched.overDoseIndications}
                      errors={props.errors.overDoseIndications}
                      value={props.values.overDoseIndications}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="overDoseIndications"
                      name="overDoseIndications"
                    />
                  </TextCard>
                  <TextCard label={t("storage_label")}>
                    <TextArea
                      touched={props.touched.storageIndications}
                      errors={props.errors.storageIndications}
                      value={props.values.storageIndications}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      id="storageIndications"
                      name="storageIndications"
                    />
                  </TextCard>
                  <TextCard label={t("expiration_label")}>
                    <CustomInput
                      touched={props.touched.expirationIndications}
                      errors={props.errors.expirationIndications}
                      value={props.values.expirationIndications}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="expirationIndications"
                      name="expirationIndications"
                    />
                  </TextCard>
                  <TextCard label={t("presentation_label")}>
                    <CustomInput
                      touched={props.touched.presentations}
                      errors={props.errors.presentations}
                      value={props.values.presentations}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="presentations"
                      name="presentations"
                    />
                  </TextCard>
                  <TextCard label={t("otc_label")}>
                    <div>
                      <Field type="checkbox" name="otc" /> OTC
                    </div>
                  </TextCard>
                </StyledForm>
              </InfoWrapper>
            </>
          )}
        </Formik>
      </Container>
    </div>
  )
}

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 225px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 220px);
  overflow-y: auto;
  padding-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 85px);
  overflow: hidden;
`

const FilterContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledForm = styled.form`
  margin: 20px;
  & ~ input {
    width: 300px;
  }
  & > div {
    margin-bottom: 10px;
    & > div {
      min-width: 300px;
    }
  }
`

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  display: block;
  resize: none;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 10px;
  margin-bottom: 15px;
  background: ${(props) =>
    props.disabled
      ? props.theme.color.background.gray
      : props.theme.color.background.gray30};
  color: ${(props) =>
    props.disabled ? "#999" : props.theme.color.background.black80};
  min-height: 80px;
  width: 100%;
  border: none;
  border-radius: 4px;
`

NewProduct.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(NewProduct)
