/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from "react"
import get from "lodash.get"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { PaymentContainer, Title, List, FilterContainer } from "./styles"

// Components
import ActionButton from "../../../../components/atoms/ActionButton"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
} from "../../../../components/atoms/Table"
// Utils
import { withFirebase } from "../../../../utils/Firebase"
import * as ROUTES from "../../../../utils/Constants/Routes"
import { AuthUserContext } from "../../../../context/Session"
import { payoutsGetUrl, payoutsUpdateUrl } from "../../../../utils/App"

const Administration = ({ firebase }) => {
  const [loading, setLoading] = useState(false)
  const [payouts, setPayouts] = useState([])
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)

  const getPayouts = useCallback(async () => {
    try {
      setLoading(true)
      const token = await firebase.getIdToken()
      const data = await fetch(
        `${payoutsGetUrl}${authUser.roles.SUPER_ADMIN ? "" : "?country=CR"}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const payoutsResponse = await data.json()
      if (payoutsResponse) {
        setPayouts(payoutsResponse.payouts)
        setLoading(false)
      }
    } catch {
      setLoading(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }, [addToast, firebase, t])

  useEffect(() => {
    if (payoutsGetUrl) {
      getPayouts()
    }
  }, [getPayouts])

  const getStatus = (status) => {
    switch (status) {
      case "created":
        return {
          label: "Creado",
          next: "pending",
        }
      case "pending":
        return {
          label: "Pendiente",
          next: "in-progress",
        }
      case "in-progress":
        return {
          label: "En Proceso",
          next: "review",
        }
      case "review":
        return {
          label: "En Revisión",
          next: "paid",
        }
      case "paid":
        return {
          label: "Pagado",
        }
      case "cancelled":
        return {
          label: "Cancelado",
        }
      default:
        return {
          label: "Listo",
        }
    }
  }

  const onAddClick = async (payoutId, status) => {
    try {
      const token = await firebase.getIdToken()
      const update = await fetch(payoutsUpdateUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payoutId,
          info: {
            status,
          },
        }),
      })
      if (update.ok) {
        getPayouts()
      }
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }
  return (
    <PaymentContainer>
      <Title>{t("payments_administration_description")}</Title>
      <List>
        <Table>
          <TableHeader>
            <TableHeaderItem>Generado por</TableHeaderItem>
            <TableHeaderItem>{t("start_date")}</TableHeaderItem>
            <TableHeaderItem>{t("end_date")}</TableHeaderItem>
            <TableHeaderItem>Total General</TableHeaderItem>
            <TableHeaderItem>Total Comisiones</TableHeaderItem>
            <TableHeaderItem>Total Deducciones</TableHeaderItem>
            <TableHeaderItem>Total Pagar</TableHeaderItem>
            <TableHeaderItem>Estado</TableHeaderItem>
            {authUser.roles.SUPER_ADMIN && (
              <TableHeaderItem>Acción</TableHeaderItem>
            )}
          </TableHeader>
          {!loading && payouts.length > 0 ? (
            payouts.map((ts) => {
              const localStatus = getStatus(ts.status)
              return (
                <TableRow key={`payout-${ts.id}`}>
                  <TableRowItem>
                    <Link
                      to={{
                        state: {
                          payout: ts,
                          summary: get(ts, "summary", []),
                          transactions: get(ts, "transactions", []),
                          bankSummary: get(ts, "bankSummary", []),
                          isSuperAdmin: authUser.roles.SUPER_ADMIN,
                        },
                        pathname: ROUTES.PAYOUT_DETAIL.replace(":id", ts.id),
                      }}
                    >
                      {ts.createdBy}
                    </Link>
                  </TableRowItem>
                  <TableRowItem>
                    {moment.unix(ts.rangeStart).format("DD/MM/YYYY")}
                  </TableRowItem>
                  <TableRowItem>
                    {moment.unix(ts.rangeEnd).format("DD/MM/YYYY")}
                  </TableRowItem>
                  <TableRowItem>{`${parseFloat(ts.total).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(ts.totalFees).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(ts.totalDonations).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(ts.payoutTotal).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{localStatus.label}</TableRowItem>
                  {authUser.roles.SUPER_ADMIN && (
                    <TableRowItem>
                      <ActionButton
                        disabled={ts.status === "paid"}
                        onActionClick={() =>
                          onAddClick(ts.id, localStatus.next)
                        }
                        dark
                      >
                        <FilterContainer>
                          <span>{getStatus(localStatus.next).label}</span>
                        </FilterContainer>
                      </ActionButton>
                    </TableRowItem>
                  )}
                </TableRow>
              )
            })
          ) : (
            <EmptyOrLoadingContainer>
              {loading ? t("loading") : "No results"}
            </EmptyOrLoadingContainer>
          )}
        </Table>
      </List>
    </PaymentContainer>
  )
}

Administration.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Administration)
