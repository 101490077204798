import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import Nav from "../../atoms/Nav"
import CardsContainer from "../../atoms/CardsContainer"
import OrgProductCard from "../../molecules/OrgProductCard"
import * as ROUTES from "../../../utils/Constants/Routes"

const OrgProducts = ({ products, orgId }) => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState("")
  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleAddOrganization = () => {
    history.push(ROUTES.ADD_PRODUCT, {
      currentOrg: orgId,
    })
  }

  const filterDatasetByTextValue = (dataset, textValue) => {
    const safeValue = textValue.toLowerCase()
    if (textValue) {
      return (
        (dataset.sku && dataset.sku.toLowerCase().includes(safeValue)) ||
        (dataset.cie11 && dataset.cie11.toLowerCase().includes(safeValue)) ||
        (dataset.composition &&
          dataset.composition.toLowerCase().includes(safeValue))
      )
    }
    return true
  }

  return (
    <>
      <Nav
        titleKey="orgs_products"
        onSearchChange={handleSearchChange}
        onAddClick={handleAddOrganization}
        searchValue={searchValue}
        addText="create_new"
      />
      <Container>
        <CardsContainer
          isEmpty={products.length === 0}
          emptyMessage="Aun no tienes productos dentro de la organización"
          fullHeight
          withCardPadding={false}
        >
          {products
            .filter((u) => filterDatasetByTextValue(u.data(), searchValue))
            .map((product) => {
              const prodData = { ...product.data(), id: product.id }
              return (
                <OrgProductCard
                  key={product.id}
                  product={prodData}
                  orgId={orgId}
                />
              )
            })}
        </CardsContainer>
      </Container>
    </>
  )
}

export const Container = styled.div`
  height: calc(100% - 150px);
`

OrgProducts.defaultProps = {
  products: [],
}

OrgProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  orgId: PropTypes.shape().isRequired,
}

export default OrgProducts
