import React from "react"
import styled, { keyframes } from "styled-components"
import { useTranslation } from "react-i18next"

const dots = keyframes`
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow: .25em 0 0 black, .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow: .25em 0 0 black, .5em 0 0 black;
  }
`

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.p`
  font-size: 4rem;
  width: 100%;
  text-align: center;
  &::after {
    content: " .";
    animation: ${dots} 1s steps(5, end) infinite;
  }
`

export default function Loading() {
  const { t } = useTranslation()
  return (
    <LoadingContainer>
      <LoadingText>{t("loading")}</LoadingText>
    </LoadingContainer>
  )
}
