import styled, { css } from "styled-components"
import { rem } from "polished"
import { ActionButtonLink } from "../../../../components/atoms/Table"

export const PaymentContainer = styled.div`
  margin: 20px 20px 0 20px;
  height: calc(100vh - 165px);
  overflow: hidden;
`

export const Header = styled.section`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100vw - 71px);
`

export const Title = styled.div`
  height: 40px;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: -0.5px;
  margin: 2px 0 10px 10px;
`

export const List = styled.section`
  height: 100%;
  max-height: calc(100vh - 245px);
  margin: 0 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
`

export const FilterContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CustomTable = styled.table`
  min-width: calc(100vw - 71px);
  border-collapse: collapse;
`

export const CustomTableHead = styled.thead(
  ({ theme, minWidth }) => css`
    padding: ${rem(2)} ${rem(6)};
    align-content: center;
    border: ${rem(1)} solid ${theme.color.border.default};
    border-left: 0px;
    border-right: 0px;
    user-select: none;
    font-size: ${rem(14)};
    min-width: ${minWidth || "unset"};
  `
)

export const CustomTableBody = styled.tbody``

export const CustomTableRow = styled.tr(
  ({ theme }) => css`
    background-color: ${theme.color.bg.light};
    border-bottom: ${rem(1)} solid ${theme.color.border.default};
  `
)

export const CustomTableHeader = styled.th(
  ({ minWidth }) => css`
    text-align: left;
    padding: ${rem(16)};
    min-width: ${minWidth || "100px"};
  `
)

export const CustomTableCell = styled.td`
  text-align: left;
  padding: ${rem(16)};
  ${ActionButtonLink} {
    text-decoration: none;
  }

  > a {
    text-decoration: none;
    cursor: pointer;
    width: auto;
  }
`
