const referenceTypes = {
  es: {
    noReference: "Sin Referencia",
    pharmaceutical: "Farmacia",
    pharmaceutical_company: "Compañía Farmacéutica",
    medical_imaging: "Imágenes Médicas",
    clinical_labs: "Laboratorios Clínicos",
    workMate: "Colega",
    social: "Redes Sociales",
    email: "Correo electrónico",
  },
  en: {
    noReference: "No Reference",
    pharmaceutical: "Pharmaceutical",
    pharmaceutical_company: "Pharmaceutical Company",
    medical_imaging: "Medical Imaging",
    clinical_labs: "Clinical Laboratories",
    workMate: "Workmate",
    social: "Social media",
    email: "Email",
  },
}

export default referenceTypes
