/* eslint-disable no-unused-vars */
import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { AuthUserContext } from "../../../context/Session"
import Sidebar from "../../atoms/Sidebar"

function Layout(props) {
  const { children, history, ...otherProps } = props
  const authUser = useContext(AuthUserContext)
  const {
    location: { pathname },
  } = history
  const allowedUser = authUser && pathname !== "/signin"

  return (
    <LayoutContainer>
      {allowedUser && <Sidebar history={history} />}
      <MainContainer>{children}</MainContainer>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.shape().isRequired,
}

const LayoutContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

const MainContainer = styled.section`
  width: calc(100% - 71px);
  height: 100vh;

  & > div:nth-child(2) {
    height: calc(100vh - 82px);
    position: relative;
  }
`

export default Layout
