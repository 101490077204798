import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StatsCard = ({ number, label, handleClick }) => {
  return (
    <Container onClick={handleClick}>
      <Number>{number}</Number>
      <Label>{label}</Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  margin: 0 10px;
  cursor: pointer;
`

const Number = styled.span`
  font-size: 2.5rem;
  line-height: 3.1875rem;
  text-align: center;
  color: ${(props) => props.theme.color.onSurface};
`

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.color.background.black60};
`

StatsCard.defaultProps = {
  handleClick: null,
}

StatsCard.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export default StatsCard
