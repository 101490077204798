/* eslint-disable global-require */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Delete } from "@styled-icons/material/Delete"
import { Payment } from "@styled-icons/material/Payment"

const CreditCard = ({
  card,
  onCardClick,
  onPaymentCardClick,
  showPayOption,
}) => {
  let logo = null
  // Card brand. Can be American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
  switch (card.cardBrand) {
    case "MasterCard":
      logo = require("./images/mastercard.png")
      break
    case "Visa":
      logo = require("./images/visa.png")
      break
    case "American Express":
      logo = require("./images/american-express.png")
      break
    case "Diners Club":
      logo = require("./images/diners-club.png")
      break
    case "Discover":
      logo = require("./images/discover.png")
      break
    case "JCB":
      logo = require("./images/jcb.png")
      break
    default:
      logo = require("./images/generic-card.png")
      break
  }
  return (
    <CardContainer>
      <Header>
        <img alt={card.cardBrand} src={logo.default} />
        <span>{`**** ${card.cardLast4}`}</span>
        <Icon onClick={onCardClick}>
          <Delete size="24" />
        </Icon>

        {onPaymentCardClick && showPayOption && (
          <IconPayment onClick={onPaymentCardClick}>
            <Payment size="24" />
          </IconPayment>
        )}
      </Header>
      <CardHolder>{card.cardHolder}</CardHolder>
      <CardExpiration>{card.cardExpiration}</CardExpiration>
    </CardContainer>
  )
}

CreditCard.defaultProps = {
  onPaymentCardClick: null,
  showPayOption: false,
}

CreditCard.propTypes = {
  card: PropTypes.shape({
    cardBrand: PropTypes.string,
    cardExpiration: PropTypes.string,
    cardLast4: PropTypes.string,
    cardHolder: PropTypes.string,
  }).isRequired,
  onCardClick: PropTypes.func.isRequired,
  onPaymentCardClick: PropTypes.func,
  showPayOption: PropTypes.bool,
}

const CardContainer = styled.div`
  min-width: 265px;
  padding: 22px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 22px;
`

const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  & > span {
    margin-left: 10px;
  }
`

const CardHolder = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  margin: 4px 0px;
`

const CardExpiration = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin: 4px 0px;
`

const IconPayment = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`

const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 5px;
  cursor: pointer;
  &:hover {
    color: red;
  }
`

export default CreditCard
