import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import get from "lodash.get"
import { Security } from "@styled-icons/material/Security"
import { Block } from "@styled-icons/material/Block"
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt"
import { useToasts } from "react-toast-notifications"
import BackIcon from "../../components/atoms/BackIcon"
import CustomModal from "../../components/atoms/CustomModal"
import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  Description,
} from "../../components/atoms/ModalElements"
import StatsCard from "../../components/atoms/StatsCard"
import Tab from "../../components/atoms/Tab"
import PersonalInfo from "../../components/organisms/PersonalInfo"
import Sessions from "../../components/organisms/Sessions"
import DocCharges from "../../components/organisms/DocCharges"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"

const UsersDetail = ({ firebase }) => {
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
  const deleteUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
  const { state } = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [profileForm, setProfileForm] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [currentUser, setCurrentUser] = useState(get(state, ["currentUser"]))
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isRemovingUser, setIsRemovingUser] = useState(false)

  useEffect(() => {
    async function getUserData() {
      if (currentUser.userType === "admin") {
        const profile = await firebase.user(currentUser.email).get()
        setProfileForm({
          ...profile.data(),
        })
      } else {
        const profile = await firebase.user(currentUser.email).get()
        const form = await firebase.getUserForm(currentUser.email, "academic")
        const formBusiness = await firebase.getUserForm(
          currentUser.email,
          "business"
        )
        const formPricing = await firebase.getUserForm(
          currentUser.email,
          "fees"
        )
        const docCharges = await firebase.getUserForm(
          currentUser.email,
          "chargesByDoc"
        )
        const dashboardData = await firebase.getDashboard(currentUser.email)
        const parsedDashData =
          dashboardData && dashboardData.docs
            ? dashboardData.docs.map((d) => d.data())
            : []
        setProfileForm({
          ...profile.data(),
          academic: form.data(),
          business: formBusiness.data(),
          pricing: formPricing.data(),
          sessions: parsedDashData,
          count: parsedDashData && parsedDashData.length,
          countDonated:
            parsedDashData && parsedDashData.filter((d) => d.refunded).length,
          countNotDonated:
            parsedDashData && parsedDashData.filter((d) => !d.refunded).length,
          docCharges: docCharges.data(),
        })
      }
    }
    if (firebase && Object.keys(currentUser).length > 0) {
      getUserData()
    }
  }, [firebase, currentUser])

  const handleUserStatus = async (userIsActive) => {
    await firebase.saveUserData({
      email: currentUser.email,
      data: {
        active: !userIsActive,
        status: userIsActive ? "inactive" : "active",
        businessFormReady: userIsActive ? currentUser.businessFormReady : true,
        academicFormReady: userIsActive ? currentUser.academicFormReady : true,
        activeStatusDate: Math.round(+new Date() / 1000),
        indexDocument: true,
      },
    })
    setCurrentUser((prevState) => {
      return {
        ...prevState,
        active: !userIsActive,
        status: userIsActive ? "inactive" : "active",
        businessFormReady: userIsActive ? prevState.businessFormReady : true,
        academicFormReady: userIsActive ? prevState.academicFormReady : true,
        activeStatusDate: Math.round(+new Date() / 1000),
      }
    })

    if (!userIsActive) {
      firebase.getIdToken().then((token) => {
        fetch(mailerUrl, {
          method: "POST",
          body: JSON.stringify({
            data: {
              templateId: 2358022, // User-activated-provider
              email: currentUser.email,
              variables: {
                helpLink: "https://youtu.be/j5xvCPErKE4",
                displayName: get(currentUser, ["displayName"], "Dr/Dra"),
                websiteLink: process.env.WEB_APP_SIGNIN,
              },
            },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
      })
    }
    setIsModalOpen(false)
  }

  const handleBack = () => {
    if (get(currentUser, ["userType"]) === "admin") {
      history.push(`/users`, {
        activeTab: 0,
      })
    } else {
      history.push(`/users`, {
        activeTab: get(currentUser, ["userType"]) === "provider" ? 1 : 2, // Set current tab on back
      })
    }
  }

  const getModalTitle = () => {
    if (get(currentUser, ["userType"]) === "provider") {
      return get(currentUser, ["status"]) === "active"
        ? t("block_user")
        : t("needs_verification")
    }
    return get(currentUser, ["status"]) === "active"
      ? t("block_user")
      : t("enabled_user")
  }

  const deleteUser = async (user) => {
    try {
      const token = await firebase.getIdToken()
      await fetch(deleteUserUrl, {
        method: "POST",
        body: JSON.stringify({ userToDeleteEmail: user.email }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then(() => {
        setRemoveModalOpen(false)
        setTimeout(() => {
          addToast(t("saved_successfully"), {
            appearance: "success",
            placement: "top-right",
          })
          setIsRemovingUser(false)
          handleBack()
        }, 1000)
      })
    } catch (error) {
      setIsRemovingUser(false)
      setRemoveModalOpen(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "top-right",
      })
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon isBack={false} onBack={handleBack} />{" "}
          {get(currentUser, ["displayName"], "Back")}
        </Back>
        <RowButtonContainer>
          {currentUser.userType !== "client" && (
            <Badge onClick={() => setRemoveModalOpen(true)}>
              <span>
                <TrashAlt size="18" />
                {t("delete_user")}
              </span>
            </Badge>
          )}
          {get(currentUser, ["status"], "") === "inactive" && (
            <Badge onClick={() => setIsModalOpen(true)}>
              <span>
                <Security size="18" />
                {get(currentUser, ["userType"]) === "provider"
                  ? t("needs_verification")
                  : t("enabled_user")}
              </span>
            </Badge>
          )}
          {get(currentUser, ["status"], "") === "active" && (
            <Badge isBlock onClick={() => setIsModalOpen(true)}>
              <span>
                <Block size="18" />
                {t("block_user")}
              </span>
            </Badge>
          )}
        </RowButtonContainer>
      </Nav>
      <Container>
        <TabsContainer>
          <Tab
            textKey="users_profile"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
          {get(currentUser, ["userType"]) === "provider" && (
            <>
              <Tab
                textKey="users_sessions"
                onTabClick={() => setActiveTab(1)}
                active={activeTab === 1}
                disabled={get(currentUser, ["status"], "") === "inactive"}
              />
              <Tab
                textKey="users_prescriptions"
                onTabClick={() => setActiveTab(2)}
                active={activeTab === 2}
                disabled={get(currentUser, ["status"], "") === "inactive"}
              />
              <Tab
                textKey="users_trials"
                onTabClick={() => setActiveTab(3)}
                active={activeTab === 3}
                disabled={get(currentUser, ["status"], "") === "inactive"}
              />
              <Tab
                textKey="users_payments"
                onTabClick={() => setActiveTab(4)}
                active={activeTab === 4}
                disabled={get(currentUser, ["status"], "") === "inactive"}
              />
              <Tab
                textKey="charges"
                onTabClick={() => setActiveTab(5)}
                active={activeTab === 5}
                disabled={false}
              />
            </>
          )}
        </TabsContainer>
        {activeTab === 0 && Object.keys(profileForm).length > 0 && (
          <PersonalInfo user={profileForm} />
        )}
        {activeTab === 1 && Object.keys(profileForm).length > 0 && (
          <Sessions data={profileForm} />
        )}
        {activeTab === 5 &&
          profileForm?.docCharges &&
          currentUser.userType === "provider" && (
            <DocCharges
              data={profileForm?.docCharges}
              email={currentUser?.email}
              firebase={firebase}
            />
          )}
      </Container>
      {get(currentUser, ["userType"]) !== "admin" && (
        <Footer>
          {currentUser.userType === "provider" && (
            <>
              <StatsCard
                number={get(currentUser, ["rating"], 0)}
                label="Rating"
              />
              <StatsCard
                number={get(profileForm, "count", "0")}
                label="Sesiones"
              />
              <StatsCard
                number={get(profileForm, "countDonated", "0")}
                label="Donadas"
              />
              <StatsCard
                number={get(profileForm, "countNotDonated", "0")}
                label="Pagadas"
              />
            </>
          )}
          {currentUser.userType === "client" && (
            <>
              <StatsCard
                number={get(profileForm, "count", "0")}
                label="Sesiones"
              />
              <StatsCard
                number={get(profileForm, "countCancelled", "0")}
                label="Canceladas"
              />
            </>
          )}
        </Footer>
      )}
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <Title>{getModalTitle()}</Title>
                <Description>
                  {get(currentUser, ["status"], "") === "active"
                    ? t("block_confirmation", {
                        user: get(currentUser, ["displayName"], ""),
                      })
                    : t("verify_confirmation", {
                        user: get(currentUser, ["displayName"], ""),
                      })}
                  {}
                </Description>
                <ButtonsContainer>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleUserStatus(
                        get(currentUser, ["status"], "") === "active"
                      )
                    }
                  >
                    {t("confirm")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {removeModalOpen && (
        <CustomModal
          key="deleteConfirmationModal"
          modalOpen={removeModalOpen}
          setModalOpen={setRemoveModalOpen}
          showClose={false}
        >
          <ModalContainer>
            <Title>{t("delete_modal_title")}</Title>
            <RegularFont>{t("delete_modal_paragraph")}</RegularFont>
            <RowContainer>
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation()
                  setRemoveModalOpen(false)
                }}
              >
                <p>{t("exit")}</p>
              </ActionButton>
              <ActionButton
                onClick={(e) => {
                  e.stopPropagation()
                  setIsRemovingUser(true)
                  deleteUser(currentUser)
                }}
                disabled={isRemovingUser}
              >
                <p>{t("delete_button")}</p>
              </ActionButton>
            </RowContainer>
          </ModalContainer>
        </CustomModal>
      )}
    </div>
  )
}

const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 185px);
  overflow: hidden;
`

const Footer = styled.section`
  height: 103px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  width: ${(props) => (props.isBlock ? "180px" : "160px")};
  height: 42px;
  background: rgba(77, 102, 245, 0.1);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
    }
  }
`

const RowButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
  & > p {
    font-weight: bold;
  }
  & > p:hover {
    color: ${(props) => props.theme.color.lightPurple};
    cursor: pointer;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.color.white.normal};
  width: 500px;
  height: 260px;
  padding: 22px;
`

export const RegularFont = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

const ActionButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`

UsersDetail.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const ComposedUsersDetail = compose(
  withFirebase,
  withAuthorization(condition)
)(UsersDetail)
export default ComposedUsersDetail
