import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"
import get from "lodash.get"
import SidebarItems from "../SidebarItems"
import SignOutButton from "../SignOut"
import { AuthUserContext } from "../../../context/Session"
import Logo from "../Logo"

function Sidebar(props, { defaultActive }) {
  const authUser = useContext(AuthUserContext)
  const {
    history: { location },
  } = props
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex")
  const lastActiveIndex = Number(lastActiveIndexString)
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  )

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex)
    setActiveIndex(newIndex)
  }

  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return `/${path}`
    }
    return path
  }

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    )
    changeActiveIndex(activeItem)
  }, [location])

  return (
    <SidebarParent>
      <div style={{ position: "fixed" }}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {SidebarItems.filter((i) => !i.isFooter).map((item, index) => {
          if (item.protected && get(authUser, ["roles", "BUSINESS_ADMIN"]))
            return null
          if (item.isFooter) return null
          const IconItem = item.icon
          return (
            <Link to={item.route} key={`sb-${item.key}`}>
              <SidebarItem key={item.name} active={index === activeIndex}>
                <IconItem size="24" />
              </SidebarItem>
            </Link>
          )
        })}
        <FooterContainer>
          {SidebarItems.map((item, index) => {
            if (item.protected && get(authUser, ["roles", "BUSINESS_ADMIN"]))
              return null
            if (!item.isFooter) return null
            const IconItem = item.icon
            return (
              <Link to={item.route} key={`sb-${item.key}`}>
                <SidebarItem key={item.name} active={index === activeIndex}>
                  <IconItem size="24" />
                  {item.bubble && (
                    <Bubble>
                      <span>+99</span>
                    </Bubble>
                  )}
                </SidebarItem>
              </Link>
            )
          })}
          <LoggedUser>
            <span>DOC</span>
          </LoggedUser>
          <SignOutButton />
        </FooterContainer>
      </div>
      <div className="behind-the-scenes" />
    </SidebarParent>
  )
}

Sidebar.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default Sidebar

const LogoContainer = styled.div`
  padding: 30px 12.5px;
  margin-bottom: 14px;
`

const SidebarParent = styled.div`
  background: #fff;
  border: 1px solid #f2f2f2;
  position: relative;
  width: 71px;

  a {
    text-decoration: none;
  }

  & > div {
    width: 71px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .behind-the-scenes {
    width: 71px;
  }
`

const FooterContainer = styled.div`
  height: auto;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SidebarItem = styled.div`
  position: relative;
  transition: all 0.25s ease-in-out;
  color: ${(props) =>
    props.active ? props.theme.color.primary : props.theme.color.black.normal};
  margin-bottom: 35px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(:first-child) {
    background: #c34a36;
  }
`

const LoggedUser = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Surfase / Concrete */
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 100px;
`

const Bubble = styled.div`
  position: absolute;
  width: 28px;
  height: 24px;
  top: -15px;
  left: 10px;
  background-color: ${({ theme }) => theme.color.error};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 0px 8px;
  border-radius: 100px;

  & > span {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.025rem;
    color: ${({ theme }) => theme.color.white.normal};
  }
`
