import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const CustomBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  position: ${(props) => props.position};
  min-width: 120px;
  width: ${(props) => props.width};
  height: 32px;
  background: ${(props) => props.theme.color.semiPalePurple};
  border-radius: 100px;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  padding: 0 3px;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
      color: red;
    }
  }
`
const Badge = (props) => {
  return <CustomBadge {...props} />
}

Badge.defaultProps = {
  left: 0,
  top: 0,
  width: "auto",
  margin: "0px 12px",
}

Badge.propTypes = {
  position: PropTypes.string.isRequired,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.string,
  margin: PropTypes.string,
}

export default Badge
