import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ActionButton = ({ children, onActionClick, dark, disabled }) => {
  return (
    <Container
      dark={dark}
      onClick={disabled ? null : onActionClick}
      disabled={disabled}
    >
      {children}
    </Container>
  )
}

export const Container = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  margin: 8px 5px;
  width: ${(props) => (props.dark ? "auto" : "87px")};
  height: 36px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  position: relative;
  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.disabled
      ? props.theme.color.background.gray30
      : props.dark
      ? props.theme.color.primary
      : "transparent"};
  & > span {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    color: ${(props) =>
      // eslint-disable-next-line no-nested-ternary
      props.disabled
        ? props.theme.color.background.black60
        : props.dark
        ? props.theme.color.white.normal
        : "rgba(0, 0, 0, 0.6)"};
    margin: inherit;
    user-select: none;
  }
`

ActionButton.defaultProps = {
  dark: false,
  disabled: false,
}

ActionButton.propTypes = {
  children: PropTypes.element.isRequired,
  onActionClick: PropTypes.func.isRequired,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default ActionButton
