import styled from "styled-components"
import ButtonWithLoader from "../../components/atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin: 20px;
  height: calc(100vh - 140px);
  overflow-y: scroll;
  width: 80%;
`

export const FormContainer = styled.div`
  height: calc(100vh - 140px);
  overflow-y: scroll;
`

export const SubmitButton = styled(ButtonWithLoader)`
  position: absolute;
  top: 60px;
  right: 40px;
  width: 250px;
  margin: 20px 0;
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`

export const ButtonFilter = styled.button`
  border: none;
  background-color: transparent;
  height: 35px;
  outline: none;
  cursor: pointer;
`

// eslint-disable-next-line import/prefer-default-export
export const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

export const Title = styled.div`
  height: 40px;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: -0.5px;
  margin-left: 30px;
  margin-top: 22px;
`

export const SubTitle = styled.p`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
`

export const SpreadContainer = styled.div`
  width: 100%;
`
