/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import { withFirebase } from "../../../utils/Firebase"
import { generatePassword, getReferenceCode } from "../../../utils/App"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"

const organizationRoles = [
  { value: "business_partner", label: "Administrador" },
  { value: "medical_visitor", label: "Visitador Médico" },
  { value: "client", label: "Empleado" },
]

const VisitorDrawer = ({
  firebase,
  open,
  closeDrawer,
  orgId,
  orgName,
  country,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)
  const [role, setRole] = useState(organizationRoles[0])
  const createAdminUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`

  const getInviteHeader = (type) => {
    let emailType = ""
    switch (type) {
      case "business_partner":
        emailType = "Administrador"
        break
      case "client":
        emailType = "Empleado"
        break
      default:
        emailType = "Visitador Médico"
    }

    return emailType
  }

  const getEmailTemplate = (type) => {
    let templateId = 0
    switch (type) {
      case "business_partner":
        templateId = 2358080
        break
      case "client":
        templateId = 6020425
        break
      default:
        templateId = 2358085
    }

    return templateId
  }

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_visitor")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            type: "business_partner", // "medical_visitor
            email: undefined,
            confirmEmail: undefined,
            password: generatePassword(),
            displayName: undefined,
            phoneNumber: undefined,
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              const token = await firebase.getIdToken()
              fetch(createAdminUrl, {
                method: "POST",
                body: JSON.stringify({ user: values }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }).then(async (dataResponse) => {
                const parsedRes = await dataResponse.json()
                const { error } = parsedRes

                if (
                  dataResponse.status === 200 ||
                  (error &&
                    error.message.includes(
                      "The email address is already in use"
                    ) &&
                    values.type === "client")
                ) {
                  try {
                    let templateId = getEmailTemplate(values.type)
                    let isExistingAgent = false
                    let existingAgent = []

                    if (
                      error &&
                      error.message.includes(
                        "The email address is already in use"
                      )
                    ) {
                      templateId = 6333133
                      isExistingAgent = true
                      existingAgent = await firebase.checkExistingAgent(
                        values.email
                      )
                    }

                    if (existingAgent.length > 0 && existingAgent[0].active) {
                      addToast(
                        `La dirección de correo ya está siendo utilizada por otra cuenta en la empresa ${existingAgent[0].businessName}`,
                        {
                          appearance: "error",
                          placement: "bottom-center",
                        }
                      )
                      setIsCreating(false)
                    } else {
                      // This timeout gives time for the firebase trigger to be executed
                      setTimeout(async () => {
                        await fetch(mailerUrl, {
                          method: "POST",
                          body: JSON.stringify({
                            data: {
                              templateId,
                              email: values.email,
                              variables: {
                                subject: `Te han invitado como ${getInviteHeader(
                                  values.type
                                )} a la organización ${orgName}`,
                                orgName,
                                displayName: values.displayName,
                                password: values.password,
                              },
                            },
                          }),
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                        })
                        const getCodeToReference = getReferenceCode()

                        if (
                          isExistingAgent &&
                          existingAgent.some((a) => a.orgId === orgId)
                        ) {
                          await firebase.saveAgentData({
                            orgId,
                            id: existingAgent[0].id,
                            data: {
                              active: true,
                              createdAt: Date.now(),
                            },
                          })
                        } else {
                          await firebase.addOrgColaborator({
                            orgId,
                            email: values.email,
                            agentData: {
                              email: values.email,
                              displayName: values.displayName,
                              phoneNumber: values.phoneNumber,
                              phone: values.phoneNumber,
                              active: true,
                              createdAt: Date.now(),
                              referenceCode: getCodeToReference,
                              role: values.type,
                              ...(values.type === "business_partner" && {
                                officeId: orgId,
                              }),
                            },
                          })
                        }
                        let userUpdatePayload = {}
                        if (isExistingAgent) {
                          userUpdatePayload = {
                            active: true,
                            status: "active",
                            organizationId: orgId,
                          }
                        } else {
                          userUpdatePayload = {
                            displayName: values.displayName,
                            email: values.email,
                            active: true,
                            status: "active",
                            country,
                            userType: "client",
                            phoneNumber: values.phoneNumber,
                            organizationId: orgId,
                            referenceCode: getCodeToReference,
                            roles: {
                              BUSINESS_ADMIN:
                                values.type === "business_partner",
                            },
                          }
                        }

                        await firebase.saveUserData({
                          email: values.email,
                          data: userUpdatePayload,
                        })

                        addToast(t("saved_successfully"), {
                          appearance: "success",
                          placement: "bottom-center",
                        })
                        resetForm({ value: "" })
                        setRole(organizationRoles[0])
                        setIsCreating(false)
                        closeDrawer()
                      }, 2500)
                    }
                  } catch (e) {
                    console.log("Unexpected error:", e)
                    addToast(t("unexpected_error"), {
                      appearance: "error",
                      placement: "bottom-center",
                    })
                    setIsCreating(false)
                  }
                } else {
                  addToast(t("unexpected_error"), {
                    appearance: "error",
                    placement: "bottom-center",
                  })

                  setIsCreating(false)
                }
              })
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            type: Yup.string().required(t("error_all_fields_required")),
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            confirmEmail: Yup.string()
              .oneOf([Yup.ref("email"), null], t("error_email_must_match"))
              .required(t("error_all_fields_required")),
            displayName: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.number()
              .typeError(t("error_phone_number"))
              .required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>
                Ingrese los datos de contacto del nuevo colaborador que desea
                crear dentro de la organización.
              </p>
              <CustomSelect
                value={role}
                formKey="type"
                lngKey="usr_type"
                handleChange={(event, value) => {
                  props.setFieldValue("type", value)
                  setRole(organizationRoles.filter((r) => r.value === value))
                }}
                items={organizationRoles}
                showError={!props.values.type && Boolean(props.errors.type)}
              />
              <CustomInput
                touched={props.touched.displayName}
                errors={props.errors.displayName}
                icon="user"
                value={props.values.displayName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("display_name_label")}
                type="text"
                id="displayName"
                name="displayName"
              />
              <CustomInput
                touched={props.touched.email}
                errors={props.errors.email}
                icon="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("email_address")}
                type="email"
                id="email"
                name="email"
              />
              <CustomInput
                touched={props.touched.confirmEmail}
                errors={props.errors.confirmEmail}
                icon="email"
                value={props.values.confirmEmail}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("email_address_verify")}
                type="email"
                id="confirmEmail"
                name="confirmEmail"
                avoidCopyAndPaste
              />
              <CustomInput
                touched={props.touched.phoneNumber}
                errors={props.errors.phoneNumber}
                icon="phone"
                value={props.values.phoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("phone_label")}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
              />
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

VisitorDrawer.defaultProps = {
  open: false,
}

VisitorDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}
export default withFirebase(VisitorDrawer)
