import React, { PureComponent } from "react"
import { I18nextProvider, withTranslation } from "react-i18next"
import i18next from "./config"

// eslint-disable-next-line import/prefer-default-export
export function withTrans(WrappedComponent) {
  const NewWrappedComponent = withTranslation()(WrappedComponent)
  return class extends PureComponent {
    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <NewWrappedComponent {...this.props} language={i18next.language} />
        </I18nextProvider>
      )
    }
  }
}
