import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
// import get from "lodash.get"
import { compose } from "recompose"
import { useLocation, useParams } from "react-router-dom"
import BackIcon from "../../components/atoms/BackIcon"
import ActionButton from "../../components/atoms/ActionButton"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
  TableFooter,
} from "../../components/atoms/Table"
import { withAuthorization } from "../../context/Session"
import { FilterContainer } from "../Payments/Sections/Administration/styles"
import IdCard from "../Payments/Sections/Summary/children/IdCard"

const PayoutDetail = () => {
  const {
    state: { transactions, summary, isSuperAdmin },
  } = useLocation()
  const { id } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    if ((transactions || summary) && id) {
      setData(summary)
    }
  }, [transactions, summary, isSuperAdmin, id])

  const sessionsTotal = data
    .filter((d) => !d.cancelled)
    .reduce((acc, item) => acc + parseFloat(item.total || 0), 0)

  const donations = data
    .filter((d) => !d.cancelled)
    .reduce((acc, item) => acc + parseFloat(item.totalDonations || 0), 0)

  const fees = data
    .filter((d) => !d.cancelled)
    .reduce((acc, item) => acc + parseFloat(item.totalFees || 0), 0)

  const granTotal = sessionsTotal - fees - donations

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          Ordenes de Pago
        </Back>
      </Nav>
      <Container>
        <FilterContainer>
          <Title>Detalle Orden de Pago</Title>
          <ActionButton dark>
            <FilterContainer>
              <span>Descargar CSV</span>
            </FilterContainer>
          </ActionButton>
        </FilterContainer>
        <List>
          <Table>
            <TableHeader>
              {isSuperAdmin && <TableHeaderItem>Banco</TableHeaderItem>}
              {!isSuperAdmin && <TableHeaderItem>Médico</TableHeaderItem>}
              {!isSuperAdmin && <TableHeaderItem>DNI/ID</TableHeaderItem>}
              {!isSuperAdmin && <TableHeaderItem>Banco</TableHeaderItem>}
              {!isSuperAdmin && <TableHeaderItem>Cuenta</TableHeaderItem>}
              {!isSuperAdmin && <TableHeaderItem>Fecha</TableHeaderItem>}
              <TableHeaderItem>Original</TableHeaderItem>
              <TableHeaderItem>Comisiones</TableHeaderItem>
              <TableHeaderItem>Deducciones</TableHeaderItem>
              <TableHeaderItem>Total</TableHeaderItem>
            </TableHeader>
            {data.length > 0 ? (
              data.map((s) => (
                <TableRow key={s.id}>
                  <TableRowItem>
                    {isSuperAdmin ? s.bank : s.providerName}
                  </TableRowItem>
                  {!isSuperAdmin && (
                    <TableRowItem>
                      <IdCard
                        provider={s.provider}
                        providerId={s.providerIdNumber}
                      />
                    </TableRowItem>
                  )}
                  {!isSuperAdmin && (
                    <TableRowItem>{s.providerBankName}</TableRowItem>
                  )}
                  {!isSuperAdmin && (
                    <TableRowItem>{s.providerBankAccount}</TableRowItem>
                  )}
                  {!isSuperAdmin && (
                    <TableRowItem>
                      {moment(s.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </TableRowItem>
                  )}
                  <TableRowItem>{`${parseFloat(s.totalSessions).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(s.totalFees).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>
                    {`${parseFloat(s.totalDonations).toFixed(2)}`}
                  </TableRowItem>
                  <TableRowItem>{`${parseFloat(s.payoutTotal).toFixed(
                    2
                  )}`}</TableRowItem>
                </TableRow>
              ))
            ) : (
              <EmptyOrLoadingContainer>Sin Resultados</EmptyOrLoadingContainer>
            )}
            <TableFooter>
              <div>{`Total general: ${sessionsTotal}`}</div>
              <div>{`Comisiones: ${fees}`}</div>
              <div>{`Donaciones: ${donations}`}</div>
              <div>{`A pagar: ${granTotal.toFixed(2)}`}</div>
            </TableFooter>
          </Table>
        </List>
      </Container>
    </div>
  )
}

PayoutDetail.propTypes = {}

const List = styled.section`
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  margin: 0 30px;
  box-sizing: border-box;
`

const Title = styled.h4`
  padding: 0 30px;
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
  position: relative;
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const condition = (authUser) => !!authUser
const ComposedPayoutDetail = compose(withAuthorization(condition))(PayoutDetail)
export default ComposedPayoutDetail
