import { People } from "@styled-icons/material/People"
// import { Leaderboard } from '@styled-icons/material/Leaderboard'
import { BusinessCenter } from "@styled-icons/material/BusinessCenter"
import { AttachMoney } from "@styled-icons/material/AttachMoney"
import { Assessment } from "@styled-icons/material/Assessment"
// import { Search } from "@styled-icons/material/Search"
// import { Notifications } from "@styled-icons/material/Notifications"

const SidebarItems = [
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: Assessment,
    key: "dashboard",
    protected: true,
  },
  {
    name: "Usuarios",
    route: "/users",
    icon: People,
    key: "users",
    protected: true,
  },
  {
    name: "Organizaciones",
    route: "/organizations",
    icon: BusinessCenter,
    key: "orgs",
  },
  {
    name: "Pagos",
    route: "/payments",
    icon: AttachMoney,
    key: "payments",
    protected: true,
  },
  // {
  //   name: "Search",
  //   route: "/search",
  //   isFooter: true,
  //   icon: Search,
  //   key: "search",
  // },
  // {
  //   name: "Notifications",
  //   route: "/notifications",
  //   isFooter: true,
  //   icon: Notifications,
  //   bubble: true,
  //   key: "notifications",
  // },
]

export default SidebarItems
