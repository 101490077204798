import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import moment from "moment"
import truncate from "lodash.truncate"
import { useCopyToClipboard } from "usehooks-ts"
import { useToasts } from "react-toast-notifications"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useTranslation } from "react-i18next"

// Components
import Nav from "../../atoms/Nav"
import Loading from "../../atoms/Loading"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
} from "../../atoms/Table"
import ClipboardIcon from "../../atoms/ClipboardIcon"
import InvoicePDF from "../../atoms/InvoicePDF"
import ReceiptPDF from "../../atoms/ReceiptPDF"

// Utils
import { withFirebase } from "../../../utils/Firebase"

const OrgInvoices = ({ orgId, firebase }) => {
  const { t } = useTranslation()
  const [, copy] = useCopyToClipboard()
  const { addToast } = useToasts()

  const [invoices, setInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getItemsFromApi = async (id) => {
    try {
      const itemsData = await firebase.getInvoiceItemsById(id)
      const data =
        itemsData.docs && itemsData.docs.length > 0
          ? itemsData.docs.map((item) => ({ ...item.data() }))
          : []
      return data
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
    return []
  }

  const compareDescription = (a, b) => {
    return b?.amount - a?.amount
  }

  const getInvoicesFromApi = async () => {
    try {
      const invoicesData = await firebase.getInvoicesByOrg(orgId)
      invoicesData?.map(async (invoice) => {
        const itemsFromApi = await getItemsFromApi(invoice?.id)
        // eslint-disable-next-line no-param-reassign
        invoice.items = itemsFromApi?.sort(compareDescription)
        return invoice
      })
      setTimeout(() => {
        setInvoices(invoicesData?.sort((a, b) => b?.createdAt - a?.createdAt))
        setIsLoading(false)
      }, 2000)
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  useEffect(() => {
    if (orgId) {
      setIsLoading(true)
      getInvoicesFromApi()
    }
  }, [firebase, orgId])

  const copyToClipboard = async (id) => {
    await copy(id)
    addToast("Copiado al portapapeles.", {
      appearance: "success",
      placement: "bottom-center",
    })
  }

  return (
    <>
      <Nav titleKey="orgs_receipts" onFilter={null} />
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <>
            <List>
              <Table>
                <TableHeader minWidth="1200px">
                  <TableHeaderItem>ID</TableHeaderItem>
                  <TableHeaderItem>Email</TableHeaderItem>
                  <TableHeaderItem>Nombre</TableHeaderItem>
                  <TableHeaderItem>Tipo</TableHeaderItem>
                  <TableHeaderItem>Fecha</TableHeaderItem>
                  <TableHeaderItem>Pagado</TableHeaderItem>
                  <TableHeaderItem>Acción</TableHeaderItem>
                </TableHeader>
                {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <>
                      {invoice?.items?.length ? (
                        <TableRow
                          key={invoice.id}
                          isVerticalOnMobile
                          minWidth="1200px"
                        >
                          <TableRowItem>
                            {truncate(invoice?.invoiceNumber, { length: 15 })}
                            <ClipboardContainer
                              onClick={async (e) => {
                                e.stopPropagation()
                                copyToClipboard(invoice?.invoiceNumber || "")
                              }}
                            >
                              <ClipboardIcon />
                            </ClipboardContainer>
                          </TableRowItem>
                          <TableRowItem>{invoice?.email}</TableRowItem>
                          <TableRowItem>{invoice?.customerName}</TableRowItem>
                          <TableRowItem>
                            {invoice?.type === "invoice" ? "Factura" : "Recibo"}
                          </TableRowItem>
                          <TableRowItem>
                            {moment(invoice.createdAt).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </TableRowItem>
                          <TableRowItem>{`$${parseFloat(
                            invoice?.paidAmount
                          ).toFixed(2)}`}</TableRowItem>
                          <TableRowItem>
                            <PDFDownloadLink
                              document={
                                invoice?.type === "invoice" ? (
                                  <InvoicePDF
                                    invoice={invoice}
                                    items={invoice?.items}
                                  />
                                ) : (
                                  <ReceiptPDF
                                    invoice={invoice}
                                    items={invoice?.items}
                                  />
                                )
                              }
                              fileName={`${
                                invoice?.type === "invoice" ? "IN" : "RE"
                              }_${invoice?.invoiceNumber}`}
                            >
                              <Button
                                style={{ cursor: "pointer" }}
                                type="button"
                              >
                                Descargar
                              </Button>
                            </PDFDownloadLink>
                          </TableRowItem>
                        </TableRow>
                      ) : null}
                    </>
                  ))
                ) : (
                  <EmptyOrLoadingContainer>
                    <Text>
                      En este momento no tiene facturas, ni recibos generados,
                      por favor espere a su proxima fecha de facturación.
                    </Text>
                  </EmptyOrLoadingContainer>
                )}
              </Table>
            </List>
          </>
        )}
      </Container>
    </>
  )
}

export const Container = styled.div`
  height: calc(100% - 150px);
  padding: 20px;
`

export const Text = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 150px);
  padding: 20px;
`

const List = styled.section`
  height: 100%;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  box-sizing: border-box;
`

export const Button = styled.button`
  height: 40px;
  width: 120px;
  border-radius: 5px;
  background-color: #4d66f5;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
`
export const TooltipText = styled.p(
  ({ theme }) => css`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.color.black.normal};
  `
)

export const ClipboardContainer = styled.div`
  margin-left: 20px;
  cursor: pointer;
`

OrgInvoices.propTypes = {
  orgId: PropTypes.string.isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(OrgInvoices)
