import React from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Theme from "../../../theme"

const customStyles = {
  container: (provided) => ({
    ...provided,
    marginLeft: 2,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderRadius: state.selectProps.borderRadius,
    borderColor: state.selectProps.showError
      ? Theme.color.error
      : Theme.color.iceBlue,
    marginBottom: 30,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

const CustomSelect = ({
  formKey,
  value,
  lngKey,
  items,
  handleChange,
  showError,
  getDefaultValue,
  label,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Label>{t(label)}</Label>
      <Select
        styles={customStyles}
        defaultValue={getDefaultValue ? getDefaultValue(value) : null}
        value={value}
        label={t(lngKey)}
        options={items}
        onChange={(option) => handleChange(formKey, option.value)}
        placeholder={t(lngKey)}
        width="100%"
        borderRadius="25px"
        showError={showError}
      />
    </>
  )
}

CustomSelect.defaultProps = {
  showError: false,
  value: "",
  getDefaultValue: undefined,
  label: undefined,
}

CustomSelect.propTypes = {
  formKey: PropTypes.string.isRequired,
  value: PropTypes.string,
  lngKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  getDefaultValue: PropTypes.func,
  label: PropTypes.string,
}

const Label = styled.span`
  font-size: 1.375rem;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: center;
  color: #071435;
  margin-bottom: 10px;
`

export default CustomSelect
