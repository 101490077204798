import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import pickby from "lodash.pickby"
import isEmpty from "lodash.isempty"
import { useToasts } from "react-toast-notifications"
import { useDropzone } from "react-dropzone"
import { useLocation, useHistory, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import XLSX from "xlsx"

// Components
import BackIcon from "../../components/atoms/BackIcon"
import CustomModal from "../../components/atoms/CustomModal"
import StatsCard from "../../components/atoms/StatsCard"
import Tab from "../../components/atoms/Tab"
import {
  ModalWrapper,
  ModalOptions,
  ButtonsContainer,
  Title,
  Description,
} from "../../components/atoms/ModalElements"
import GenericTable from "../../components/atoms/GenericTable"
import OrgInfo from "../../components/organisms/OrgInfo"
import OrgUsers from "../../components/organisms/OrgUsers"
import OrgProducts from "../../components/organisms/OrgProducts"
import OrgPayments from "../../components/organisms/OrgPayments"
import OrgDoctors from "../../components/organisms/OrgDoctors"
import OrgInvoices from "../../components/organisms/OrgInvoices"

// Utils
import { withAuthorization } from "../../context/Session"
import { withFirebase } from "../../utils/Firebase"
import * as ROUTES from "../../utils/Constants/Routes"
import {
  generatePassword,
  createAdminUrl,
  getReferenceCode,
  mailerUrl,
  getCountryGenericValue,
} from "../../utils/App"

// Styles
import Theme from "../../theme"
import {
  Nav,
  Back,
  Badge,
  Container,
  TabsContainer,
  Footer,
  IncompleteProductsModalContent,
  IncompleteProductsBanner,
} from "./styles"

const PRODUCT_TABLE_CELL_TYPES = {
  TEXT: "text",
  FILE: "file",
  IMAGE: "image",
  BOOLEAN: "boolean",
  SELECT: "select",
}

const productRequiredColumns = [
  "country",
  "labName",
  "sku",
  "severalRestricted",
  "prescriptionRestricted",
  "composition",
  "pharmaceuticalPresentation",
  "cie11",
  "noCie11",
  "mainPhotoURL",
  "URLInsert",
  "therapeuticIndications",
  "canShareProductResourceInChat",
  "canShowProductResultByCIE11",
]

const OrgsDetails = ({ firebase }) => {
  const deleteUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
  const { state } = useLocation()
  const history = useHistory()
  const { addToast, removeAllToasts } = useToasts()
  const { t } = useTranslation()
  const [profileForm, setProfileForm] = useState({
    agents: [],
    products: [],
    addresses: [],
    doctors: [],
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPendingProductsModalOpen, setPendingProductModal] = useState(false)
  const [incompletedProducts, setIncompletedProducts] = useState([])
  const [hasIncompletedProducts, setHasIncompletedProducts] = useState(false)
  const [activeTab, setActiveTab] = useState(get(state, "activeTab", 0))
  const [currentOrg] = useState(get(state, ["currentOrg"]))
  const [isLoadingFile, setLoadingFile] = useState("")

  // Using in userCard to remove users
  const [isRemovingUser, setIsRemovingUser] = useState(false)

  const getFormsCreate = async () => {
    const profile = await firebase.org(currentOrg.uid).get()
    const agents = await firebase.getOrgForm(currentOrg.uid, "agents")
    const products = await firebase.getOrgForm(currentOrg.uid, "products")
    const addresses = await firebase.getOrgForm(currentOrg.uid, "addresses")
    const doctors = await firebase.getOrgForm(currentOrg.uid, "doctors") // TODO: create function to get doctors

    setProfileForm({
      ...profile.data(),
      agents: agents.docs,
      products: products.docs,
      addresses: addresses.docs,
      doctors: doctors.docs,
    })
  }

  const addProducts = async (newProducts) => {
    await firebase.addOrgProducts({
      orgId: currentOrg.uid,
      products: newProducts,
    })
  }

  const updateProduct = async (newProduct) => {
    const { id, ...safeProduct } = newProduct
    await firebase.updateOrgProduct({
      orgId: currentOrg.uid,
      documentId: id,
      productData: safeProduct,
    })
  }

  const fileUploadHandler = async (_fileName, config, id, columnIndex) => {
    try {
      setLoadingFile(`${id}-${columnIndex}`)
      const uploadDir = `organizations/${currentOrg.id}/products/${config.name}`
      const snapshot = await firebase.uploadToStorage(uploadDir, config.blob, {
        orgId: state.currentOrg.id,
        storagePath: uploadDir,
      })
      const imageURL = await snapshot.ref.getDownloadURL()
      setLoadingFile("")
      return imageURL
    } catch (error) {
      setLoadingFile("")
      return ""
    }
  }

  const invalidRequiredProductFields = (product) => {
    const emptyFields = pickby(
      product,
      (value) =>
        (typeof value === "string" && isEmpty(value)) ||
        (typeof value === "number" && isEmpty(value))
    )

    const emptyProductKeys = Object.keys(emptyFields)

    const emptyProductColumns = emptyProductKeys.find((key) =>
      productRequiredColumns.indexOf(key)
    )
    return Boolean(emptyProductColumns)
  }

  const getProductCellType = (cellKey) => {
    switch (cellKey) {
      case productRequiredColumns[0]:
        return PRODUCT_TABLE_CELL_TYPES.SELECT
      case productRequiredColumns[8]:
        return PRODUCT_TABLE_CELL_TYPES.BOOLEAN
      case productRequiredColumns[9]:
        return PRODUCT_TABLE_CELL_TYPES.IMAGE
      case productRequiredColumns[10]:
        return PRODUCT_TABLE_CELL_TYPES.FILE
      case productRequiredColumns[3]:
      case productRequiredColumns[4]:
      case productRequiredColumns[12]:
      case productRequiredColumns[13]:
        return PRODUCT_TABLE_CELL_TYPES.BOOLEAN

      default:
        return PRODUCT_TABLE_CELL_TYPES.TEXT
    }
  }

  const incompletedProductFilter = (product) => {
    const parsedProduct = product.data()
    if (parsedProduct.state === "incompleted" && !parsedProduct.noCie11) {
      return true
    }
    if (typeof parsedProduct.state === "undefined" && !parsedProduct.noCie11) {
      return true
    }
    return false
  }

  const getIncompletedProduct = useCallback(async () => {
    const products = await firebase.getOrgForm(currentOrg.uid, "products")
    const incompleted = products.docs.filter(incompletedProductFilter)

    if (incompleted.length >= 1) {
      const productsData = incompleted.map((product) => ({
        ...product.data(),
        id: product.id,
      }))

      const tableOfProducts = productsData.map((product) => {
        const columns = Object.keys(product)
        const sortedColumns = []

        productRequiredColumns.forEach((header, index) => {
          const requiredColumnIndex = columns.findIndex(
            (column) => column === header
          )
          sortedColumns[index] = columns[requiredColumnIndex]
        })

        return {
          rowKey: product.id,
          row: sortedColumns.map((col, columnIndex) => ({
            columnValue: product[col],
            columnType: getProductCellType(col),
            columnKey: getReferenceCode(),
            columnName: col,
            columnIndex,
          })),
        }
      })

      setIncompletedProducts(tableOfProducts)
      setHasIncompletedProducts(true)
      setPendingProductModal(true)
    } else {
      setPendingProductModal(false)
      setIncompletedProducts([])
      setHasIncompletedProducts(false)
    }
  }, [currentOrg.uid, firebase])

  const updateIncompleteProduct = (productUpdate, rowKey) => {
    const selectedProductRowIndex = incompletedProducts.findIndex(
      (row) => row.rowKey === rowKey
    )

    const nextRow = { ...incompletedProducts[selectedProductRowIndex] }

    nextRow.row[productUpdate.columnIndex].columnValue = productUpdate.value

    const nextState = [...incompletedProducts]

    nextState[selectedProductRowIndex] = nextRow

    setIncompletedProducts(nextState)
  }

  const processProductsImportedFile = async (fileProducts) => {
    // 26 => Number of columns required
    if (fileProducts[0].length === 28) {
      const newProducts = []
      fileProducts.forEach(async (row, index) => {
        if (index) {
          const newProduct = {
            country: getCountryGenericValue(get(row, ["0"], "CR")).value,
            labName: get(row, ["1"], ""),
            sku: get(row, ["2"], ""),
            severalRestricted: get(row, ["3"], "NO") === "SI",
            prescriptionRestricted: get(row, ["4"], "NO") === "SI",
            composition: get(row, ["5"], ""),
            pharmaceuticalPresentation: get(row, ["6"], ""),
            cie11: get(row, ["7"], ""),
            noCie11: Boolean(get(row, ["7"], false)),
            mainPhotoURL: get(row, ["8"], ""),
            URLInsert: get(row, ["9"], ""),
            therapeuticIndications: get(row, ["10"], ""),
            canShareProductResourceInChat: get(row, ["11"], "SI" === "NO"),
            canShowProductResultByCIE11: get(row, ["12"], "SI" === "NO"),
            pharmacologicalAction: get(row, ["13"], ""),
            excipients: get(row, ["14"], ""),
            dose: get(row, ["15"], ""),
            contraindications: get(row, ["16"], ""),
            drugInteractions: get(row, ["17"], ""),
            restrictions: get(row, ["18"], ""),
            reactions: get(row, ["19"], ""),
            warnings: get(row, ["20"], ""),
            precautions: get(row, ["21"], ""),
            overDoseIndications: get(row, ["22"], ""),
            storageIndications: get(row, ["23"], ""),
            expirationIndications: get(row, ["24"], ""),
            clinicalStudyURL: get(row, ["25"], ""),
            videoURL: get(row, ["26"], ""),
            slidesURL: get(row, ["27"], ""),
          }
          const isInvalidProduct = invalidRequiredProductFields(newProduct)
          if (isInvalidProduct) {
            if (!newProduct.noCie11) {
              newProduct.state = "incompleted"
            }
          } else {
            newProduct.state = "completed"
          }

          newProducts.push(newProduct)
        }
      })
      addToast(t("file_been_processed", { totalFiles: fileProducts.length }), {
        appearance: "info",
        autoDismiss: false,
      })

      addProducts(newProducts)
        .then(() => {
          removeAllToasts()
          addToast(t("file_processed"), { appearance: "success" })
          getIncompletedProduct()
          getFormsCreate()
        })
        .catch(() => {
          removeAllToasts()
          addToast(t("unexpected_error"), { appearance: "error" })
        })
      return true
    }
    addToast(t("invalid_file"), { appearance: "error" })
    return false
  }

  const processIncompleteProducts = async (products) => {
    const promises = []

    products.forEach(async (productRow) => {
      const newProduct = {}
      productRow.row.forEach((column) => {
        if (typeof column.columnName !== "undefined") {
          newProduct[column.columnName] = column.columnValue
        }
      })

      newProduct.id = productRow.rowKey

      const isInvalidProduct = invalidRequiredProductFields(newProduct)

      if (typeof newProduct.URLInsert === "undefined") {
        newProduct.URLInsert = ""
      }

      if (typeof newProduct.canShareProductResourceInChat === "undefined") {
        newProduct.canShareProductResourceInChat = false
      }

      if (typeof newProduct.canShowProductResultByCIE11 === "undefined") {
        newProduct.canShowProductResultByCIE11 = true
      }

      if (isInvalidProduct) {
        if (typeof newProduct.noCie11 === "undefined" || !newProduct.noCie11) {
          newProduct.state = "incompleted"
          newProduct.noCie11 = false
        }
      } else {
        newProduct.state = "completed"
      }

      promises.push(updateProduct(newProduct))
    })

    Promise.all(promises)
      .then(() => {
        removeAllToasts()
        addToast(t("file_processed"), { appearance: "success" })
        getIncompletedProduct()
        getFormsCreate()
      })
      .catch(() => {
        removeAllToasts()
        addToast(t("unexpected_error"), { appearance: "error" })
      })
    return true
  }

  const onDropProducts = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () =>
        addToast(t("unexpected_error"), {
          appearance: "error",
          placement: "bottom-center",
        })
      reader.onerror = () =>
        addToast(t("unexpected_error"), {
          appearance: "error",
          placement: "bottom-center",
        })
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]

        const parsedFirstWorksheet = {}

        for (const [key, value] of Object.entries(firstWorksheet)) {
          if (key === "!ref") {
            parsedFirstWorksheet[key] = value
          } else {
            const escapedValue = encodeURIComponent(value.v)
            const parsedValue = decodeURIComponent(escapedValue)

            parsedFirstWorksheet[key] = {
              t: value.t,
              v: parsedValue,
            }
          }
        }

        const dataXLS = XLSX.utils.sheet_to_json(parsedFirstWorksheet, {
          header: 1,
          blankrows: false,
        })

        processProductsImportedFile(dataXLS)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const getForms = async () => {
        const profile = await firebase.org(currentOrg.uid).get()
        const agents = await firebase.getOrgForm(currentOrg.uid, "agents")
        const products = await firebase.getOrgForm(currentOrg.uid, "products")
        const addresses = await firebase.getOrgForm(currentOrg.uid, "addresses")
        const doctors = await firebase.getOrgForm(currentOrg.uid, "doctors") // TODO: create function to get doctors

        setProfileForm({
          ...profile.data(),
          agents: agents.docs,
          products: products.docs,
          addresses: addresses.docs,
          doctors: doctors.docs,
        })
      }

      const addVisitor = async (newVisitor, token) => {
        try {
          await fetch(createAdminUrl, {
            method: "POST",
            body: JSON.stringify({ user: newVisitor }),
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }).then(() => {
            try {
              // This timeout gives time for the firebase trigger to be executed
              setTimeout(async () => {
                await fetch(mailerUrl, {
                  method: "POST",
                  body: JSON.stringify({
                    data: {
                      templateId: 2358085, // invite visitor
                      email: newVisitor.email,
                      variables: {
                        subject: "Te han invitado como visitador médico",
                        orgName: currentOrg.businessName,
                        displayName: newVisitor.displayName,
                        password: newVisitor.password,
                      },
                    },
                  }),
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                })
                await firebase.addOrgColaborator({
                  orgId: currentOrg.uid,
                  email: newVisitor.email,
                  agentData: {
                    displayName: newVisitor.displayName,
                    phoneNumber: newVisitor.phoneNumber,
                    active: true,
                  },
                })

                await firebase.saveUserData({
                  email: newVisitor.email,
                  data: {
                    displayName: newVisitor.displayName,
                    email: newVisitor.email,
                    active: true,
                    status: "active",
                    country: currentOrg.country,
                    userType: "medical_visitor",
                    phoneNumber: newVisitor.phoneNumber,
                    organizationId: currentOrg.uid,
                  },
                })

                addToast(t("saved_successfully"), {
                  appearance: "success",
                  placement: "bottom-center",
                })
              }, 2500)
            } catch (e) {
              addToast(t("unexpected_error"), {
                appearance: "error",
                placement: "bottom-center",
              })
            }
          })
        } catch (error) {
          addToast(t("unexpected_error"), {
            appearance: "error",
            placement: "bottom-center",
          })
        }
      }

      const processVisitorsImportedFile = async (fileUser) => {
        const token = await firebase.getIdToken()
        if (fileUser[0].length === 3) {
          const promises = []
          fileUser.forEach(async (row, index) => {
            if (index) {
              const newVisitor = {
                displayName: row[0],
                phoneNumber: row[1] || "",
                email: row[2] || "",
                password: generatePassword(),
              }
              try {
                promises.push(addVisitor(newVisitor, token))
              } catch (error) {
                addToast(t("unexpected_error"), { appearance: "error" })
              }
            }
          })
          addToast(t("file_been_processed"), { appearance: "info" })
          Promise.all(promises)
            .then(() => {
              addToast(t("file_processed"), { appearance: "success" })
              getForms()
            })
            .catch(() => {
              addToast(t("unexpected_error"), { appearance: "error" })
            })
          return true
        }
        addToast(t("invalid_file"), { appearance: "error" })
        return false
      }

      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () =>
          addToast(t("unexpected_error"), {
            appearance: "error",
            placement: "bottom-center",
          })
        reader.onerror = () =>
          addToast(t("unexpected_error"), {
            appearance: "error",
            placement: "bottom-center",
          })
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result)
          const workbook = XLSX.read(data, { type: "array" })
          const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
          const dataXLS = XLSX.utils.sheet_to_json(firstWorksheet, {
            header: 1,
          })
          processVisitorsImportedFile(dataXLS)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    [addToast, firebase, t, currentOrg]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop: activeTab === 1 ? onDrop : onDropProducts,
  })

  const getForms = async () => {
    const profile = await firebase.org(currentOrg.uid).get()
    const agents = await firebase.getOrgForm(currentOrg.uid, "agents")
    const products = await firebase.getOrgForm(currentOrg.uid, "products")
    const addresses = await firebase.getOrgForm(currentOrg.uid, "addresses")
    const doctors = await firebase.getOrgForm(currentOrg.uid, "doctors") // TODO: create function to get doctors

    setProfileForm({
      ...profile.data(),
      agents: agents.docs,
      products: products.docs,
      addresses: addresses.docs,
      doctors: doctors.docs,
    })
  }

  useEffect(() => {
    if (firebase && Object.keys(currentOrg).length > 0) {
      getForms()
      getIncompletedProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase, currentOrg, getIncompletedProduct])

  const deleteUser = async (user) => {
    try {
      const token = await firebase.getIdToken()
      await fetch(deleteUserUrl, {
        method: "POST",
        body: JSON.stringify({
          userToDeleteEmail: user.userEmail,
          orgUserId: user.email,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then(() => {
        setTimeout(() => {
          getForms()
          setIsRemovingUser(true)
          addToast(t("saved_successfully"), {
            appearance: "success",
            placement: "top-right",
          })
          setIsRemovingUser(false)
        }, 1000)
      })
    } catch (error) {
      setIsRemovingUser(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "top-right",
      })
    }
  }

  useEffect(() => {
    let hasProducts = false
    incompletedProducts.forEach((product) => {
      product.row.forEach((column) => {
        if (
          column.columnValue === "" ||
          column.columnValue === null ||
          typeof column.columnValue === "undefined"
        ) {
          hasProducts = true
        }
      })
    })

    if (hasProducts) {
      setHasIncompletedProducts(true)
    } else {
      setHasIncompletedProducts(false)
    }
  }, [hasIncompletedProducts, incompletedProducts])

  const handleBack = () => {
    history.push(`/organizations`)
  }

  return (
    <div style={{ position: "relative" }}>
      {hasIncompletedProducts && (
        <IncompleteProductsBanner>
          <Link
            to={{
              state: {
                currentOrg,
                activeTab: 2,
              },
              pathname: ROUTES.ORGS_DETAIL.replace(":id", currentOrg.uid),
            }}
            onClick={() => {
              setActiveTab(2)
              setPendingProductModal(true)
            }}
          >
            {t("incompleted_product_banner")}
          </Link>
        </IncompleteProductsBanner>
      )}
      <Nav>
        <Back>
          <BackIcon isBack={false} onBack={handleBack} />{" "}
          {get(currentOrg, ["name"], "Back")}
        </Back>
        <div>
          {(activeTab === 1 || activeTab === 2) && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Badge
                bg="primary"
                textColor={Theme.color.white.normal}
                onClick={() => setIsModalOpen(true)}
              >
                <span>{t("import_label")}</span>
              </Badge>
            </div>
          )}
        </div>
      </Nav>
      <Container hasIncompletedProducts={hasIncompletedProducts}>
        <TabsContainer>
          <Tab
            textKey="orgs_profile"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
          <Tab
            textKey="orgs_users"
            onTabClick={() => setActiveTab(1)}
            active={activeTab === 1}
          />
          {currentOrg.type !== "business" && (
            <Tab
              textKey="orgs_products"
              onTabClick={() => setActiveTab(2)}
              active={activeTab === 2}
            />
          )}
          {currentOrg.type === "business" && (
            <Tab
              textKey="orgs_doctors"
              onTabClick={() => setActiveTab(4)}
              active={activeTab === 4}
            />
          )}
          <Tab
            textKey="orgs_payments"
            onTabClick={() => setActiveTab(3)}
            active={activeTab === 3}
          />
          <Tab
            textKey="orgs_receipts"
            onTabClick={() => setActiveTab(5)}
            active={activeTab === 5}
          />
        </TabsContainer>
        {activeTab === 0 && Object.keys(profileForm).length > 0 && (
          <OrgInfo user={profileForm} organization={currentOrg} />
        )}
        {activeTab === 1 && Object.keys(profileForm).length > 0 && (
          <OrgUsers
            agents={profileForm.agents}
            orgData={currentOrg}
            orgName={profileForm.businessName}
            onUserCreated={() => getFormsCreate()}
            deleteUser={deleteUser}
            isRemovingUser={isRemovingUser}
            country={currentOrg.country}
          />
        )}
        {activeTab === 2 && Object.keys(profileForm).length > 0 && (
          <OrgProducts products={profileForm.products} orgId={currentOrg} />
        )}
        {activeTab === 3 && Object.keys(profileForm).length > 0 && (
          <OrgPayments
            org={currentOrg}
            orgId={currentOrg.uid}
            addresses={profileForm.addresses}
            orgStripeUser={get(currentOrg, "stripeUserId")}
          />
        )}
        {activeTab === 4 && Object.keys(profileForm).length > 0 && (
          <OrgDoctors
            doctors={profileForm.doctors}
            orgData={currentOrg}
            orgName={profileForm.businessName}
            onUserCreated={() => getFormsCreate()}
            deleteUser={deleteUser}
            isRemovingUser={isRemovingUser}
            country={currentOrg.country}
          />
        )}
        {activeTab === 5 && Object.keys(profileForm).length > 0 && (
          <OrgInvoices orgId={currentOrg.uid} />
        )}
        <div />
      </Container>
      {activeTab !== 5 && (
        <Footer>
          <StatsCard
            number={profileForm.agents.filter((d) => d.data().active).length}
            label={t("orgs_users")}
            handleClick={() => setActiveTab(1)}
          />
          {currentOrg.type === "business" && (
            <StatsCard
              number={profileForm.doctors.length}
              label={t("orgs_doctors")}
              handleClick={() => setActiveTab(4)}
            />
          )}
          {currentOrg.type !== "business" && (
            <StatsCard
              number={profileForm.products.length}
              label="Productos"
              handleClick={() => setActiveTab(2)}
            />
          )}
        </Footer>
      )}
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <Title>{t("import_label")}</Title>
                <Description>{t("import_description_label")}</Description>
                <ButtonsContainer>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false)
                      open()
                    }}
                  >
                    {t("confirm")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {isPendingProductsModalOpen && (
        <CustomModal
          modalOpen={isPendingProductsModalOpen}
          setModalOpen={setPendingProductModal}
          showClose={false}
        >
          <ModalWrapper>
            <ModalOptions h="700px" w="800px" align="flex-start">
              <section>
                <Title>{t("incompleted_product_title")}</Title>
                <Description>{t("incompleted_product_desc")}</Description>
                <IncompleteProductsModalContent>
                  <GenericTable
                    titles={[
                      `${t("country_label")} *`,
                      `${t("lab_name")} *`,
                      `${t("sku_label")} *`,
                      `${t("several_restricted_label")} *`,
                      `${t("prescription_restricted_label")} *`,
                      `${t("composition_label")} *`,
                      `${t("pharmaceutical_presentation_label")} *`,
                      `${t("cie11_label")} *`,
                      `${t("no_cie11")}`,
                      `${t("product_image_url")} *`,
                      `${t("url_to_insert")} *`,
                      `${t("therapeutic_label")} *`,
                      `${t("can_share_product_resource_in_chat")} *`,
                      `${t("can_show_product_result_by_CIE11")} *`,
                    ]}
                    data={incompletedProducts}
                    onChangeHandler={updateIncompleteProduct}
                    getFileUrl={fileUploadHandler}
                    isLoading={isLoadingFile}
                  />
                </IncompleteProductsModalContent>

                <ButtonsContainer>
                  <button
                    type="button"
                    onClick={() => setPendingProductModal(false)}
                  >
                    {t("incompleted_product_cancel_button")}
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      processIncompleteProducts(incompletedProducts)
                    }
                  >
                    {t("incompleted_product_save_button")}
                  </button>
                </ButtonsContainer>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </div>
  )
}

OrgsDetails.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const ComposedUsersDetail = compose(
  withFirebase,
  withAuthorization(condition)
)(OrgsDetails)
export default ComposedUsersDetail
