// globalStyle.js
import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.font.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1 {
    ${(props) => props.theme.font_size.xlarge};
    font-family: ${(props) => props.theme.font.bold};
    
  }

  h2 {
    ${(props) => props.theme.font_size.larger};
  }

  h3 {
    ${(props) => props.theme.font_size.larger};
    font-family: ${(props) => props.theme.font.bold};
  }

  h4 {
    ${(props) => props.theme.font_size.large};
    font-family: ${(props) => props.theme.font.bold};
  }

  h5 {
    ${(props) => props.theme.font_size.xsmall};
    font-family: ${(props) => props.theme.font.primary};
  }
`

export default GlobalStyle
