/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from "react"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"
import get from "lodash.get"
import { compose } from "recompose"
import { withFirebase } from "../../utils/Firebase"
import { AuthUserContext, withAuthorization } from "../../context/Session"
import Nav from "../../components/atoms/Nav"
import CardsContainer from "../../components/atoms/CardsContainer"
import CustomCheckbox from "../../components/atoms/CustomCheckbox"
import OrgCard from "../../components/molecules/OrgCard"
import OrgDrawer from "../../components/molecules/OrgDrawer"
import { countries } from "../../utils/App"

const Organizations = ({ firebase }) => {
  const deleteOrganizationUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
  const authUser = useContext(AuthUserContext)
  const [loadingOrgs, setLoadingOrgs] = useState(true)
  const [orgsList, setOrgsList] = useState([])
  const [filteredOrgsList, setFilteredOrgsList] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [openFilters, setOpenFilters] = useState(false)
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [countryFilters, setCountryFilters] = useState({})
  const [isFilterActive, setIsFilterActive] = useState(false)
  // Using in userCard to remove users
  const [isRemovingOrg, setIsRemovingOrg] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleFilterChange = () => {
    setOpenFilters(!openFilters)
  }

  const handleAddOrganization = () => {
    if (openDrawerCreate) {
      setLoadingOrgs(true)
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }

  useEffect(() => {
    setLoadingOrgs(true)
  }, [])

  const getOrgs = async () => {
    const orgs = authUser.roles.SUPER_ADMIN
      ? await firebase.getOrgs(get(authUser, "organizationId"))
      : await firebase.getOrgs(
          get(authUser, "organizationId"),
          authUser.country
        )
    if (get(authUser, "organizationId")) {
      setOrgsList(orgs ? [{ uid: orgs.id, ...orgs.data() }] : [])
    } else {
      const sortedOrgs =
        orgs.docs && orgs.docs.length > 0
          ? orgs.docs
              .map((d) => ({ uid: d.id, ...d.data() }))
              .sort((a, b) => a.name - b.name)
          : []
      setOrgsList(sortedOrgs)
    }
    setLoadingOrgs(false)
  }

  useEffect(() => {
    if (loadingOrgs && firebase) {
      getOrgs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrgs, firebase, authUser])

  const deleteOrganization = async (org) => {
    try {
      const token = await firebase.getIdToken()
      await fetch(deleteOrganizationUrl, {
        method: "POST",
        body: JSON.stringify({ userToDeleteEmail: org.email }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then(() => {
        setTimeout(() => {
          getOrgs()
          setIsRemovingOrg(true)
          addToast(t("saved_successfully"), {
            appearance: "success",
            placement: "top-right",
          })
          setIsRemovingOrg(false)
        }, 1000)
      })
    } catch (error) {
      setIsRemovingOrg(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "top-right",
      })
    }
  }

  useEffect(() => {
    if (countryFilters) {
      const keys = Object.keys(countryFilters)
      if (keys.length > 0) {
        const values = keys.filter((k) => countryFilters[k].value)
        if (values.length > 0) {
          setIsFilterActive(true)
          const orgs = orgsList.filter((u) => values.includes(u.country))
          setFilteredOrgsList(orgs)
        } else {
          setIsFilterActive(false)
          setFilteredOrgsList(orgsList)
        }
      }
    }
  }, [countryFilters, orgsList])

  const orgs = filteredOrgsList || orgsList

  return (
    <>
      <Nav
        titleKey="orgs_title"
        descriptionKey="orgs_description"
        onSearchChange={handleSearchChange}
        onFilter={
          get(authUser, ["roles", "SUPER_ADMIN"]) ? handleFilterChange : null
        }
        onAddClick={
          get(authUser, ["roles", "SUPER_ADMIN"]) ||
          get(authUser, ["roles", "LOCAL_ADMIN"])
            ? handleAddOrganization
            : null
        }
        searchValue={searchValue}
        addText="create_org"
        showFilterOptions={openFilters}
        filterOptions={() => (
          <FilterOptions>
            {countries.map((c) => (
              <div style={{ width: "100%" }} key={`${c.label}-${c.value}`}>
                <label style={{ display: "flex", cursor: "pointer" }}>
                  <span style={{ minWidth: 150, marginRight: 80 }}>
                    {c.label}
                  </span>
                  <CustomCheckbox
                    checked={
                      countryFilters[c.value] && countryFilters[c.value].value
                    }
                    onChange={() =>
                      setCountryFilters((prev) => ({
                        ...prev,
                        [c.value]: {
                          value: countryFilters[c.value]
                            ? !countryFilters[c.value].value
                            : true,
                        },
                      }))
                    }
                  />
                </label>
                <hr style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} />
              </div>
            ))}
          </FilterOptions>
        )}
        isFilterActive={isFilterActive}
      />
      <Container>
        {get(authUser, ["roles", "SUPER_ADMIN"]) ? (
          countries
            .filter((c) => {
              const keys = Object.keys(countryFilters)
              if (keys.length > 0) {
                const values = keys.filter((k) => countryFilters[k].value)
                if (values.length > 0) {
                  return values.includes(c.value)
                }
                return true
              }
              return true
            })
            .map((c) => {
              return (
                <>
                  <Title>{c.label}</Title>
                  <CardsContainer
                    isGrid
                    isEmpty={orgs && orgs.length === 0}
                    emptyMessage="Aún no tienes organizaciones, crea una nueva."
                    type="organizaciones"
                    loading={loadingOrgs}
                  >
                    {orgs &&
                      orgs
                        .filter((u) => {
                          if (searchValue) {
                            return (
                              u.name &&
                              u.country === c.value &&
                              u.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                          }
                          return u.country === c.value
                        })
                        .map((org) => {
                          return (
                            <OrgCard
                              key={org.uid}
                              org={org}
                              isRemovingOrg={isRemovingOrg}
                              deleteOrganization={deleteOrganization}
                              toActivate={org?.toActivate || false}
                            />
                          )
                        })}
                  </CardsContainer>
                  <Divider />
                </>
              )
            })
        ) : (
          <CardsContainer
            isGrid
            isEmpty={orgs && orgs.length === 0}
            emptyMessage="Aún no tienes organizaciones, crea una nueva."
            type="organizaciones"
            loading={loadingOrgs}
          >
            {orgs &&
              orgs
                .filter((u) => {
                  if (searchValue) {
                    return (
                      u.name &&
                      u.name.toLowerCase().includes(searchValue.toLowerCase())
                    )
                  }
                  return true
                })
                .map((org) => {
                  return (
                    <OrgCard
                      key={org.uid}
                      org={org}
                      isRemovingOrg={isRemovingOrg}
                      deleteOrganization={deleteOrganization}
                      toActivate={org?.toActivate || false}
                    />
                  )
                })}
          </CardsContainer>
        )}
      </Container>
      <OrgDrawer open={openDrawerCreate} closeDrawer={handleAddOrganization} />
    </>
  )
}

const Title = styled.div`
  height: 30px;
  text-align: left;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  letter-spacing: -0.5px;
  text-align: left;
  margin-left: 30px;
  margin-top: 15px;
`

const Divider = styled.hr`
  margin: 0 30px 16px 30px;
  opacity: 0.3;
`

const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

const Container = styled.div`
  overflow-y: auto;
  height: calc(100vh - 180px);
`

Organizations.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompUsers = compose(
  withFirebase,
  withAuthorization(condition)
)(Organizations)
export default CompUsers
