import styled from "styled-components"

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonsContainer = styled.div`
  margin-top: ${(props) => props.mt || "35px"};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    outline: none;
    cursor: pointer;
    color: ${(props) => props.theme.color.onSurface};
    border: none;
    border-radius: 5px;
    width: 125px;
    height: 45px;
    margin: 0 8px;
    ${({ isForm }) =>
      !isForm &&
      `
    background-color: transparent;
  `}
    &:hover {
      color: ${(props) => props.theme.color.black["500"]};
    }
    &:nth-child(2) {
      color: ${(props) => props.theme.color.primary};
      cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
      &:hover {
        color: ${(props) =>
          props.isDisabled
            ? props.theme.color.gray
            : props.theme.color.darkPurple};
      }
    }
  }
`

export const ModalOptions = styled.div`
  align-items: ${(props) => props.align || "center"};
  display: flex;
  justify-content: ${(props) => props.align || "center"};
  width: ${(props) => props.w || "500px"};
  height: ${(props) => props.h || "300px"};
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  & > section {
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
  }
`

export const Title = styled.h3`
  letter-spacing: -0.5 px;
  ${({ m }) =>
    m &&
    `
    margin: ${m};
  `}
`

export const Description = styled.p`
  font-size: 1.125rem;
  text-align: left;
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isColumnDirection ? "column" : "row")};
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 2px;
  min-height: 200px;
  height: auto;
  & > div {
    flex: 1;
  }
`
