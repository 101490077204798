import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Tab = ({ active, textKey, onTabClick, disabled }) => {
  const { t } = useTranslation()
  return (
    <TabContainer
      active={active}
      onClick={disabled ? null : onTabClick}
      disabled={disabled}
    >
      <span>{t(textKey)}</span>
    </TabContainer>
  )
}

Tab.defaultProps = {
  disabled: false,
}

Tab.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  & > span {
    font-size: 1rem;
    text-transform: uppercase;
    color: ${(props) =>
      props.disabled
        ? `${props.theme.color.background.black60}`
        : `${props.theme.color.black.normal}`};
  }
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme.color.onSurface}`
      : "2px solid transparent"};
`

export default Tab
