/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"
import { PaymentContainer, List, FilterContainer } from "./styles"

// Components
import ActionButton from "../../../../components/atoms/ActionButton"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
} from "../../../../components/atoms/Table"
// Utils
import { withFirebase } from "../../../../utils/Firebase"
import { AuthUserContext } from "../../../../context/Session"
import { payoutsGetUrl, payoutsUpdateUrl } from "../../../../utils/App"

import PromotionDrawer from "../../../../components/molecules/PromotionDrawer"
import Nav from "../../../../components/atoms/Nav"

const Promotions = ({ firebase }) => {
  const [loading, setLoading] = useState(false)
  const [promotions, setPromotions] = useState([])
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)

  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const getPromotions = useCallback(async () => {
    try {
      setLoading(true)
      const promotionsData = await firebase.getPromotions()
      setPromotions(promotionsData)
      setLoading(false)
    } catch {
      setLoading(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }, [addToast, firebase, t])

  useEffect(() => {
    if (payoutsGetUrl) {
      getPromotions()
    }
  }, [getPromotions])

  const getStatus = (status) => {
    switch (status) {
      case "active":
        return {
          label: "Activo",
          next: "pending",
        }
      case "expired":
        return {
          label: "Expirada",
          next: "in-progress",
        }
      case "cancelled":
        return {
          label: "Cancelado",
        }
      default:
        return {
          label: "Listo",
        }
    }
  }

  const getType = (type) => {
    switch (type) {
      case "premium":
        return "Premium"
      case "basic":
        return "Básico"
      default:
        return ""
    }
  }

  const onCancelClick = async (id, status) => {
    try {
      await firebase.updatePromotionData({
        promotionId: id,
        promotionData: { state: status },
      })

      getPromotions()
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  const onNewClick = () => {
    if (openDrawerCreate) {
      getPromotions()
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }
  return (
    <>
      <Nav
        titleKey="promotion_administration_description"
        // onSearchChange={handleSearchChange}
        onFilter={null}
        onAddClick={onNewClick}
        searchValue={searchValue}
        addText="create_new"
      />
      <PaymentContainer>
        <List>
          <Table>
            <TableHeader>
              <TableHeaderItem>Generado por</TableHeaderItem>
              <TableHeaderItem>{t("start_date")}</TableHeaderItem>
              <TableHeaderItem>{t("end_date")}</TableHeaderItem>
              <TableHeaderItem>{t("total_label")}</TableHeaderItem>
              <TableHeaderItem>{t("promotion_type_label")}</TableHeaderItem>
              {/* <TableHeaderItem>Descuento</TableHeaderItem> */}
              <TableHeaderItem>Estado</TableHeaderItem>
              {authUser.roles.SUPER_ADMIN && (
                <TableHeaderItem>Acción</TableHeaderItem>
              )}
            </TableHeader>
            {!loading && promotions.length > 0 ? (
              promotions.map((ts) => {
                const localStatus = getStatus(ts.state)
                return (
                  <TableRow key={`payout-${ts.id}`}>
                    <TableRowItem>{ts.created_by}</TableRowItem>
                    <TableRowItem>
                      {moment.unix(ts.start_date).format("DD/MM/YYYY")}
                    </TableRowItem>
                    <TableRowItem>
                      {moment.unix(ts.end_date).format("DD/MM/YYYY")}
                    </TableRowItem>
                    <TableRowItem>{`${parseFloat(ts.new_total).toFixed(
                      2
                    )}`}</TableRowItem>
                    <TableRowItem>{getType(ts.type)}</TableRowItem>
                    {/* <TableRowItem>{`${parseFloat(ts.new_total).toFixed(
                      2
                    )}`}</TableRowItem> */}
                    <TableRowItem>{localStatus.label}</TableRowItem>
                    {authUser.roles.SUPER_ADMIN && (
                      <TableRowItem>
                        <ActionButton
                          disabled={ts.state !== "active"}
                          onActionClick={() =>
                            onCancelClick(ts.id, "cancelled")
                          }
                          dark
                        >
                          <FilterContainer>
                            <span>Cancelar</span>
                          </FilterContainer>
                        </ActionButton>
                      </TableRowItem>
                    )}
                  </TableRow>
                )
              })
            ) : (
              <EmptyOrLoadingContainer>
                {loading ? t("loading") : "No results"}
              </EmptyOrLoadingContainer>
            )}
          </Table>
        </List>
      </PaymentContainer>
      <PromotionDrawer open={openDrawerCreate} closeDrawer={onNewClick} />
    </>
  )
}

Promotions.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Promotions)
