import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { useLocation, Link } from "react-router-dom"
import BackIcon from "../../components/atoms/BackIcon"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
  TableFooter,
} from "../../components/atoms/Table"
import { withAuthorization } from "../../context/Session"
import { withFirebase } from "../../utils/Firebase"

const SessionList = ({ firebase }) => {
  const { t } = useTranslation()
  const {
    state: { type },
  } = useLocation()
  const [sessions, setSessions] = useState([])

  useEffect(() => {
    const getDates = () => {
      switch (type) {
        case "day":
          return {
            start: moment().startOf("day").valueOf(),
            end: moment().endOf("day").valueOf(),
          }
        case "week":
          return {
            start: moment().startOf("week").valueOf(),
            end: moment().endOf("week").valueOf(),
          }
        case "month":
          return {
            start: moment().startOf("month").valueOf(),
            end: moment().endOf("month").valueOf(),
          }
        default:
          return {}
      }
    }
    const getDashboardData = async () => {
      const dates = getDates()
      const originalData = await firebase.getFilteredDashboardData(dates)
      setSessions(
        originalData.docs
          .map((d) => ({ id: d.id, ...d.data() }))
          .sort((a, b) => a.createdAt - b.createdAt)
      )
    }
    if (firebase && type) {
      getDashboardData()
    }
  }, [firebase, type])
  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          Dashboard
        </Back>
      </Nav>
      <Container>
        <Title>{t("users_sessions")}</Title>
        <List>
          <Table>
            <TableHeader>
              <TableHeaderItem>Proveedor</TableHeaderItem>
              <TableHeaderItem>Cliente</TableHeaderItem>
              <TableHeaderItem>Fecha</TableHeaderItem>
              <TableHeaderItem>Total</TableHeaderItem>
              <TableHeaderItem>Estado</TableHeaderItem>
            </TableHeader>
            {sessions.length > 0 ? (
              sessions.map((s) => (
                <TableRow key={s.id}>
                  <TableRowItem>
                    <Link
                      to={{
                        pathname: `users/${s.metadata.providerId}`,
                        state: {
                          currentUser: {
                            id: s.metadata.pproviderId,
                            email: s.metadata.providerEmail,
                            displayName: s.metadata.providerName,
                            userType: "provider",
                            active: true,
                          },
                        },
                      }}
                    >
                      {s.metadata.providerEmail}
                    </Link>
                  </TableRowItem>
                  <TableRowItem>{s.metadata.clientEmail}</TableRowItem>
                  <TableRowItem>
                    {moment(s.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  </TableRowItem>
                  <TableRowItem>{`$${s.metadata.sessionTotal}`}</TableRowItem>
                  <TableRowItem>
                    {s.refunded ? "Donada" : "Finalizada"}
                  </TableRowItem>
                </TableRow>
              ))
            ) : (
              <EmptyOrLoadingContainer>No results</EmptyOrLoadingContainer>
            )}
            <TableFooter>
              <div>
                Total general:{" "}
                {sessions.reduce(
                  (acc, item) => acc + Number(item.metadata.sessionTotal || 0),
                  0
                )}
              </div>
            </TableFooter>
          </Table>
        </List>
      </Container>
    </div>
  )
}

SessionList.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const List = styled.section`
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  margin: 0 30px;
  box-sizing: border-box;
`

const Title = styled.h4`
  padding: 0 30px;
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
  position: relative;
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const condition = (authUser) => !!authUser
const ComposedSessionList = compose(
  withFirebase,
  withAuthorization(condition)
)(SessionList)
export default ComposedSessionList
