import React, { Component } from "react"
import PropTypes from "prop-types"

// import { withFirebase } from '../../utils/Firebase';
import { withAuthorization } from "../../context/Session"

class AdminPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      users: [],
    }
  }

  componentDidMount() {
    const { firebase } = this.props
    this.setState({ loading: true })
    firebase.chats().on("value", (snapshot) => {
      const usersObject = snapshot.val()
      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }))
      this.setState({
        users: usersList,
        loading: false,
      })
    })
  }

  componentWillUnmount() {
    const { firebase } = this.props
    firebase.chats().off()
  }

  render() {
    const { users, loading } = this.state
    return (
      <div>
        <h1>Admin</h1>
        {loading && <div>Loading ...</div>}

        <UserList users={users} />
      </div>
    )
  }
}

AdminPage.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const UserList = ({ users }) => (
  <ul>
    {users.map((user) => (
      <li key={user.uid}>
        <span>
          <strong>ID:</strong> {user.uid}
        </span>
        <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
        <span>
          <strong>Username:</strong> {user.username}
        </span>
      </li>
    ))}
  </ul>
)

UserList.defaultProps = {
  users: [],
}
UserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape),
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(AdminPage)
