/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import isFunction from "lodash.isfunction"
import isObject from "lodash.isobject"
import isEmpty from "lodash.isempty"
import { useTranslation } from "react-i18next"

import ProductImage from "../../molecules/ProductImage"
import CustomSelect from "../CustomSelect"
import Switch from "../Switch"

import { countries, getCountryGenericValue } from "../../../utils/App"

const StyledTable = styled.table`
  caption-side: top;
  border: none;
  border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 5px 10px;

  caption-side: bottom;
  vertical-align: middle;

  td,
  th {
    border: none;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  td {
    padding: 5px 10px;
  }

  tbody tr {
    :nth-of-type(odd) {
      background-color: white;
    }
    :hover {
      background-color: aliceblue;
    }
  }
  thead > tr {
    background-color: white;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`

const Container = styled.div`
  overflow-x: auto;
`

const ITEM_TYPES = {
  TEXT: "text",
  FILE: "file",
  IMAGE: "image",
  BOOLEAN: "boolean",
  SELECT: "select",
}

const emptyControlStyle = {
  border: "1.5px solid red",
}

const noneEmptyControlStyle = {
  border: "none",
}

const GenericTable = ({
  titles,
  data,
  onChangeHandler,
  getFileUrl,
  titleAccessor = undefined,
  isLoading,
}) => {
  const onFileHandler = React.useCallback(
    async (name, config, rowKey, columnIndex) => {
      const fileURL = await getFileUrl(name, config, rowKey, columnIndex)
      if (fileURL) {
        onChangeHandler(
          {
            value: fileURL,
            columnIndex,
          },
          rowKey
        )
      }
    },
    [getFileUrl, onChangeHandler]
  )

  const { t } = useTranslation()
  const renderTableCell = React.useCallback(
    (dataItem) => {
      const { row, rowKey } = dataItem

      const itemRow = row.map(
        ({ columnValue, columnType, columnKey, columnIndex, columnName }) => {
          if (columnType === ITEM_TYPES.BOOLEAN) {
            return (
              <td
                key={columnKey}
                style={
                  typeof columnValue !== "boolean" && isEmpty(columnValue)
                    ? emptyControlStyle
                    : noneEmptyControlStyle
                }
              >
                <Switch
                  value={columnValue}
                  checked={columnValue}
                  id={`${columnKey}-${columnIndex}`}
                  onChange={(event) =>
                    onChangeHandler(
                      {
                        value: event.target.checked,
                        columnIndex,
                      },
                      rowKey
                    )
                  }
                />
              </td>
            )
          }

          if (columnType === ITEM_TYPES.IMAGE) {
            return (
              <td key={columnKey}>
                <ProductImage
                  emptyMessage={`${t("upload_file_button")} *`}
                  text={`${t("upload_file_button")} *`}
                  width={100}
                  height={100}
                  src={columnValue}
                  error={!columnValue}
                  fieldName={columnName}
                  isLoading={isLoading === `${rowKey}-${columnIndex}`}
                  onImageLoaded={(name, config) =>
                    onFileHandler(name, config, rowKey, columnIndex)
                  }
                />
              </td>
            )
          }

          if (columnType === ITEM_TYPES.FILE) {
            return (
              <td key={columnKey}>
                <ProductImage
                  srcType={["image/*", "application/pdf"]}
                  emptyMessage={`${t("upload_file_button")} *`}
                  text={`${t("upload_file_button")} *`}
                  width={100}
                  height={100}
                  src={columnValue}
                  error={!columnValue}
                  fieldName={columnName}
                  isLoading={isLoading === `${rowKey}-${columnIndex}`}
                  onImageLoaded={(name, config) =>
                    onFileHandler(name, config, rowKey, columnIndex)
                  }
                />
              </td>
            )
          }

          if (columnType === ITEM_TYPES.SELECT) {
            return (
              <td
                key={columnKey}
                style={
                  isEmpty(columnValue)
                    ? emptyControlStyle
                    : noneEmptyControlStyle
                }
              >
                <CustomSelect
                  value={columnValue}
                  formKey="country"
                  lngKey="country_label"
                  getDefaultValue={getCountryGenericValue}
                  handleChange={(_field, value) => {
                    onChangeHandler(
                      {
                        value,
                        columnIndex,
                      },
                      rowKey
                    )
                  }}
                  items={countries}
                />
              </td>
            )
          }

          return (
            <td key={columnKey}>
              <input
                type="text"
                value={columnValue}
                style={
                  isEmpty(columnValue)
                    ? emptyControlStyle
                    : noneEmptyControlStyle
                }
                onChange={(event) =>
                  onChangeHandler(
                    {
                      value: event.target.value,
                      columnIndex,
                    },
                    rowKey
                  )
                }
              />
            </td>
          )
        }
      )

      return <tr key={rowKey}>{itemRow}</tr>
    },
    [onChangeHandler, onFileHandler, t, isLoading]
  )

  const getTableHeaderWidth = (headerIndex) =>
    headerIndex === 0 ? "130px" : "100%"

  const renderTableHeader = React.useCallback(
    (headerItem, headerIndex) => {
      if (isFunction(headerItem)) {
        return (
          <th
            style={{ minWidth: getTableHeaderWidth(headerIndex) }}
            key={headerIndex}
          >
            {headerItem()}
          </th>
        )
      }

      if (isObject(headerItem)) {
        return (
          <th
            style={{ minWidth: getTableHeaderWidth(headerIndex) }}
            key={headerIndex}
          >
            {headerItem[titleAccessor]}
          </th>
        )
      }

      if (typeof headerItem === "string") {
        return (
          <th
            style={{ minWidth: getTableHeaderWidth(headerIndex) }}
            key={headerIndex}
          >
            {headerItem}
          </th>
        )
      }
      return null
    },
    [titleAccessor]
  )

  return (
    <Container>
      <StyledTable>
        <thead>
          <tr>{titles.map(renderTableHeader)}</tr>
        </thead>
        <tbody>{data.map(renderTableCell)}</tbody>
      </StyledTable>
    </Container>
  )
}

GenericTable.propTypes = {
  titles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape),
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  titleAccessor: PropTypes.string,
  onChangeHandler: PropTypes.func,
  getFileUrl: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rowKey: PropTypes.string.isRequired,
      row: PropTypes.arrayOf(
        PropTypes.exact({
          columnValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
          ]),
          columnName: PropTypes.string.isRequired,
          columnType: PropTypes.oneOf([
            "text",
            "file",
            "image",
            "boolean",
            "select",
          ]).isRequired,
          columnKey: PropTypes.string.isRequired,
          columnIndex: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
}

export default GenericTable
