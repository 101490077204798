import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { compose } from "recompose"
import get from "lodash.get"
import capitalize from "lodash.capitalize"
import { useTranslation } from "react-i18next"
import Nav from "../../components/atoms/Nav"
import Tab from "../../components/atoms/Tab"
import * as ROLES from "../../utils/Constants/Roles"
import CardsContainer from "../../components/atoms/CardsContainer"
import UserCard from "../../components/molecules/UserCard"
import DashboardData from "../../components/organisms/DashboarData"
import { withFirebase } from "../../utils/Firebase"
import { AuthUserContext, withAuthorization } from "../../context/Session"

const Dashboard = ({ firebase }) => {
  const authUser = useContext(AuthUserContext)
  const [activeTab, setActiveTab] = useState(0)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [usersList, setUsersList] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setLoadingUsers(true)
  }, [activeTab])

  useEffect(() => {
    const getUsers = async () => {
      const users = get(authUser, ["roles", "SUPER_ADMIN"])
        ? await firebase.getUsersByType("provider")
        : await firebase.getUsersByType("provider", authUser.country)
      const sortedUsers =
        users.docs && users.docs.length > 0
          ? users.docs
              .map((d) => {
                const userData = d.data()
                return {
                  id: d.id,
                  ...userData,
                  displayName: userData.displayName
                    ? userData.displayName
                    : `${capitalize(userData.name)} ${capitalize(
                        userData.surname1
                      )}`,
                }
              })
              .sort((a, b) => a.displayName - b.displayName)
          : []
      setUsersList(sortedUsers)
      setLoadingUsers(false)
    }
    if (loadingUsers && firebase && authUser) {
      getUsers()
    }
  }, [loadingUsers, activeTab, firebase, authUser])

  return (
    <>
      <Nav titleKey="dashboard_title" descriptionKey="dashboard_description" />
      <div>
        <TabsContainer>
          <Tab
            textKey="admnistration"
            onTabClick={() => setActiveTab(0)}
            active={activeTab === 0}
          />
          <Tab
            textKey="visualization"
            onTabClick={() => setActiveTab(1)}
            active={activeTab === 1}
          />
        </TabsContainer>
        {usersList.filter((u) => u.status === "inactive").length > 0 &&
          activeTab === 0 &&
          !loadingUsers && (
            <>
              <Title>
                {t("new_users", {
                  type: t("users_providers"),
                })}{" "}
                ({usersList.filter((u) => u.status === "inactive").length})
              </Title>
              <CardsContainer isEmpty={false} isHorizontal hasTitle>
                {usersList
                  .filter((u) => u.status === "inactive")
                  .map((user) => {
                    return <UserCard key={user.id} user={user} toActivate />
                  })}
              </CardsContainer>
              <Divider />
            </>
          )}
        {activeTab === 1 && (
          <>
            <DashboardData />
          </>
        )}
      </div>
    </>
  )
}

const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Title = styled.div`
  height: 40px;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: -0.5px;
  text-align: left;
  margin-left: 30px;
  margin-top: 22px;
`

const Divider = styled.hr`
  margin: 0 30px 16px 30px;
  opacity: 0.3;
`

Dashboard.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) =>
  !!authUser &&
  authUser.userType === "admin" &&
  (authUser.roles[ROLES.SUPER_ADMIN] || authUser.roles[ROLES.LOCAL_ADMIN])
const CompDashboard = compose(
  withFirebase,
  withAuthorization(condition)
)(Dashboard)
export default CompDashboard
