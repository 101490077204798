import styled from "styled-components"
import { rem } from "polished"

export const Title = styled.div`
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.black["300"]};
`

export const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
`

export const Info = styled.div`
  position: relative;
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-top: 15px;
  & > p {
    margin: 0;
    margin-top: 5px;
  }
`

export const Label = styled.div`
  position: relative;
  margin-top: 15px;
  & > span {
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 1.5px;
    text-align: left;
    color: ${(props) => props.theme.color.black["300"]};
    text-transform: uppercase;
  }
`

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 32px;
  background: ${(props) =>
    props.donated ? props.theme.color.semiPalePurple : "#ffffff"};
  border-radius: 100px;
  right: 0;
  top: 5px;
  padding: 0 12px;
  border: 1px solid #f2f2f2;
  border-radius: 100px;
  box-sizing: border-box;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
      color: red;
    }
  }
`

export const ClientCard = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Initials = styled.p`
  margin: 0;
`

export const Avatar = styled.div`
  border: 2px solid #000;
  border-radius: 50%;
  transition: all ease-in-out 100ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  & > ${Initials} {
    font-size: ${(props) => `calc(${rem(props.w)} / 2.5)`};
  }
  margin-right: 8px;
`
