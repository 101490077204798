import React, { useEffect, useState } from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import get from "lodash.get"
import { compose } from "recompose"
import { useLocation, useParams } from "react-router-dom"
import BackIcon from "../../components/atoms/BackIcon"
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableRow,
  TableRowItem,
  EmptyOrLoadingContainer,
  TableFooter,
} from "../../components/atoms/Table"
import { withAuthorization } from "../../context/Session"

const PaymentDetail = () => {
  const {
    state: { transactions, isSuperAdmin },
  } = useLocation()
  const { id } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    if (transactions && id) {
      setData(
        isSuperAdmin
          ? transactions.filter((t) => t.providerCountry === id)
          : transactions.filter((t) => t.providerEmail === id)
      )
    }
  }, [transactions, isSuperAdmin, id])

  const sessionsTotal = data.reduce(
    (acc, item) => acc + parseFloat(item.localSessionTotal || 0),
    0
  )

  const donations = data
    .filter((d) => !d.cancelled)
    .reduce(
      (acc, item) =>
        acc +
        parseFloat(get(item, ["refunds", "local_amount_refunded"], 0) || 0),
      0
    )

  const fees = data
    .filter((d) => !d.cancelled)
    .reduce((acc, item) => acc + parseFloat(item.localSessionFee || 0), 0)

  const granTotal = sessionsTotal - fees - donations

  return (
    <div style={{ position: "relative" }}>
      <Nav>
        <Back>
          <BackIcon />
          Pagos
        </Back>
      </Nav>
      <Container>
        <Title>Detalle Transacciones</Title>
        <List>
          <Table>
            <TableHeader>
              {isSuperAdmin && <TableHeaderItem>Banco</TableHeaderItem>}
              <TableHeaderItem>Cliente</TableHeaderItem>
              <TableHeaderItem>Fecha</TableHeaderItem>
              <TableHeaderItem>Precio</TableHeaderItem>
              <TableHeaderItem>Comisión</TableHeaderItem>
              <TableHeaderItem>Deducciones</TableHeaderItem>
              <TableHeaderItem>Total</TableHeaderItem>
              <TableHeaderItem>Estado</TableHeaderItem>
            </TableHeader>
            {data.length > 0 ? (
              data.map((s) => (
                <TableRow key={s.id}>
                  {isSuperAdmin && (
                    <TableRowItem>{s.providerBankName}</TableRowItem>
                  )}
                  <TableRowItem>{s.clientEmail}</TableRowItem>
                  <TableRowItem>
                    {moment(s.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  </TableRowItem>
                  <TableRowItem>{`${parseFloat(s.localSessionPrice).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(s.localSessionFee).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>{`${get(
                    s,
                    ["refunds", "local_amount_refunded"],
                    0
                  ).toFixed(2)}`}</TableRowItem>
                  <TableRowItem>{`${parseFloat(s.payoutTotal).toFixed(
                    2
                  )}`}</TableRowItem>
                  <TableRowItem>
                    {s.cancelled ? "Cancelada" : "Finalizada"}
                  </TableRowItem>
                </TableRow>
              ))
            ) : (
              <EmptyOrLoadingContainer>Sin Resultados</EmptyOrLoadingContainer>
            )}
            <TableFooter>
              <div>{`Total general: ${sessionsTotal}`}</div>
              <div>{`Comisiones: ${fees}`}</div>
              <div>{`Donaciones: ${donations}`}</div>
              <div>{`A pagar: ${granTotal.toFixed(2)}`}</div>
            </TableFooter>
          </Table>
        </List>
      </Container>
    </div>
  )
}

PaymentDetail.propTypes = {}

const List = styled.section`
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  margin: 0 30px;
  box-sizing: border-box;
`

const Title = styled.h4`
  padding: 0 30px;
`

const Nav = styled.section`
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Container = styled.section`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
  position: relative;
`

const Back = styled.span`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const condition = (authUser) => !!authUser
const ComposedPaymentDetail = compose(withAuthorization(condition))(
  PaymentDetail
)
export default ComposedPaymentDetail
