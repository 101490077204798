/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from "react"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import get from "lodash.get"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import capitalize from "lodash.capitalize"
import Nav from "../../components/atoms/Nav"
import Tab from "../../components/atoms/Tab"
import Badge from "../../components/atoms/Badge"
import CardsContainer from "../../components/atoms/CardsContainer"
import CustomCheckbox from "../../components/atoms/CustomCheckbox"
import UserCard from "../../components/molecules/UserCard"
import AdminDrawer from "../../components/molecules/AdminDrawer"
import * as ROLES from "../../utils/Constants/Roles"
import { withFirebase } from "../../utils/Firebase"
import { countries } from "../../utils/App"
import { AuthUserContext, withAuthorization } from "../../context/Session"

const Users = ({ firebase }) => {
  const deleteUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
  const { state } = useLocation()
  const authUser = useContext(AuthUserContext)
  const [activeTab, setActiveTab] = useState(
    get(state, "activeTab", authUser.roles.SUPER_ADMIN ? 0 : 1)
  )
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [usersList, setUsersList] = useState([])
  const [filteredUsersList, setFilteredUsersList] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [openFilters, setOpenFilters] = useState(false)
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [countryFilters, setCountryFilters] = useState({})
  const [isFilterActive, setIsFilterActive] = useState(false)

  // Using in userCard to remove users
  const [isRemovingUser, setIsRemovingUser] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleFilterChange = () => {
    setOpenFilters(!openFilters)
  }

  const handleAddAdministrator = () => {
    if (openDrawerCreate) {
      setLoadingUsers(true)
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }

  useEffect(() => {
    setLoadingUsers(true)
  }, [activeTab])

  const getUsers = async () => {
    try {
      const type =
        // eslint-disable-next-line no-nested-ternary
        activeTab === 0 ? "admin" : activeTab === 1 ? "provider" : "client"
      const users = authUser.roles.SUPER_ADMIN
        ? await firebase.getUsersByType(type)
        : await firebase.getUsersByType(
            type,
            activeTab === 1 ? authUser.country : null
          )
      const generalUsers = await firebase.getNoCountryUsers(type)
      const allUsers =
        generalUsers.length > 0 ? [...users.docs, ...generalUsers] : users.docs
      const sortedUsers =
        allUsers && allUsers.length > 0
          ? allUsers
              .map((d) => {
                const data = d.countryBusiness === "Others" ? d : d.data()
                return { id: d.id, ...data }
              })
              .sort((a, b) => a.displayName - b.displayName)
          : []
      const filterByCountry = authUser.roles.SUPER_ADMIN
        ? sortedUsers
        : sortedUsers.filter(
            (user) => user.countryBusiness === authUser.country
          )
      setUsersList(filterByCountry)
      setLoadingUsers(false)
    } catch (error) {
      setLoadingUsers(false)
    }
  }

  useEffect(() => {
    if (loadingUsers && firebase && authUser) {
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUsers, activeTab, firebase, authUser, isRemovingUser])

  const deleteUser = async (user) => {
    try {
      const token = await firebase.getIdToken()
      await fetch(deleteUserUrl, {
        method: "POST",
        body: JSON.stringify({ userToDeleteEmail: user.email }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then(() => {
        setTimeout(() => {
          getUsers()
          setIsRemovingUser(true)
          addToast(t("saved_successfully"), {
            appearance: "success",
            placement: "top-right",
          })
          setIsRemovingUser(false)
        }, 1000)
      })
    } catch (error) {
      setIsRemovingUser(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "top-right",
      })
    }
  }

  useEffect(() => {
    if (countryFilters) {
      const keys = Object.keys(countryFilters)
      if (keys.length > 0) {
        const values = keys.filter((k) => countryFilters[k].value)
        if (values.length > 0) {
          setIsFilterActive(true)
          const users = usersList.filter((u) =>
            values.includes(u.countryBusiness)
          )
          setFilteredUsersList(users)
        } else {
          setIsFilterActive(false)
          setFilteredUsersList(usersList)
        }
      }
    }
  }, [countryFilters, usersList])

  let users = filteredUsersList || usersList
  users = users.map((u) => ({
    ...u,
    displayName: u.displayName
      ? u.displayName
      : `${capitalize(u.name)} ${capitalize(u.surname1)}`,
  }))

  return (
    <>
      <Nav
        titleKey="users_title"
        descriptionKey="users_description"
        onSearchChange={handleSearchChange}
        onFilter={
          get(authUser, ["roles", "SUPER_ADMIN"]) ? handleFilterChange : null
        }
        onAddClick={
          activeTab === 0 && get(authUser, ["roles", "SUPER_ADMIN"])
            ? handleAddAdministrator
            : null
        }
        addText="create_administrator"
        searchValue={searchValue}
        showFilterOptions={openFilters}
        filterOptions={() => (
          <FilterOptions>
            {countries.map((c) => (
              <div style={{ width: "100%" }} key={`${c.label}-${c.value}`}>
                <label style={{ display: "flex", cursor: "pointer" }}>
                  <span style={{ minWidth: 150, marginRight: 80 }}>
                    {c.label}
                  </span>
                  <CustomCheckbox
                    checked={
                      countryFilters[c.value] && countryFilters[c.value].value
                    }
                    onChange={() =>
                      setCountryFilters((prev) => ({
                        ...prev,
                        [c.value]: {
                          value: countryFilters[c.value]
                            ? !countryFilters[c.value].value
                            : true,
                        },
                      }))
                    }
                  />
                </label>
                <hr />
              </div>
            ))}
          </FilterOptions>
        )}
        isFilterActive={isFilterActive}
      />
      <div>
        <TabsContainer>
          {authUser.roles.SUPER_ADMIN && (
            <Tab
              textKey="users_admin"
              onTabClick={() => setActiveTab(0)}
              active={activeTab === 0}
            />
          )}
          <Tab
            textKey="users_providers"
            onTabClick={() => setActiveTab(1)}
            active={activeTab === 1}
          />
          <Tab
            textKey="users_clients"
            onTabClick={() => setActiveTab(2)}
            active={activeTab === 2}
          />
        </TabsContainer>
        <Container>
          {users.filter((u) => u.status === "inactive").length > 0 && // Providers to activate
            activeTab === 1 &&
            !loadingUsers && (
              <>
                <Badge
                  position="relative"
                  width="100px"
                  margin="10px 30px 0px 30px"
                >
                  <span>
                    {t("all", {
                      quantity: users.filter((u) => u.status === "inactive")
                        .length,
                    })}
                  </span>
                </Badge>
                {get(authUser, ["roles", "SUPER_ADMIN"]) ? (
                  countries
                    .filter((c) => {
                      const keys = Object.keys(countryFilters)
                      if (keys.length > 0) {
                        const values = keys.filter(
                          (k) => countryFilters[k].value
                        )
                        if (values.length > 0) {
                          return values.includes(c.value)
                        }
                        return true
                      }
                      return true
                    })
                    .map((c) => {
                      const currentUsers = users.filter(
                        (u) =>
                          u.status === "inactive" &&
                          u.countryBusiness === c.value
                      )
                      return (
                        <div key={`${c.label}-${c.value}`}>
                          <Title>{`Nuevos Proveedores - ${c.label} (${currentUsers.length})`}</Title>
                          {currentUsers.length > 0 && (
                            <CardsContainer
                              isEmpty={false}
                              isHorizontal
                              hasTitle
                            >
                              {users
                                .filter((u) => {
                                  if (searchValue) {
                                    return (
                                      u.status === "inactive" &&
                                      u.countryBusiness === c.value &&
                                      u.displayName &&
                                      u.displayName
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                  }
                                  return (
                                    u.status === "inactive" &&
                                    u.countryBusiness === c.value
                                  )
                                })
                                .map((userData) => {
                                  return (
                                    <UserCard
                                      key={userData.id}
                                      user={userData}
                                      deleteUser={deleteUser}
                                      isRemovingUser={isRemovingUser}
                                      toActivate
                                    />
                                  )
                                })}
                            </CardsContainer>
                          )}
                          <Divider />
                        </div>
                      )
                    })
                ) : (
                  <CardsContainer isEmpty={false} isHorizontal hasTitle>
                    {users
                      .filter((u) => {
                        if (searchValue) {
                          return (
                            u.status === "inactive" &&
                            u.displayName &&
                            u.displayName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                        }
                        return u.status === "inactive"
                      })
                      .map((userData) => {
                        return (
                          <UserCard
                            key={userData.id}
                            user={userData}
                            deleteUser={deleteUser}
                            isRemovingUser={isRemovingUser}
                            toActivate
                          />
                        )
                      })}
                  </CardsContainer>
                )}
                <Divider />
              </>
            )}
          {get(authUser, ["roles", "SUPER_ADMIN"]) ? (
            countries
              .filter((c) => {
                const keys = Object.keys(countryFilters)
                if (keys.length > 0) {
                  const values = keys.filter((k) => countryFilters[k].value)
                  if (values.length > 0) {
                    return values.includes(c.value)
                  }
                  return true
                }
                return true
              })
              .map((c) => {
                return (
                  <div key={`${c.label}-${c.value}`}>
                    <Title>{c.label}</Title>
                    {users.filter(
                      (u) =>
                        u.status === "active" && u.countryBusiness === c.value
                    ).length > 0 && (
                      <CardsContainer
                        isGrid={activeTab !== 1}
                        isEmpty={
                          users.filter(
                            (u) =>
                              activeTab === 1
                                ? u.status === "active" &&
                                  u.countryBusiness === c.value
                                : u.countryBusiness === c.value && true // Providers already activated
                          ).length === 0
                        }
                        isHorizontal={
                          activeTab === 1 &&
                          users.filter(
                            (u) =>
                              u.countryBusiness === c.value &&
                              u.status === "inactive"
                          ).length > 0
                        }
                        loading={loadingUsers}
                      >
                        {users
                          .filter((data) => {
                            if (searchValue) {
                              return activeTab === 1
                                ? data.status === "active" &&
                                    data.countryBusiness === c.value &&
                                    data.displayName &&
                                    data.displayName
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                                : data.displayName &&
                                    data.countryBusiness === c.value &&
                                    data.displayName
                                      .toLowerCase()
                                      .includes(searchValue.toLowerCase())
                            }
                            return activeTab === 1
                              ? data.status === "active" &&
                                  data.countryBusiness === c.value
                              : data.countryBusiness === c.value
                          })
                          .map((userData) => (
                            <UserCard
                              key={userData.id}
                              user={userData}
                              deleteUser={deleteUser}
                              isRemovingUser={isRemovingUser}
                            />
                          ))}
                      </CardsContainer>
                    )}
                    <Divider />
                  </div>
                )
              })
          ) : (
            <CardsContainer
              isGrid={activeTab !== 1}
              isEmpty={
                users.filter(
                  (u) => (activeTab === 1 ? u.status === "active" : true) // Providers already activated
                ).length === 0
              }
              isHorizontal={
                activeTab === 1 &&
                users.filter((u) => u.status === "inactive").length > 0
              }
              loading={loadingUsers}
            >
              {users
                .filter((data) => {
                  if (searchValue) {
                    return activeTab === 1
                      ? data.status === "active" &&
                          data.displayName &&
                          data.displayName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                      : data.displayName &&
                          data.displayName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                  }
                  return activeTab === 1 ? data.status === "active" : true
                })
                .map((userData) => (
                  <UserCard
                    key={userData.id}
                    user={userData}
                    deleteUser={deleteUser}
                    isRemovingUser={isRemovingUser}
                  />
                ))}
            </CardsContainer>
          )}
        </Container>
      </div>
      <AdminDrawer
        open={openDrawerCreate}
        closeDrawer={handleAddAdministrator}
      />
    </>
  )
}

export const Container = styled.div`
  overflow-y: auto;
  height: calc(100vh - 180px);
  position: relative;
`

export const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

const TabsContainer = styled.section`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 1;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const Title = styled.div`
  height: 30px;
  text-align: left;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  letter-spacing: -0.5px;
  text-align: left;
  margin-left: 30px;
  margin-top: 15px;
`

const Divider = styled.hr`
  margin: 0 30px 16px 30px;
  opacity: 0.3;
`

Users.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) =>
  !!authUser &&
  authUser.userType === "admin" &&
  (authUser.roles[ROLES.SUPER_ADMIN] || authUser.roles[ROLES.LOCAL_ADMIN])
const CompUsers = compose(withFirebase, withAuthorization(condition))(Users)
export default CompUsers
