import React from "react"

const ClipboardIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6875 0C4.47938 0 3.5 0.979377 3.5 2.1875V8.3125C3.5 9.52062 4.47938 10.5 5.6875 10.5H11.8125C13.0206 10.5 14 9.52062 14 8.3125V2.1875C14 0.979377 13.0206 0 11.8125 0H5.6875ZM5.25 2.1875C5.25 1.94588 5.44588 1.75 5.6875 1.75H11.8125C12.0541 1.75 12.25 1.94588 12.25 2.1875V8.3125C12.25 8.55412 12.0541 8.75 11.8125 8.75H5.6875C5.44588 8.75 5.25 8.55412 5.25 8.3125V2.1875Z"
        fill="black"
      />
      <path
        d="M10.5 11.375H8.75V11.8125C8.75 12.0541 8.55412 12.25 8.3125 12.25H2.1875C1.94588 12.25 1.75 12.0541 1.75 11.8125V5.6875C1.75 5.44588 1.94588 5.25 2.1875 5.25H2.625V3.5H2.1875C0.979377 3.5 0 4.47938 0 5.6875V11.8125C0 13.0206 0.979377 14 2.1875 14H8.3125C9.52062 14 10.5 13.0206 10.5 11.8125V11.375Z"
        fill="black"
      />
    </svg>
  )
}

export default ClipboardIcon
