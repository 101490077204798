/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const PaymentContainer = styled.div`
  margin: 20px 20px 0 20px;
  height: calc(100vh - 165px);
  overflow-y: scroll;
`

export const Title = styled.div`
  height: 40px;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: -0.5px;
  margin: 2px 0 10px 10px;
`

export const List = styled.section`
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 245px);
  margin: 0 10px;
  box-sizing: border-box;
`

export const FilterContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`
