/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { withFirebase } from "../../../utils/Firebase"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import { StyledForm, SubmitButton } from "./styles"
import { AuthUserContext } from "../../../context/Session"

const promotionTypes = [
  { value: "premium", label: "Premium" },
  { value: "basic", label: "Básico" },
]

const PromotionDrawer = ({ firebase, open, closeDrawer }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isCreating, setIsCreating] = useState(false)
  const authUser = useContext(AuthUserContext)
  const [promotionType, setPromotionType] = useState(undefined)

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_promotion")}
      internal
    >
      <div>
        <Formik
          initialValues={{
            created_by: undefined,
            end_date: undefined,
            new_total: undefined,
            start_date: undefined,
            state: "active",
            type: "basic",
          }}
          onSubmit={async (values, { resetForm }) => {
            setIsCreating(true)
            try {
              await firebase.savePromotion({
                promotionData: {
                  created_by: authUser.email,
                  start_date: moment(values.start_date).unix(),
                  end_date: moment(values.end_date).unix(),
                  state: values.state,
                  new_total: values.new_total,
                  type: values.type,
                },
              })

              addToast(t("saved_successfully"), {
                appearance: "success",
                placement: "bottom-center",
              })
              resetForm({ value: "" })
              setPromotionType(undefined)
              setIsCreating(false)
              closeDrawer()
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            type: Yup.string().required(t("error_all_fields_required")),
            start_date: Yup.date().required(t("error_all_fields_required")),
            end_date: Yup.date().required(t("error_all_fields_required")),
            new_total: Yup.number().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <p>Ingrese los datos de la nueva promoción.</p>
              <CustomSelect
                value={promotionType}
                formKey="type"
                lngKey="promotion_type"
                handleChange={(event, value) => {
                  props.setFieldValue("type", value)
                  setPromotionType(
                    promotionTypes.filter((p) => p.value === value)
                  )
                }}
                items={promotionTypes}
                showError={!props.values.type && Boolean(props.errors.type)}
              />
              <CustomInput
                touched={props.touched.start_date}
                errors={props.errors.start_date}
                icon="date"
                value={props.values.start_date}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("start_date")}
                type="date"
                id="start_date"
                name="start_date"
              />
              <CustomInput
                touched={props.touched.end_date}
                errors={props.errors.end_date}
                icon="date"
                value={props.values.end_date}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("end_date")}
                type="date"
                id="end_date"
                name="end_date"
              />
              <CustomInput
                touched={props.touched.new_total}
                errors={props.errors.new_total}
                icon="money"
                value={props.values.new_total}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("total_label")}
                type="number"
                id="new_total"
                name="new_total"
              />
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

PromotionDrawer.defaultProps = {
  open: false,
}

PromotionDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
}
export default withFirebase(PromotionDrawer)
