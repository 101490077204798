import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const Container = styled.div`
  position: relative;
  height: calc(100% - 56px);
`

export const PaymentsHeader = styled.div`
  width: 100%;
  height: 104px;
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border: 1px solid #f2f2f2;
  align-self: stretch;
  flex-grow: 0;
  margin: 22px 0px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 215px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  padding-top: 15px;
  overflow-y: auto;
  box-sizing: border-box;
  & > div {
    margin-bottom: 10px;
  }
  filter: ${(props) => (props.isDeleting ? "blur(2px)" : "unset")};
`

export const StyledForm = styled.form`
  height: ${(props) => (props.isVisible ? "690px" : "100%")};
  width: 100%;
  display: flex;
  flex-direction: column;
  & > section {
    display: flex;
    div {
      flex: 1;
      & .rccs {
        margin: 0;
        & .rccs__chip {
          display: none;
        }
      }
    }
  }
`

export const AddButton = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "48px")};
  height: ${(props) => (props.height ? props.height : "48px")};
  background: #edf2f7;
  border-radius: 100px;
  flex-grow: 0;
  margin: 0px 12px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
  & > span {
    font-size: 2rem;
    color: #323232;
    padding-bottom: 2px;
    padding-left: 1px;
  }
`

export const SubmitButton = styled(ButtonWithLoader)``

export const DoubleInputWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 5px;
  /* Space between items */
  grid-template-columns: 1fr 1fr;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isDouble ? "row" : "column")};
  height: 90px;
`

export const CardInput = styled.input`
  flex: 1;
  height: 56px;
  max-height: 60px;
  border: 1px solid #a0aec0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 22px;
`

export const ErrorMessage = styled.div`
  padding-top: 3px;
  color: ${(props) => props.theme.color.error};
`

export const BillingSection = styled.section`
  border-top: 1px solid #a0aec0;
  border-bottom: 1px solid #a0aec0;
  justify-content: space-between;
  align-items: center;
`
export const BillingContainer = styled.section`
  transition: ${(props) =>
    props.isVisible ? "all 300ms ease-in-out" : "none"};
  height: ${(props) => (props.isVisible ? "40vh" : "0px")};
  min-height: ${(props) => (props.isVisible ? "350px" : "0px")};
  overflow: hidden;
  flex-direction: column;
`

export const BillingAddres = styled.section`
  border-top: 1px solid #a0aec0;
  border-bottom: 1px solid #a0aec0;
  display: flex;
  flex-direction: column;
  padding: 8px;
`

export const BillingTitle = styled.p`
  font-size: 1rem;
  color: #000000;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`

export const BillingText = styled.span`
  color: #888888;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0em;
  text-align: left;
`
