/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import isNumber from "lodash.isnumber"
import isEmpty from "lodash.isempty"
import { useToasts } from "react-toast-notifications"
import { compose } from "recompose"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"

import { Percent } from "@styled-icons/fa-solid/Percent"
import { AttachMoney } from "@styled-icons/material/AttachMoney"
import { UserCheck } from "@styled-icons/fa-solid/UserCheck"

import { withFirebase } from "../../../../utils/Firebase"
import { withAuthorization } from "../../../../context/Session"
import * as ROLES from "../../../../utils/Constants/Roles"
import { cleanNotNumbersAcceptDot } from "../../../../utils/cleanNotNumbers"

import { updateAllProvidersCharges } from "../../../../utils/App"

// Components
import RoundGrayInput from "../../../../components/atoms/RoundGrayInput"
import CustomInput from "../../../../components/atoms/CustomInput"
import SearchInput from "../../../../components/atoms/SearchInput"
import CustomModal from "../../../../components/atoms/CustomModal"
import Spinner from "../../../../components/atoms/Spinner"

// Styles
import {
  StyledForm,
  Title,
  SubmitButton,
  FormContainer,
  ChargesTitleContainer,
  Subtitles,
  SubtitlesII,
  Line,
  SectionTitle,
  InputContainer,
  CountryLine,
  TitleContainer,
  SearchContainer,
  ProviderChargesStyledForm,
  ChargesLine,
  PutChargesToAll,
  ModalContainer,
  RegularFont,
  ActionButton,
  ApplyToAllButton,
} from "./styles"
import { SpreadContainer, RowContainer, SubTitle } from "../../styles"

const Charges = ({ firebase }) => {
  const { t } = useTranslation()
  const formRef = useRef()
  const { addToast } = useToasts()

  const [isSaving, setIsSaving] = useState(false)
  const [loadingPricing, setLoadingPricing] = useState(false)
  const [pricing, setPricing] = useState({})
  const [countries, setCountries] = useState({})
  const [initialCountries, setInitialCountries] = useState({})
  const [stripeSpreadFromDB, setStripeSpread] = useState(0)
  const [exhangeSpreadFromDB, setExchangeSpread] = useState(0)
  const [exchanges, setExchanges] = useState({})
  const [countrySearchValue, setCountrySearchValue] = useState("")
  const [providerChargesState, setProviderCharges] = useState({
    firstYear: 0,
    secondYear: 0,
    restOfTime: 0,
    basicFirstYear: 0,
    basicSecondYear: 0,
    basicRestOfTime: 0,
  })
  const [businessCharges, setBusinessCharges] = useState({
    basic: 0,
    premium: 0,
    adminFee: 0,
  })
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getPricing = async () => {
      setLoadingPricing(false)
      const pricingSnapshot = await firebase.getPricing()
      if (pricingSnapshot.exists) {
        const getData = pricingSnapshot.data()
        setPricing(getData)
        setStripeSpread(getData.stripe_spread_percentage || 0)
        setExchangeSpread(getData.compensation_spread_percentage || 0)
        setProviderCharges(getData?.providerCharges)
        setBusinessCharges(getData?.businessCharges)
        setLoadingPricing(true)
      }
    }
    if (firebase) {
      getPricing()
    }
  }, [firebase])

  useEffect(() => {
    const getCountries = async () => {
      const localExchanges = await firebase.getFormOptions("exchanges")
      if (localExchanges) {
        const data = localExchanges.data()
        const sellExchageData = {}
        const buyExchangeData = {}

        setCountries(data.buyRates)
        setInitialCountries(data.buyRates)
        Object.keys(data.saleRates).forEach((k) => {
          const key = `sell-${k}`
          sellExchageData[key] = data.saleRates[k]
        })
        Object.keys(data.buyRates).forEach((k) => {
          const key = `buy-${k}`
          buyExchangeData[key] = data.buyRates[k]
        })
        setExchanges({ ...sellExchageData, ...buyExchangeData })
      }
    }

    if (firebase) {
      getCountries()
    }
  }, [firebase])

  useEffect(() => {
    if (isEmpty(countrySearchValue)) {
      setCountries(initialCountries)
    }
  }, [countrySearchValue, initialCountries])

  const getCountriesNames = (countryKey) => {
    const countriesNames = {
      BO: "Bolivia",
      CL: "Chile",
      CO: "Colombia",
      CR: "Costa Rica",
      DO: "República Dominicana",
      EC: "Ecuador",
      GT: "Guatemala",
      HN: "Honduras",
      MX: "México",
      NI: "Nicaragua",
      PA: "Panamá",
      PE: "Perú",
      SV: "El Salvador",
    }
    return countriesNames[countryKey]
  }

  const getVariableCharge = (sell, buy, provisionSpread, stripeSpread) => {
    const provisionSpreadToPercetange = provisionSpread / 100
    const spread = ((sell - buy) / buy) * 100
    const percentage = spread * provisionSpreadToPercetange
    const total = spread + percentage
    return total + stripeSpread
  }

  const saveNewDataWithVariableCharge = (values) => {
    const newValues = {}
    Object.keys(countries).forEach((country) => {
      const variableChargeAmount = (
        getVariableCharge(
          exchanges[`sell-${country}`],
          exchanges[`buy-${country}`],
          values.compensation_spread_percentage,
          values.stripe_spread_percentage
        ) || 0
      ).toFixed(2)

      if (values[country]) {
        const variableFeeToPercentage =
          variableChargeAmount > 0 ? variableChargeAmount / 100 : 0
        const dataFromValues = values[country]

        newValues[country] = {
          donationAdminFee: Number(dataFromValues.donationAdminFee) || 0,
          faceToFaceCashFee: Number(dataFromValues.faceToFaceCashFee) || 0,
          faceToFaceDonationFee:
            Number(dataFromValues.faceToFaceDonationFee) || 0,
          faceToFaceDonationCashFee:
            Number(dataFromValues.faceToFaceDonationCashFee) || 0,
          faceToFaceFixedPriceFee:
            Number(dataFromValues.faceToFaceFixedPriceFee) || 0,
          faceToFaceVariableFee: Number(variableFeeToPercentage) || 0,
          homeCashFee: Number(dataFromValues.homeCashFee) || 0,
          homeDonationFee: Number(dataFromValues.homeDonationFee) || 0,
          homeFixedPriceFee: Number(dataFromValues.homeFixedPriceFee) || 0,
          homeVariableFee: Number(variableFeeToPercentage) || 0,
          medicalVisitorsFixedPriceFee:
            Number(dataFromValues.medicalVisitorsFixedPriceFee) || 0,
          nursingCashFee: Number(dataFromValues.nursingCashFee) || 0,
          nursingDonationFee: Number(dataFromValues.nursingDonationFee) || 0,
          nursingFixedPriceFee:
            Number(dataFromValues.nursingFixedPriceFee) || 0,
          nursingVariableFee: Number(variableFeeToPercentage) || 0,
          nursingDonationCashFee:
            Number(dataFromValues.nursingDonationCashFee) || 0,
          questionsFixedPriceFee:
            Number(dataFromValues.questionsFixedPriceFee) || 0,
          questionsCashFee: 0,
          questionsDonationFee:
            Number(dataFromValues.questionsDonationFee) || 0,
          questionsVariableFee:
            Number(dataFromValues.questionsVariableFee) < 1
              ? Number(dataFromValues.questionsVariableFee) || 0
              : Number(dataFromValues.questionsVariableFee) / 100,
          virtualFixedPriceFee:
            Number(dataFromValues.virtualFixedPriceFee) || 0,
          virtualVariableFee: Number(variableFeeToPercentage) || 0,
          virtualDonationCashFee:
            Number(dataFromValues.virtualDonationCashFee) || 0,
          virtualCashFee: Number(dataFromValues.virtualCashFee) || 0,
          orgFixedPriceFee: Number(dataFromValues.orgFixedPriceFee) || 0,
          orgVariableFee: Number(variableFeeToPercentage) || 0,
          orgCashFee: Number(dataFromValues.orgCashFee) || 0,
          orgBandPercentageFee:
            Number(dataFromValues.orgBandPercentageFee) < 1
              ? Number(dataFromValues.orgBandPercentageFee) || 0
              : Number(dataFromValues.orgBandPercentageFee) / 100,
          orgBandFee: Number(dataFromValues.orgBandFee) || 0,
        }
      }
    })
    return {
      ...newValues,
      compensation_spread_percentage: values.compensation_spread_percentage,
      stripe_spread_percentage: values.stripe_spread_percentage,
    }
  }

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  const searchByCountry = (e) => {
    const newCountries = {}
    setCountrySearchValue(e.target.value)
    // eslint-disable-next-line array-callback-return, consistent-return
    Object.keys(countries).filter((country) => {
      if (
        removeAccents(getCountriesNames(country))
          .toLowerCase()
          .includes(removeAccents(countrySearchValue).toLowerCase())
      ) {
        newCountries[country] = 0
      }
    })

    if (isEmpty(newCountries)) {
      setCountries(initialCountries)
    } else {
      setCountries(newCountries)
    }
  }

  const saveProviderCharges = async () => {
    try {
      const providerCharges = {
        firstYear: Number(providerChargesState?.firstYear),
        secondYear: Number(providerChargesState?.secondYear),
        restOfTime: Number(providerChargesState?.restOfTime),
        basicFirstYear: Number(providerChargesState?.basicFirstYear),
        basicSecondYear: Number(providerChargesState?.basicSecondYear),
        basicRestOfTime: Number(providerChargesState?.basicRestOfTime),
      }
      await firebase.updateProviderCharges({ providerCharges })

      addToast(t("saved_successfully"), {
        appearance: "success",
        placement: "bottom-center",
      })
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
    }
  }

  const saveBusinessCharges = async () => {
    try {
      const businessDataCharges = {
        basic: Number(businessCharges?.basic),
        premium: Number(businessCharges?.premium),
        adminFee: Number(businessCharges?.adminFee),
      }
      await firebase.updatePricing({
        businessCharges: businessDataCharges,
      })

      addToast(t("saved_successfully"), {
        appearance: "success",
        placement: "bottom-center",
      })
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
    }
  }

  const updateAllUsers = async () => {
    try {
      const token = await firebase.getIdToken()
      const updateProviders = await fetch(updateAllProvidersCharges, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Content-Length": 0,
        },
      })
      if (updateProviders?.ok) {
        setIsLoading(false)
        setShowConfirmationModal(false)
        addToast(t("saved_successfully"), {
          appearance: "success",
          placement: "bottom-center",
        })
      }
    } catch (error) {
      setIsLoading(false)
      setShowConfirmationModal(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
    }
  }

  return (
    <FormContainer>
      {showConfirmationModal && (
        <CustomModal
          key="deleteConfirmationModal"
          modalOpen={showConfirmationModal}
          setModalOpen={setShowConfirmationModal}
          showClose={false}
        >
          <ModalContainer alignItems={!isLoading ? "flex-start" : "center"}>
            <Title>
              {!isLoading ? t("delete_modal_title") : t("applying_changes")}
            </Title>
            {!isLoading && <RegularFont>{t("update_all_user")}</RegularFont>}
            <RowContainer style={{ width: "100%" }}>
              {isLoading && <Spinner isLoading />}
              {!isLoading && (
                <>
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowConfirmationModal(false)
                    }}
                  >
                    <p>{t("cancel")}</p>
                  </ActionButton>
                  <ApplyToAllButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsLoading(true)
                      updateAllUsers()
                    }}
                    disabled={isLoading}
                  >
                    <p>{t("apply_to_all")}</p>
                  </ApplyToAllButton>
                </>
              )}
            </RowContainer>
          </ModalContainer>
        </CustomModal>
      )}

      <ProviderChargesStyledForm>
        <TitleContainer>
          <Subtitles>{t("provider_charges")}</Subtitles>
        </TitleContainer>
        <TitleContainer>
          <SubtitlesII>{t("provider_premium")}</SubtitlesII>
        </TitleContainer>

        <RowContainer>
          <InputContainer>
            <Subtitles>{`12 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="firstYear"
              name="firstYear"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.firstYear}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  firstYear: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{`24 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="secondYear"
              name="secondYear"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.secondYear}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  secondYear: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{`36 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="restOfTime"
              name="restOfTime"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.restOfTime}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  restOfTime: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>
          <PutChargesToAll
            onClick={() => setShowConfirmationModal(!showConfirmationModal)}
          >
            <UserCheck size="40" />
          </PutChargesToAll>
        </RowContainer>
        <TitleContainer>
          <SubtitlesII>{t("provider_basic")}</SubtitlesII>
        </TitleContainer>

        <RowContainer>
          <InputContainer>
            <Subtitles>{`12 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="basicFirstYear"
              name="basicFirstYear"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.basicFirstYear}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  basicFirstYear: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{`24 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="basicSecondYear"
              name="basicSecondYear"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.basicSecondYear}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  basicSecondYear: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{`36 ${t("months")}`}</Subtitles>
            <RoundGrayInput
              id="basicRestOfTime"
              name="basicRestOfTime"
              type="number"
              labelName={t("total_label")}
              value={providerChargesState?.basicRestOfTime}
              onBlur={saveProviderCharges}
              onChange={(e) => {
                setProviderCharges((currentValues) => ({
                  ...currentValues,
                  basicRestOfTime: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>
          <PutChargesToAll
            onClick={() => setShowConfirmationModal(!showConfirmationModal)}
          >
            <UserCheck size="40" />
          </PutChargesToAll>
        </RowContainer>
      </ProviderChargesStyledForm>

      <ProviderChargesStyledForm>
        <TitleContainer>
          <Subtitles>{t("business_charges")}</Subtitles>
        </TitleContainer>

        <RowContainer>
          <InputContainer>
            <Subtitles>{t("provider_basic")}</Subtitles>
            <RoundGrayInput
              id="basic"
              name="basic"
              type="number"
              labelName={t("total_label")}
              value={businessCharges?.basic}
              onBlur={saveBusinessCharges}
              onChange={(e) => {
                setBusinessCharges((currentValues) => ({
                  ...currentValues,
                  basic: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{t("provider_premium")}</Subtitles>
            <RoundGrayInput
              id="premium"
              name="premium"
              type="number"
              labelName={t("total_label")}
              value={businessCharges?.premium}
              onBlur={saveBusinessCharges}
              onChange={(e) => {
                setBusinessCharges((currentValues) => ({
                  ...currentValues,
                  premium: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>

          <InputContainer>
            <Subtitles>{t("admin_fee")}</Subtitles>
            <RoundGrayInput
              id="adminFee"
              name="adminFee"
              type="number"
              labelName={t("total_label")}
              value={businessCharges?.adminFee}
              onBlur={saveBusinessCharges}
              onChange={(e) => {
                setBusinessCharges((currentValues) => ({
                  ...currentValues,
                  adminFee: e.target.value,
                }))
              }}
              iconRight={<AttachMoney size="24" />}
            />
          </InputContainer>
        </RowContainer>
      </ProviderChargesStyledForm>

      <ChargesLine />

      {loadingPricing && (
        <Formik
          innerRef={formRef}
          initialValues={{
            ...pricing,
            stripe_spread_percentage: stripeSpreadFromDB,
            compensation_spread_percentage: exhangeSpreadFromDB,
          }}
          onSubmit={async (values) => {
            try {
              setIsSaving(true)
              const dataToSave = saveNewDataWithVariableCharge(values)
              await firebase.updatePricing(dataToSave)
              addToast(t("saved_successfully"), {
                appearance: "success",
                placement: "bottom-center",
              })
              await firebase.updatePricing({
                stripe_spread_percentage: Number(
                  values.stripe_spread_percentage
                ),
              })
              setStripeSpread(Number(values.stripe_spread_percentage))
              setIsSaving(false)
            } catch (e) {
              addToast(t("unexpected_error"), {
                appearance: "error",
                placement: "bottom-center",
              })
              setIsSaving(false)
            }
          }}
        >
          {(props) => (
            <StyledForm>
              <SpreadContainer>
                <SubTitle>{t("stripe_commision")}</SubTitle>
                <RowContainer>
                  <CustomInput
                    value={props.values.stripe_spread_percentage}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    id="stripe_spread_percentage"
                    name="stripe_spread_percentage"
                    type="numeric"
                    icon="percent"
                    fontSize="1.4rem"
                    iconRight
                  />
                </RowContainer>
              </SpreadContainer>

              <TitleContainer>
                <Title>{t("charges_for")}</Title>
                <SearchContainer>
                  <SearchInput
                    value={countrySearchValue}
                    onChange={searchByCountry}
                    placeholder={t("search_by_country")}
                  />
                </SearchContainer>
              </TitleContainer>

              {Object.keys(countries)
                .sort()
                .map((country) => {
                  const variableChargeAmount =
                    getVariableCharge(
                      exchanges[`sell-${country}`],
                      exchanges[`buy-${country}`],
                      props.values.compensation_spread_percentage,
                      props.values.stripe_spread_percentage
                    ) || 0
                  const fixedVariableCharge = isNumber(variableChargeAmount)
                    ? variableChargeAmount.toFixed(2)
                    : variableChargeAmount

                  return (
                    <div key={country}>
                      <div>
                        <ChargesTitleContainer>
                          <CountryLine />
                          <SectionTitle>
                            {getCountriesNames(country)}
                          </SectionTitle>
                          <CountryLine />
                        </ChargesTitleContainer>
                        <ChargesTitleContainer>
                          <SectionTitle>{t("user_charges")}</SectionTitle>
                          <Line />
                        </ChargesTitleContainer>
                        <InputContainer>
                          <Subtitles>{t("virtual_quote")}</Subtitles>
                          <RoundGrayInput
                            id="virtualFixedPriceFee"
                            name="virtualFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "virtualFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.virtualFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="donationAdminFee"
                            name="donationAdminFee"
                            type="numeric"
                            labelName={t("donated_quote")}
                            value={get(
                              props.values[country],
                              "donationAdminFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.donationAdminFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="virtualCashFee"
                            name="virtualCashFee"
                            type="numeric"
                            labelName={t("cash_fee_charges")}
                            value={get(
                              props.values[country],
                              "virtualCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.virtualCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="virtualDonationCashFee"
                            name="virtualDonationCashFee"
                            type="numeric"
                            labelName={t("cash_fee_donated_charges")}
                            value={get(
                              props.values[country],
                              "virtualDonationCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.virtualDonationCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="variablePriceFee"
                            name="variablePriceFee"
                            type="text"
                            labelName={t("variable_charge")}
                            value={fixedVariableCharge}
                            iconRight={<Percent size="18" />}
                            readonly
                            disabled
                          />
                        </InputContainer>
                        <InputContainer>
                          <Subtitles>{t("presencial_quote")}</Subtitles>
                          <RoundGrayInput
                            id="faceToFaceFixedPriceFee"
                            name="faceToFaceFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "faceToFaceFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.faceToFaceFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="faceToFaceDonationFee"
                            name="faceToFaceDonationFee"
                            type="numeric"
                            labelName={t("donated_quote")}
                            value={get(
                              props.values[country],
                              "faceToFaceDonationFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.faceToFaceDonationFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="faceToFaceCashFee"
                            name="faceToFaceCashFee"
                            type="numeric"
                            labelName={t("cash_fee_charges")}
                            value={get(
                              props.values[country],
                              "faceToFaceCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.faceToFaceCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="faceToFaceDonationCashFee"
                            name="faceToFaceDonationCashFee"
                            type="numeric"
                            labelName={t("cash_fee_donated_charges")}
                            value={get(
                              props.values[country],
                              "faceToFaceDonationCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.faceToFaceDonationCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="faceToFaceVariableFee"
                            name="faceToFaceVariableFee"
                            type="text"
                            labelName={t("variable_charge")}
                            value={fixedVariableCharge}
                            iconRight={<Percent size="18" />}
                            readonly
                            disabled
                          />
                        </InputContainer>
                        <InputContainer>
                          <Subtitles>Consulta a domicilio</Subtitles>
                          <RoundGrayInput
                            id="homeFixedPriceFee"
                            name="homeFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "homeFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.homeFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="homeDonationFee"
                            name="homeDonationFee"
                            type="numeric"
                            labelName={t("donated_quote")}
                            value={get(
                              props.values[country],
                              "homeDonationFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.homeDonationFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="homeCashFee"
                            name="homeCashFee"
                            type="numeric"
                            labelName={t("cash_fee_charges")}
                            value={get(props.values[country], "homeCashFee", 0)}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.homeCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="homeVariableFee"
                            name="homeVariableFee"
                            type="text"
                            labelName={t("variable_charge")}
                            value={fixedVariableCharge}
                            iconRight={<Percent size="18" />}
                            readonly
                            disabled
                          />
                        </InputContainer>
                        <InputContainer>
                          <Subtitles>{t("questions")}</Subtitles>
                          <RoundGrayInput
                            id="questionsFixedPriceFee"
                            name="questionsFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "questionsFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.questionsFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="questionsDonationFee"
                            name="questionsDonationFee"
                            type="numeric"
                            labelName={t("donated_quote")}
                            value={get(
                              props.values[country],
                              "questionsDonationFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.questionsDonationFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="questionsVariableFee"
                            name="questionsVariableFee"
                            type="numeric"
                            labelName={t("variable_charge")}
                            value={get(
                              props.values[country],
                              "questionsVariableFee",
                              0
                            )}
                            iconRight={<Percent size="18" />}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.questionsVariableFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                          />
                        </InputContainer>
                        <InputContainer>
                          <Subtitles>Servicios de enfermería</Subtitles>
                          <RoundGrayInput
                            id="nursingFixedPriceFee"
                            name="nursingFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "nursingFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.nursingFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="nursingDonationFee"
                            name="nursingDonationFee"
                            type="numeric"
                            labelName={t("donated_quote")}
                            value={get(
                              props.values[country],
                              "nursingDonationFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.nursingDonationFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="nursingCashFee"
                            name="nursingCashFee"
                            type="numeric"
                            labelName={t("cash_fee_charges")}
                            value={get(
                              props.values[country],
                              "nursingCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.nursingCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="nursingDonationCashFee"
                            name="nursingDonationCashFee"
                            type="numeric"
                            labelName={t("cash_fee_donated_charges")}
                            value={get(
                              props.values[country],
                              "nursingDonationCashFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.nursingDonationCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="nursingVariableFee"
                            name="nursingVariableFee"
                            type="text"
                            labelName={t("variable_charge")}
                            value={fixedVariableCharge}
                            iconRight={<Percent size="18" />}
                            readonly
                            disabled
                          />
                        </InputContainer>
                      </div>
                      <div>
                        <ChargesTitleContainer>
                          <SectionTitle>{t("medical_visitors")}</SectionTitle>
                          <Line />
                        </ChargesTitleContainer>
                        <InputContainer>
                          <Subtitles>{t("virtual_quote")}</Subtitles>
                          <RoundGrayInput
                            id="medicalVisitorsFixedPriceFee"
                            name="medicalVisitorsFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "medicalVisitorsFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.medicalVisitorsFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                        </InputContainer>
                      </div>
                      <div>
                        <ChargesTitleContainer>
                          <SectionTitle style={{ width: 360 }}>
                            {t("orgs_charges")}
                          </SectionTitle>
                          <Line />
                        </ChargesTitleContainer>
                        <InputContainer>
                          <Subtitles>{t("transfer")}</Subtitles>
                          <RoundGrayInput
                            id="orgFixedPriceFee"
                            name="orgFixedPriceFee"
                            type="numeric"
                            labelName={t("fixed_charge")}
                            value={get(
                              props.values[country],
                              "orgFixedPriceFee",
                              0
                            )}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.orgFixedPriceFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="orgCashFee"
                            name="orgCashFee"
                            type="numeric"
                            labelName={t("cash_fee_charges")}
                            value={get(props.values[country], "orgCashFee", 0)}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.orgCashFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="orgVariableFee"
                            name="orgVariableFee"
                            type="text"
                            labelName={t("variable_charge")}
                            value={fixedVariableCharge}
                            iconRight={<Percent size="18" />}
                            readonly
                            disabled
                          />
                          <RoundGrayInput
                            id="orgBandFee"
                            name="orgBandFee"
                            type="numeric"
                            labelName={t("band_minimun_fee")}
                            value={get(props.values[country], "orgBandFee", 0)}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.orgBandFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                            iconRight={<AttachMoney size="24" />}
                          />
                          <RoundGrayInput
                            id="orgBandPercentageFee"
                            name="orgBandPercentageFee"
                            type="numeric"
                            labelName={t("band_percentage_charge")}
                            value={
                              get(
                                props.values[country],
                                "orgBandPercentageFee",
                                0
                              ) < 1
                                ? get(
                                    props.values[country],
                                    "orgBandPercentageFee",
                                    0
                                  ) * 100
                                : get(
                                    props.values[country],
                                    "orgBandPercentageFee",
                                    0
                                  )
                            }
                            iconRight={<Percent size="18" />}
                            onChange={(e) =>
                              props.setFieldValue(
                                `${country}.orgBandPercentageFee`,
                                cleanNotNumbersAcceptDot(e.target.value)
                              )
                            }
                          />
                        </InputContainer>
                      </div>
                    </div>
                  )
                })}
            </StyledForm>
          )}
        </Formik>
      )}
      <SubmitButton
        iconName="save"
        isLoading={isSaving}
        onClick={() => formRef.current.handleSubmit()}
      >
        {t("save_button")}
      </SubmitButton>
    </FormContainer>
  )
}

Charges.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => authUser && authUser.roles[ROLES.SUPER_ADMIN]

const ComposedPayments = compose(
  withFirebase,
  withAuthorization(condition)
)(Charges)
export default ComposedPayments
