import { css } from "styled-components"
import { rem } from "polished"

export const sizes = {
  small: 420,
  medium: 640,
  mlarge: 816,
  large: 1024,
  xlarge: 1220,
  xxlarge: 1440,
}

const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${rem(sizes[label])}) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const laptopAndUp = media.large

export default media
