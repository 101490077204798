/* eslint-disable react/prop-types */
import React, { useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import CryptoJS from "crypto-js"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import get from "lodash.get"
import { withFirebase } from "../../../utils/Firebase"
import { generatePassword, countries } from "../../../utils/App"
import CustomInput from "../../atoms/CustomInput"
import CustomSelect from "../../atoms/CustomSelect"
import Drawer from "../../atoms/Drawer"
import { AuthUserContext } from "../../../context/Session"
import { StyledForm, SubmitButton, Title } from "./styles"

const AdminDrawer = ({ firebase, open, closeDrawer }) => {
  const createAdminUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserAdmin`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const formRef = useRef()

  const [isCreating, setIsCreating] = useState(false)
  const [country, setCountry] = useState(
    user.roles.SUPER_ADMIN
      ? undefined
      : countries.filter(
          (c) => c.value === get(user, "countryBusiness", user.country)
        )
  )

  const createAdminUser = async (values) => {
    try {
      setIsCreating(true)
      let authToken = null
      await firebase.getIdToken().then((token) => {
        authToken = token
        fetch(createAdminUrl, {
          method: "POST",
          body: JSON.stringify({
            admin: {
              email: values.email,
              password: CryptoJS.AES.encrypt(
                values.password,
                "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5"
              ).toString(),
              displayName: values.displayName,
              phoneNumber: values.phoneNumber,
              country: values.country,
            },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then(async (data) => {
          if (data.status === 200) {
            await fetch(mailerUrl, {
              method: "POST",
              body: JSON.stringify({
                data: {
                  templateId: 2358069, // new admin created
                  email: values.email,
                  variables: {
                    helpLink: "https://youtu.be/hFXh3joR8IM",
                    displayName: values.displayName,
                    password: values.password,
                    websiteLink: process.env.REACT_APP_ADMIN_APP_SIGNIN,
                  },
                },
              }),
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
              },
            })

            setIsCreating(false)

            const savedCountry = values.country
            formRef.current.resetForm()
            if (savedCountry) {
              formRef.current.setFieldValue("country", savedCountry)
            }
            closeDrawer()
          } else {
            addToast(t("unexpected_error"), {
              appearance: "error",
              placement: "bottom-center",
            })
            setIsCreating(false)
          }
        })
      })
    } catch (e) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
      setIsCreating(false)
    }
  }

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("create_administrator")}
    >
      <div>
        <Title>
          Ingrese los datos de contacto del administrador que deseas crear en
          DOC.
        </Title>
        <Formik
          innerRef={formRef}
          initialValues={{
            email: undefined,
            password: generatePassword(),
            displayName: undefined,
            phoneNumber: undefined,
            photoURL: undefined,
            country: user.roles.SUPER_ADMIN
              ? undefined
              : get(user, "countryBusiness", user.country),
          }}
          onSubmit={async (values) => {
            await createAdminUser(values)
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            displayName: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.string().required(t("error_all_fields_required")),
            country: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <CustomInput
                touched={props.touched.displayName}
                errors={props.errors.displayName}
                icon="user"
                value={props.values.displayName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("display_name_label")}
                type="text"
                id="displayName"
                name="displayName"
              />
              <CustomInput
                touched={props.touched.email}
                errors={props.errors.email}
                icon="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("email_address")}
                type="email"
                id="email"
                name="email"
              />
              <CustomInput
                touched={props.touched.phoneNumber}
                errors={props.errors.phoneNumber}
                icon="phone"
                value={props.values.phoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("phone_label")}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
              />
              {user.roles.SUPER_ADMIN && (
                <CustomSelect
                  value={country}
                  formKey="country"
                  lngKey="country_label"
                  handleChange={(event, value) => {
                    props.setFieldValue("country", value)
                    setCountry(countries.filter((c) => c.value === value))
                  }}
                  items={countries}
                  showError={
                    !props.values.country && Boolean(props.errors.country)
                  }
                />
              )}
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
              >
                {t("create")}
              </SubmitButton>
              {/* {error && <p>{error.message}</p>} */}
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

AdminDrawer.defaultProps = {
  open: false,
}

AdminDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
}
export default withFirebase(AdminDrawer)
