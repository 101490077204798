import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import get from "lodash.get"
import moment from "moment"
import { withFirebase } from "../../../utils/Firebase"

const DashboardData = ({ firebase }) => {
  const history = useHistory()
  const [dashboard, setDashboard] = useState(null)
  useEffect(() => {
    const getDashboardData = async () => {
      const originalData = await firebase.getMonthDashboard()
      const dashboardData = originalData.docs.map((d) => d.data())
      setDashboard({
        total: dashboardData.length,
        countDay: dashboardData.filter(
          (d) =>
            d.createdAt >= moment().startOf("day").valueOf() &&
            d.createdAt <= moment().endOf("day").valueOf()
        ).length,
        countWeek: dashboardData.filter(
          (d) =>
            d.createdAt >= moment().startOf("week").valueOf() &&
            d.createdAt <= moment().endOf("week").valueOf()
        ).length,
        countMonth: dashboardData.filter(
          (d) =>
            d.createdAt >= moment().startOf("month").valueOf() &&
            d.createdAt <= moment().endOf("month").valueOf()
        ).length,
      })
    }
    if (firebase) {
      getDashboardData()
    }
  }, [firebase])
  return (
    <Container>
      <Title>Sesiones</Title>
      <CardsContainer>
        <DataCard
          onClick={() => {
            history.push(`/sessions`, {
              type: "day",
            })
          }}
        >
          <DataTitle>Por día</DataTitle>
          <DataValue>{get(dashboard, "countDay", 0)}</DataValue>
        </DataCard>
        <DataCard
          onClick={() => {
            history.push(`/sessions`, {
              type: "week",
            })
          }}
        >
          <DataTitle>Por semana</DataTitle>
          <DataValue>{get(dashboard, "countWeek", 0)}</DataValue>
        </DataCard>
        <DataCard
          onClick={() => {
            history.push(`/sessions`, {
              type: "month",
            })
          }}
        >
          <DataTitle>Por mes</DataTitle>
          <DataValue>{get(dashboard, "countMonth", 0)}</DataValue>
        </DataCard>
      </CardsContainer>
    </Container>
  )
}

const Title = styled.div`
  height: 40px;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: -0.5px;
  text-align: left;
`

const Container = styled.div`
  margin-top: 22px;
  margin-left: 30px;
`

const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.375rem;
  width: 164px;
  height: 96px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`

const DataTitle = styled.span`
  width: 100%;
  height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
`

const DataValue = styled.span`
  font-size: 1.875rem;
  line-height: 2.375rem;
  text-align: center;
  color: #000000;
  margin: 0px 5px;
  width: 100%;
  height: 38px;
`

const CardsContainer = styled.section`
  display: flex;
  & > ${DataCard} {
    margin-right: 20px;
    &:last-child {
      margin: 0px;
    }
  }
`

DashboardData.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(DashboardData)
