// Theme

export const palette = {
  white: "#FFFFFF",
  teal: "#00CDBE",
  darkTeal: "#50BAB3",
  moss: "#00A8A3",
  blue: "#4A90E2",
  purple: "#bb86fc",
  lightPurple: "#4D66F5",
  darkPurple: "#6200ee",
  red: "#D0021B",
  yellow: "#F5A623",
  green: "#00B359",
  darkGrey1: "#9FA9BA",
  darkGrey2: "#727682",
  lightGrey1: "#E6EAEE",
  lightGrey2: "#CBD1E0",
  lightGrey3: "#F6F7FA",
  lightGrey4: "#FAFBFC",
  lightGrey5: "#F1F4F7",
  darkBlue1: "#354052",
  darkBlue2: "#262935",
  darkBlue3: "#1D1F26",
}

const theme = {
  font: {
    primary: "Circular Std Book",
    secondary: "Circular Std Medium",
    light: "Circular Std Light",
    bold: "Circular Std Bold",
    extrabold: "Circular Std Black",
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 1.875rem; line-height: normal;",
    larger: "font-size: 2.188rem; line-height: 2.813rem;",
    xlarge: "font-size: 2.5rem; line-height: 3rem;",
  },
  color: {
    primary: palette.lightPurple,
    secondary: palette.lightPurple,
    background: {
      black80: "rgba(0,0,0,0.87)",
      black60: "rgba(0, 0, 0, 0.6)",
      gray30: "rgba(237, 237, 237, 0.3)",
    },
    black: {
      normal: "#000",
      300: "rgba(0, 0, 0, 0.38)",
      500: "rgba(0, 0, 0, 0.5)",
    },
    darkPurple: palette.darkPurple,
    error: palette.red,
    purple: palette.purple,
    palePurple: "rgba(77, 102, 245, 0.1)",
    semiPalePurple: "rgba(77, 102, 245, 0.4)",
    white: {
      normal: "#fff",
    },
    onSurface: "#071435",
    semiBorder: "rgba(33, 33, 33, 0.08)",
    border: {
      default: palette.lightGrey2,
      focused: palette.teal,
      error: palette.red,
    },
    bg: {
      light: palette.white,
      secondary: palette.lightGrey4,
      dark: palette.lightGrey5,
      inverted: palette.darkBlue3,
      grayGradient: `linear-gradient(0deg,#F2F4F7 0%,#FFFFFF 100%)`,
      error: "rgba(208,2,27,0.1)",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
    xlg: "1600px",
  },
}

export default theme
