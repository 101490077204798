import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin-top: 60px;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const RegularButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
  background-color: white;
  & span {
    color: black;
  }
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`
