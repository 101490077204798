import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import get from "lodash.get"
import { LocalHospital } from "@styled-icons/material/LocalHospital"
import { useTranslation } from "react-i18next"
import ProductImage from "../ProductImage"
import { withFirebase } from "../../../utils/Firebase"

const OrgProductCard = ({ product, toActivate, firebase, orgId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  // eslint-disable-next-line no-unused-vars
  const [orgForm, setOrgForm] = useState([])
  const handleClick = () =>
    history.push(`/product/${product.id}`, {
      currentProd: product,
      currentOrg: orgId,
    })
  useEffect(() => {
    if (firebase && product.type) {
      const getForm = async () => {
        const form = await firebase.getOrgForm(product.id, "agents")
        setOrgForm(form.docs)
      }
      getForm()
    }
  }, [firebase, product])
  return (
    <CardContainer onClick={handleClick} toActivate={toActivate}>
      <Info>
        <Label topPosition="0">
          <span>{t("information")}</span>
        </Label>
        <PersonalData>
          <p>{get(product, "labName", "Laboratorio")}</p>
          <p>{get(product, "sku", "SKU")}</p>
        </PersonalData>
      </Info>
      <Badge>
        <span>
          <LocalHospital size="18" />
          {get(product, "cie11", "CIE11")}
        </span>
      </Badge>
      <ImagesContainer>
        <ProductImage
          hideControls
          width={411}
          height={148}
          src={get(product, "mainPhotoURL")}
        />
      </ImagesContainer>
    </CardContainer>
  )
}

OrgProductCard.defaultProps = {
  toActivate: false,
}

OrgProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    email: PropTypes.string,
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    type: PropTypes.string,
    legalId: PropTypes.string,
    logoURL: PropTypes.string,
  }).isRequired,
  orgId: PropTypes.shape().isRequired,
  toActivate: PropTypes.bool,
  firebase: PropTypes.shape().isRequired,
}

const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 0;
  background: ${(props) =>
    props.toActivate ? "rgba(77, 102, 245, 0.1)" : "white"};
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 148px;
  width: 100%;
  margin-top: 8px;
  & > div {
    margin: 15px;
  }
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  width: 90px;
  height: 32px;
  background: #f2f2f2;
  border-radius: 100px;
  padding: 0 20px;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      margin-right: 6px;
    }
  }
`

const Info = styled.div`
  position: relative;
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
  margin: 0 20px;
`

const Label = styled.div`
  position: absolute;
  top: ${(props) => props.topPosition};
  left: 0;
  & > span {
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 1.5px;
    text-align: left;
    color: ${(props) => props.theme.color.black["300"]};
    text-transform: uppercase;
  }
`

const PersonalData = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  & > p {
    text-align: left;
    margin: 8px 0;
    &:first-of-type {
      font-size: 1.25rem;
    }
  }

  & > span {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > svg {
      margin-right: 8px;
    }
  }
`

export default withFirebase(OrgProductCard)
