import React from "react"
import PropTypes from "prop-types"
import { ExitToApp } from "@styled-icons/material/ExitToApp"

import { withFirebase } from "../../../utils/Firebase"

const SignOutButton = ({ firebase }) => (
  <button
    type="button"
    onClick={firebase.doSignOut}
    style={{
      marginBottom: 20,
    }}
  >
    <ExitToApp size={24} />
  </button>
)

SignOutButton.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(SignOutButton)
// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
